import React from 'react';
import { Flex } from '@newlab-io/getprospect-react-components/lib/ui';

import { Checkbox, Option, Select } from 'components/common';
import Text from '../text';

const AddToSequence = ({
  sequences,
  sequenceId,
  openSequence,
  onSelectSequence,
  onChangeOpenSequence,
}) => (
  <Flex gap={12} direction="column">
    <Text />
    <Select
      size="default"
      value={sequenceId}
      placeholder="Select sequence..."
      onChange={onSelectSequence}
      allowClear
    >
      {
        sequences.map((option) => (
          <Option
            value={option._id}
            key={option._id}
          >
            {option.name}
          </Option>
        ))
      }
    </Select>
    <Checkbox
      size="default"
      checked={openSequence}
      onChange={(e) => {
        onChangeOpenSequence(e.target.checked);
      }}
    >
      Open sequence
    </Checkbox>
  </Flex>
);

export default AddToSequence;
