import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { getRandomNumber } from '@newlab-io/getprospect-react-components/lib/shared/helpers/number';

import { toggleModal } from 'data/actions/modals';
import {
  Button, Option, Select, TextArea,
} from 'components/common';
import { REASON_TYPE, tools } from 'data/types/subscription.types';
import { cancelSubscriptionSelector } from 'data/selectors/subscriptions';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { analyticsAndDeleteUserLoaderSelector } from 'data/selectors/loading';
import { resetCancelSubscription, setCancelSubscription } from 'data/actions/subscriptions';
import {
  AskText,
  SorryText,
  BlueLabel,
  ReasonRadio,
  ReasonsRadioWrapper,
} from './styles';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';

const CancelSubscriptionModal = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector(modalsSelector);
  const { deleteAccount } = useSelector(modalOptionsSelector);
  const loading = useSelector(analyticsAndDeleteUserLoaderSelector);
  const details = useSelector(cancelSubscriptionSelector);

  const [reason, setReason] = useState();
  const [labelReason, setLabelReason] = useState();

  const reasonOptions = useMemo(() => {
    const reasons = [
      { label: 'GetProspect is too expensive', value: REASON_TYPE.EXPENSIVE_SUBSCRIPTION },
      { label: 'The data quantity is too low', value: REASON_TYPE.POOR_DATA_QUANTITY },
      { label: 'I finished the work I needed to do', value: REASON_TYPE.FINISHED_WORK },
      { label: 'I switched to another tool', value: REASON_TYPE.ANOTHER_TOOL },
      { label: 'The data quality is too low', value: REASON_TYPE.POOR_DATA_QUALITY },
    ];
    const randomReasons = [];
    while (reasons.length) {
      const [randomReason] = reasons.splice(getRandomNumber(0, reasons.length - 1), 1);
      randomReasons.push(randomReason);
    }
    randomReasons.push({ label: 'Other', value: REASON_TYPE.OTHER });
    return randomReasons;
  }, []);

  const disabled = useMemo(() => {
    if (!reason) return true;
    if (reason === REASON_TYPE.OTHER && !details.descriptionReason) return true;
    if (reason === REASON_TYPE.ANOTHER_TOOL && !details.tool) return true;
    return false;
  }, [reason, details.descriptionReason, details.tool]);

  const handleClose = () => {
    dispatch(toggleModal('cancel_subscription', false));
    dispatch(resetCancelSubscription());
  };

  const openIntercom = () => {
    if (window.Intercom) {
      handleClose();
      window.Intercom('showNewMessage');
    }
  };

  const openManagerCancelSubscriptionModal = () => {
    if (!REASON_TYPE.ANOTHER_TOOL && details.tool) {
      dispatch(setCancelSubscription({ tool: undefined }));
    }
    dispatch(toggleModal('manager_cancel_subscription', true, { reason, labelReason, deleteAccount }));
  };

  useEffect(() => {
    setLabelReason(reasonOptions.find(({ value }) => value === reason)?.label);
  }, [reason, reasonOptions]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {deleteAccount ? 'Delete my account' : 'Cancel my subscription'}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <SorryText>
          If there&lsquo;s anything we can do to help, please
          {' '}
          <BlueLabel onClick={openIntercom}>contact us.</BlueLabel>
        </SorryText>
        <AskText>{`Why would you like to ${deleteAccount ? 'delete' : 'cancel'} your ${deleteAccount ? 'account' : 'subscription'}?`}</AskText>
        <ReasonsRadioWrapper value={reason} onChange={(e) => setReason(e.target?.value)}>
          {
            reasonOptions.map((reasonItem) => (
              <ReasonRadio key={reasonItem.value} value={reasonItem.value}>
                <span>
                  {reasonItem.label}
                </span>
                {
                  reasonItem.value === REASON_TYPE.ANOTHER_TOOL && reason === REASON_TYPE.ANOTHER_TOOL && (
                    <Select
                      style={{ margin: '5px 30px', width: 165 }}
                      size="default"
                      value={details.tool}
                      onChange={((value) => {
                        dispatch(setCancelSubscription({ tool: value }));
                      })}
                    >
                      {
                        tools.map((tool) => (
                          <Option key={tool} value={tool}>{tool}</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </ReasonRadio>
            ))
          }
        </ReasonsRadioWrapper>
        <AskText>{`Your feedback matters. Tell us more about why you're ${deleteAccount ? 'deleting' : 'cancelling'}:`}</AskText>
        <TextArea
          value={details.descriptionReason}
          onChange={(e) => dispatch(setCancelSubscription({ descriptionReason: e.target.value }))}
        />
        <Footer>
          <Button
            data-form="true"
            type="primary"
            disabled={disabled}
            onClick={openManagerCancelSubscriptionModal}
            loading={loading}
          >
            Continue
          </Button>
          <Button type="normal" onClick={handleClose}>Back</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
