import styled from 'styled-components';

import * as CONSTANTS from 'constants/styles';
import {
  CheckboxGroup, Checkbox, RadioGroup, Radio,
} from 'components/common';

export const SorryText = styled.p`
  margin: 0;
  margin-bottom: 10px;
`;

export const AskText = styled.p`
  margin: 0;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const ReasonsWrapper = styled(CheckboxGroup)`
  margin-bottom: 15px;  
`;

export const ReasonsRadioWrapper = styled(RadioGroup)`
  margin-bottom: 10px;  
`;

export const DescriptionReasonWrapper = styled.p`
  margin: 0;
  margin-bottom: 20px;
`;

export const ReasonCheckbox = styled(Checkbox)`
  display: block;
  margin-left: 0 !important;
  margin-bottom: 5px;
`;

export const ReasonRadio = styled(Radio)`
  display: block;
  margin-left: 0 !important;
  margin-bottom: 5px;
`;

export const BlueLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${CONSTANTS.COLORS.PRIMARY};
  cursor: pointer;
`;
