import get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button,
} from 'antd';

import ListSelect from 'components/listSelect';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import {
  listAddContacts,
} from 'data/actions/lists';
import { CREDITS_TYPE } from 'data/types/user.types';
import { SOURCE_TYPE } from 'data/types/source.types';
import usePaidOperation from 'hooks/usePaidOperation';
import { createInsightsSearch } from 'data/actions/search';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';

import {
  CloseIcon,
  ModalTitle,
  Label,
  Footer,
} from '../baseModal/styles';

const AddToStaticListModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    isInsight,
    tourListId,
    setSelectedRowKeys,
    selectedItems,
    selectedAll,
    filters = [],
    search,
    saveAdded,
    selectAll,
    totalItems,
    perCompany,
    listIds = [],
  } = useSelector(modalOptionsSelector);

  const [selectedListId, selectListId] = useState(listIds);
  const [isOpened, setIsOpened] = useState(false);

  const handleClose = () => dispatch(toggleModal('add_to_static_list', false));

  const onSuccess = (data) => {
    if (!data.pending) {
      const countUpdatedItems = get(data, 'countUpdatedItems');
      if (countUpdatedItems) {
        dispatch(toggleMessage('success', { header: 'Contacts were added to list(s)!' }));
      }
    }
    if (setSelectedRowKeys) setSelectedRowKeys([]);
    handleClose();
  };

  const onChange = (value) => {
    selectListId(value);
  };

  const handleSaveSearch = usePaidOperation(CREDITS_TYPE.EMAIL_SEARCH, () => {
    const limits = {
      total: totalItems,
    };
    dispatch(createInsightsSearch(
      {
        selected: totalItems,
        source: SOURCE_TYPE.CONTACT,
        listIds: selectedListId,
        limits,
        saveAdded,
        selectedAll,
        perCompany,
        onSuccess: () => {
          dispatch(toggleMessage('info', { header: 'Contacts save in progress' }));
          if (setSelectedRowKeys) setSelectedRowKeys([]);
          handleClose();
        },
      },
    ));
  }, undefined, 1);

  const handleAddToList = () => {
    if (isInsight) {
      handleSaveSearch();
    } else {
      dispatch(listAddContacts({
        listIds: selectedListId,
        contactsIds: selectedItems,
        onSuccess,
        filters,
        search,
        selectAll,
      }));
    }
  };

  const selectedContactsLength = get(selectedItems, 'length', 0);

  useEffect(() => {
    if (tourListId) selectListId(tourListId);
  }, [tourListId]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {
            selectedContactsLength > 1
              ? `Add ${selectedContactsLength} contacts to list`
              : 'Add to list'
          }
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={450}
      closable={false}
      footer={false}
      onCancel={handleClose}
    >
      <>
        <Label style={{ display: 'block' }}>
          Select list
        </Label>
        <ListSelect
          size="default"
          mode={false}
          maxTagCount={2}
          value={selectedListId}
          onSelectedListsChange={onChange}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      </>
      <Footer>
        <Button
          data-form={!selectedListId ? 'false' : 'true'}
          type="primary"
          data-tour="create-list-tour-step-6"
          size="large"
          onClick={handleAddToList}
          disabled={!selectedListId}
        >
          Confirm
        </Button>
        <Button size="large" type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default AddToStaticListModal;
