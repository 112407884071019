import styled from 'styled-components';
import { COLORS } from 'constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Half = styled.div`
  width: 50%;
  padding: 0 20px;

  &:first-child {
    border-right: 1px solid #dfe3eb;
  }
`;

export const PropertyItem = styled.div`
  padding: 8px 0;
  font-size: 5px;
  margin-left: 15px;

  span {
    font-size: 15px;
  }
`;

export const PropertiesContainer = styled.div`
  height: 250px;
  overflow-y: scroll;
  margin: 15px 0;
`;

export const PropertyGroup = styled.span`
  font-weight: 600;
`;

export const Label = styled.span`
  font-weight: 500;
  text-transform: uppercase;
`;

export const SelectedItem = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  width: 95%;
  color: rgb(51,71,91);
  padding: 10px 10px 10px 20px;
  margin: 10px 0;
  font-size: 14px;
  background-color: rgb(234,240,246);
  border: 1px solid rgb(203,214,226);
  border-radius: 3px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  &:before {
    content: '';
    width: 5px;
    height: 18px;
    position: absolute;
    left: 7px;
    top: calc((100% - 18px) / 2);
    border-left: 2px dotted rgba(0,0,0,0.65);
    border-right: 2px dotted rgba(0,0,0,0.65);
    box-sizing: border-box;
  }

  &:first-child {
    &:before {
      content: none
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectLabel = styled.span`
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  color: ${COLORS.BLACK_G1};
`;

export const SelectLabel2 = styled.span`
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
  color: ${COLORS.BLACK_G1};
`;

export const RadioStyle = {
  display: 'block',
  marginBottom: 10,
};

export const CustomConfigContainer = styled.div`
  display: inline-block;
  width: calc(100% - 20px);
`;

export const CustomConfig = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonEditConfig = styled.span`
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const CallToSequencesContainer = styled.div`
  background: ${COLORS.PRIMARY_P4};
  border-radius: 2px;
  width: 100%;
  padding: 15px;
`;

export const CallToSequencesHeader = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${COLORS.BLACK_G1};
  margin: 0;
  margin-bottom: 5px;
`;

export const CallToSequencesDescription = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.GREY_G1};
  margin: 0;
  margin-bottom: 5px;
`;

export const ButtonTryNow = styled.button`
  border: 1px solid ${COLORS.PRIMARY};
  background: transparent;
  color: ${COLORS.PRIMARY};
  outline: none;
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }
`;
