import React, { useState } from 'react';
import {
  Switch,
} from 'antd';
import get from 'lodash/get';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { Flex, Label } from '@newlab-io/getprospect-react-components/lib/ui';

import {
  Search,
  Icon,
} from 'components/common';
import * as CONSTANTS from 'constants/styles';
import { renameLabel } from 'helpers/renameLabel';
import {
  Container,
  SelectedItem,
  SelectedPropertiesContainer,
  ScrollContainer,
} from './styles';
import {
  SearchPropertiesForm,
} from '../addCompaniesToContactBar/styles';

const PropertiesContent = ({
  onDragEnd,
  hideColumns,
  updateColumns,
  isDefaultColumn,
  selectedColumns,
  getPropertyLabel,
  getPropertyIsDefault,
}) => {
  const [searchName, setSearchName] = useState('');

  return (
    <Container>
      <Search
        onChange={(e) => setSearchName(e.target.value.toLowerCase())}
        style={SearchPropertiesForm}
        placeholder="Search properties"
        autoFocus
        size="default"
      />
      <Flex style={{ paddingTop: 12, paddingBottom: 6 }} justify="space-between">
        <Label size="small" fontWeight={500} kind="quaternary">
          Properties
        </Label>
        <Label style={{ cursor: 'pointer' }} onClick={hideColumns} size="small" fontWeight={500} kind="primary">
          Hide all
        </Label>
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="properties-column-1">
          {(droppableProvided) => (
            <SelectedPropertiesContainer
              ref={droppableProvided.innerRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProvided.droppableProps}
            >
              <ScrollContainer>
                {selectedColumns.filter((item) => renameLabel(get(item, 'name', '')).toLowerCase().includes(searchName)).map((item, index) => (
                  <Draggable
                    prefix={(<Icon size="small" type="ic-drag-indicator" className="ic_drag_indicator" />)}
                    key={`draggble_item_${item.name}`}
                    draggableId={`draggble_item_${item.name}`}
                    index={index}
                    isDragDisabled={item.hidden || item.name === 'name'}
                  >
                    {(draggableProvided, snapshot) => {
                      const droppableStyle = {
                        ...draggableProvided.draggableProps.style,
                        borderColor: snapshot.isDragging && CONSTANTS.COLORS.PRIMARY,
                      };

                      return (
                        <SelectedItem
                          key={`selected_item_${item.name}`}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...draggableProvided.draggableProps}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                          style={droppableStyle}
                        >
                          <Flex align="center">
                            <Icon
                              size="small"
                              style={{ marginRight: '7px' }}
                              alt=""
                              type="ic-drag-indicator"
                              className="ic_drag_indicator"
                            />
                            <Label size="small" fontWeight={500} kind="secondary">
                              {renameLabel(getPropertyLabel(item.name), getPropertyIsDefault(item.name))}
                            </Label>
                          </Flex>
                          <Switch disabled={isDefaultColumn(item.name)} checked={!item.hidden} size="small" onClick={() => updateColumns(item.name, !item.hidden)} />
                        </SelectedItem>
                      );
                    }}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </ScrollContainer>
            </SelectedPropertiesContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

export default PropertiesContent;
