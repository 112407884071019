import React, {
  useCallback, useMemo, useState,
} from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { searchFiltersSelector } from 'data/selectors/search';
import { useSelector } from 'react-redux';
import { addOptionToIncludedFilters, removeOptionFromFilters } from 'data/actions/search';
import useResetParams from 'hooks/useResetParams';

import { Checkbox } from 'components/common';
import { IconArrow } from 'components/listSelect/styles';
import {
  ControlsContainer,
  ControlsWrapper,
  FilterEditorContainer,
  InputDatesWrapper,
  InputDatesDivider,
  FilterDateSelect,
  FilterDateOption,
} from '../../../sidebar/styles';

import { CheckboxGroupWrapper } from '../../styles';

const currentYear = moment().year();

const DateRangeFilter = ({
  filter,
}) => {
  const [isOpenMin, setIsOpenMin] = useState(false);
  const [isOpenMax, setIsOpenMax] = useState(false);

  const filters = useSelector(searchFiltersSelector);
  const [minDate, maxDate, includeUnknown] = get(filters.find((item) => item.property === filter.property), 'included.value[0]', []);

  const resetParams = useResetParams((element) => addOptionToIncludedFilters({
    property: filter.property, includedOp: filter.includedOp, element, updateOnly: filter.updateOnly,
  }));
  const removeFilter = useResetParams(() => removeOptionFromFilters({ property: filter.property, element: [minDate, maxDate, includeUnknown] }));

  const dateOptions = useMemo(() => Array(currentYear - 1899).fill(1).map((_, i) => (<FilterDateOption key={nanoid()} value={`${currentYear - i}`}>{currentYear - i}</FilterDateOption>)), []);

  const updateParams = useCallback(debounce((range) => {
    const [currMinDate, currMaxDate] = range;

    if (!currMinDate && !currMaxDate) {
      removeFilter();
      return;
    }

    if ((typeof currMinDate !== 'undefined' && `${currMinDate}`.length < 4) || (typeof currMaxDate !== 'undefined' && `${currMaxDate}`.length < 4)) {
      return;
    }
    resetParams(range);
  }, 500), [removeFilter, resetParams]);

  const popupRender = useCallback(() => document.querySelector('.filter-date-target'), []);

  return (
    <FilterEditorContainer>
      <ControlsContainer>
        <ControlsWrapper>
          <InputDatesWrapper>
            <FilterDateSelect
              style={{ width: 110, height: 32 }}
              showSearch
              placeholder="Min year"
              optionFilterProp="value"
              filterOption={(input, option) => option?.props?.value?.indexOf(input.toLowerCase()) >= 0}
              onChange={(data) => {
                const value = Number(data);

                if (value > maxDate) {
                  updateParams([value, value, includeUnknown]);
                } else {
                  updateParams([value, maxDate, includeUnknown]);
                }
              }}
              value={minDate}
              getPopupContainer={popupRender}
              notFoundContent="Not found"
              dropdownClassName="dropdown-date"
              onDropdownVisibleChange={(visible) => setIsOpenMin(visible)}
              suffixIcon={<IconArrow open={isOpenMin ? true : ''} />}
            >
              {dateOptions}
            </FilterDateSelect>
            <InputDatesDivider />
            <FilterDateSelect
              style={{ width: 110, height: 32 }}
              showSearch
              placeholder="Max year"
              optionFilterProp="value"
              filterOption={(input, option) => option?.props?.value?.indexOf(input.toLowerCase()) >= 0}
              onChange={(data) => {
                const value = Number(data);

                if (value && (minDate > value)) {
                  updateParams([minDate, minDate, includeUnknown]);
                } else {
                  updateParams([minDate, value, includeUnknown]);
                }
              }}
              value={maxDate}
              getPopupContainer={popupRender}
              notFoundContent="Not found"
              dropdownClassName="dropdown-date"
              onDropdownVisibleChange={(visible) => setIsOpenMax(visible)}
              suffixIcon={<IconArrow open={isOpenMax ? true : ''} />}
            >
              {dateOptions}
            </FilterDateSelect>
          </InputDatesWrapper>
        </ControlsWrapper>
        <CheckboxGroupWrapper>
          <Checkbox
            size="small"
            checked={includeUnknown}
            onChange={(props) => {
              updateParams([minDate, maxDate, props.target.checked]);
            }}
          >
            Include with unknown year
          </Checkbox>
        </CheckboxGroupWrapper>
      </ControlsContainer>
    </FilterEditorContainer>
  );
};

export default DateRangeFilter;
