import React from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import { isDisabledPauseSelector } from 'data/selectors/subscriptions';
import { AskText } from 'components/modals/cancelSubscriptionModal/styles';
import {
  Button,
} from 'components/common';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';

const PauseQuestion = ({
  onClose,
  onNext,
}) => {
  const isDisabledPause = useSelector(isDisabledPauseSelector);

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          Want to pause your plan instead?
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={onClose}
    >
      <div>
        <AskText>If you&lsquo;re done with your project, pause your subscription and enjoy a 30% discount when it resumes.</AskText>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            disabled={isDisabledPause}
            onClick={() => onNext({ wantsToPause: true })}
          >
            More about pause
          </Button>
          <Button
            type="normal"
            onClick={onNext}
          >
            Cancel now
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default PauseQuestion;
