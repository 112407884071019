import styled from 'styled-components';

export const WarningText = styled.p`
  text-wrap: auto;
  margin-top: 10px;
  margin-left: 30px;
  font-style: italic;
  
  b {
    font-weight: 700;
    color: black;
  }
`;
