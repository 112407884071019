import moment from 'moment';
import get from 'lodash/get';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';
import React,
{
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  Modal,
} from 'antd';

import {
  Button,
} from 'components/common';
import { SOURCE_TYPE } from 'data/types/source.types';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { exportData } from 'data/actions/export';
import {
  contactsFilterSelector,
  contactsFiltersSelector,
  selectedContactsSelector,
  sortContactsSelector,
  selectedAllContactsSelector,
  excludedContactsSelector,
} from 'data/selectors/contacts';
import usePaidOperation from 'hooks/usePaidOperation';
import { CREDITS_TYPE } from 'data/types/user.types';
import { createInsightsSearch } from 'data/actions/search';
import { exportDataLoaderSelector } from 'data/selectors/loading';
import {
  companiesFiltersSelector,
  companiesFilterSelector,
  sortCompaniesSelector,
  selectedCompaniesSelector,
  selectedAllCompaniesSelector,
  excludedCompaniesSelector,
} from 'data/selectors/companies';
import { staticListsSelector } from 'data/selectors/lists';

import { toggleMessage } from 'data/actions/message';
import { exportColumnsConfigurationSelector } from 'data/selectors/user';
import {
  ModalBody,
} from './styles';

import {
  ModalTitle,
  CloseIcon,
  Footer,
} from '../baseModal/styles';
import CallToSequences from './callToSequences';
import ExportBody from './exportBody';

const ExportModal = () => {
  const dispatch = useDispatch();

  const contactSort = useSelector(sortContactsSelector);
  const companySort = useSelector(sortCompaniesSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const allContactFilters = useSelector(contactsFilterSelector);
  const allCompanyFilters = useSelector(companiesFilterSelector);
  const allLists = useSelector(staticListsSelector);
  const { visible } = useSelector(modalsSelector);
  const contactFilters = useSelector(contactsFiltersSelector);
  const companyFilters = useSelector(companiesFiltersSelector);
  const selectedContacts = useSelector(selectedContactsSelector);
  const selectedCompanies = useSelector(selectedCompaniesSelector);
  const exportColumns = useSelector(exportColumnsConfigurationSelector);
  const loading = useSelector(exportDataLoaderSelector);
  const selectedAllCompanies = useSelector(selectedAllCompaniesSelector);
  const selectedAllContacts = useSelector(selectedAllContactsSelector);
  const excludedContacts = useSelector(excludedContactsSelector);
  const excludedCompanies = useSelector(excludedCompaniesSelector);

  const isInsight = get(modalOptions, 'isInsight');
  const totalItems = get(modalOptions, 'totalItems');
  const selectedAll = get(modalOptions, 'selectedAll');
  const setSelectedRowKeys = get(modalOptions, 'setSelectedRowKeys');
  const perCompany = get(modalOptions, 'perCompany');
  const listId = get(modalOptions, 'listId');
  const saveAdded = get(modalOptions, 'saveAdded');
  const exportType = get(modalOptions, 'exportType');
  const sequenceId = get(modalOptions, 'sequenceId');
  const sourceType = get(modalOptions, 'sourceType', null);
  const includeOption = get(modalOptions, 'includeProperties', 'columns_only');
  const customFilters = get(modalOptions, 'customFilters');
  const filters = get(modalOptions, 'customFilters', sourceType === 'company' ? companyFilters : contactFilters);
  const allFilters = sourceType === 'company' ? allCompanyFilters : allContactFilters;
  const sort = sourceType === 'company' ? companySort : contactSort;
  let itemIds = get(modalOptions, 'itemIds');
  if (!itemIds && !sequenceId) {
    itemIds = sourceType === 'company' ? selectedCompanies : selectedContacts;
  }
  const showCustomConfiguration = sourceType === SOURCE_TYPE.CONTACT && !sequenceId;
  const selectAll = sourceType === 'company' ? selectedAllCompanies : selectedAllContacts;

  const [exportName, setExportName] = useState('');
  const [includeProperties, setIncludeProperties] = useState(includeOption);
  const [format, setFormat] = useState(localStorage.getItem('lastExportFormat') ?? 'csv');

  const exportCustomColumnsLength = useMemo(() => {
    const exportColumnsLength = sourceType === 'company' ? get(exportColumns, 'companies.length', 0) : get(exportColumns, 'contacts.length', 0);
    return exportColumnsLength;
  }, [exportColumns, sourceType]);

  const openExportConfigurationEditor = useCallback(() => {
    dispatch(toggleModal('export-configuration-editor', true, modalOptions));
  }, [dispatch, modalOptions]);

  const handleClose = useCallback(() => dispatch(toggleModal('export', false)), [dispatch]);

  const handleSaveSearch = usePaidOperation(CREDITS_TYPE.EMAIL_SEARCH, () => {
    const limits = {
      total: totalItems,
    };
    dispatch(createInsightsSearch(
      {
        selected: totalItems,
        source: SOURCE_TYPE.CONTACT,
        limits,
        selectedAll,
        saveAdded,
        perCompany,
        exportData: {
          includeProperties,
          fileFormat: format.toLowerCase(),
          filters: [],
          sourceType,
        },
        onSuccess: () => {
          dispatch(toggleMessage('info', { header: 'Preparing the file, you’ll be notified' }));
          if (setSelectedRowKeys) setSelectedRowKeys([]);
          handleClose();
        },
      },
    ));
  }, undefined, 1);

  const handleExport = useCallback(() => {
    if (!exportCustomColumnsLength && includeProperties === 'custom') {
      openExportConfigurationEditor();
    } else {
      localStorage.setItem('lastExportFormat', format);
      if (isInsight) {
        handleSaveSearch();
      } else {
        const ids = selectAll ? [] : itemIds;
        dispatch(exportData({
          sequenceId,
          name: exportName,
          filters,
          fileFormat: format.toLowerCase(),
          includeProperties,
          sourceType,
          sort,
          itemIds: Array.isArray(itemIds) && itemIds.length > 0 ? ids : undefined,
          excludedIds: sourceType === 'company' ? [...excludedCompanies] : [...excludedContacts],
        }, () => {
          dispatch(toggleMessage('info', { header: 'Preparing the file, you’ll be notified' }));
          handleClose();
        }));
      }
    }
  }, [dispatch, handleClose, exportCustomColumnsLength, itemIds, selectAll, format, exportName, includeProperties, filters, sort, sourceType]); // eslint-disable-line

  useEffect(() => {
    if (exportCustomColumnsLength) {
      setIncludeProperties('custom');
    }
  }, [exportCustomColumnsLength]);

  useEffect(() => {
    const { pathname } = window.location;
    const date = moment().format('YYYY_MM_DD');
    const source = pathname.split('/')?.[2] || 'contacts';
    const type = pathname.split('/')?.[3] || exportType;
    const id = pathname.split('/')?.[4] || listId;

    let prefix = `Filtered_${source}`;

    if (type === 'filter') {
      if (id === 'all') prefix = `All ${source}`;
      else {
        const filter = allFilters.find((item) => item._id === id);
        if (filter && filter.name) prefix = filter.name;
      }
    } else if (type === 'import') {
      prefix = `Import_${source}`;
    } else if (type === 'list') {
      if (id) {
        const list = allLists.find((item) => item._id === id);
        if (list) prefix = list.name;
      }
    }

    let name = replace(`${startCase(prefix)}_${date}`, / /g, '_');
    if (name.length > 31 && format === 'XLSX') {
      name = replace(`${startCase(source)}_${date}`, / /g, '_');
    }
    setExportName(name);
  }, [allFilters, allLists, listId, exportType, format]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Export
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <ModalBody>
        {
          sourceType === SOURCE_TYPE.CONTACT && !sequenceId && process.env.REACT_APP_SEQUENCES_ON && <CallToSequences customFilters={customFilters} />
        }
        <ExportBody
          format={format}
          setFormat={setFormat}
          sourceType={sourceType}
          includeProperties={includeProperties}
          setIncludeProperties={setIncludeProperties}
          showCustomConfiguration={showCustomConfiguration}
        />
      </ModalBody>
      <Footer onlyFooter>
        <Button
          data-form="true"
          type="primary"
          loading={loading}
          onClick={handleExport}
        >
          Confirm
        </Button>
        <Button type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>

    </Modal>
  );
};

export default ExportModal;
