import { Modal } from 'antd';
import React, { useState } from 'react';

import useFetch from 'hooks/useFetch';
import { Button, RadioGroup } from 'components/common';
import { getCheapestPlans } from 'data/actions/plans';
import { cheapestPlansSelector } from 'data/selectors/plans';
import { getCheapestPlansLoadingSelector } from 'data/selectors/loading';
import { AskText, ReasonRadio } from 'components/modals/cancelSubscriptionModal/styles';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';

const Discount = ({
  onNext,
  onClose,
}) => {
  const [cheapPlanId, setCheapPlanId] = useState();

  const { data: cheapestPlans, loading } = useFetch({
    selector: cheapestPlansSelector,
    action: getCheapestPlans,
    cached: true,
    loadingSelector: getCheapestPlansLoadingSelector,
  });

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          Continue using GetProspect, at a reduced price
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={onClose}
    >
      <div>
        <AskText>
          We would like to make a special offer:
        </AskText>
        <RadioGroup
          value={cheapPlanId}
          onChange={(e) => setCheapPlanId(e.target.value)}
        >
          {cheapestPlans.map((plan) => (
            <ReasonRadio key={plan._id} value={plan._id}>
              <span>
                {`${plan.name} plan for $${plan.price} per month`}
              </span>
            </ReasonRadio>
          ))}
        </RadioGroup>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            loading={loading}
            disabled={!cheapPlanId}
            onClick={() => onNext({ cheapPlanId })}
          >
            Use discounted plan
          </Button>
          <Button
            data-form="true"
            type="normal"
            loading={loading}
            onClick={() => onNext({})}
          >
            Cancel anyway
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default Discount;
