import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Empty,
} from 'antd';

import Loader from 'components/loading';
import { toggleModal } from 'data/actions/modals';
import { CREDITS_TYPE } from 'data/types/user.types';
import { SOURCE_TYPE } from 'data/types/source.types';
import usePaidOperation from 'hooks/usePaidOperation';
import { createInsightsSearch } from 'data/actions/search';
import {
  Button,
  Select,
  Option,
  Checkbox,
  Icon,
} from 'components/common';
import { addToSequenceLoadingSelector } from 'data/selectors/loading';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import useContactsSavedMessage from 'hooks/messageTemplates/useContactsSavedMessage';
import { isAdditingLeadsSelector, selectedSequenceSelector } from 'data/selectors/sequences';
import { addContactsToSequence, createSequence, getSequencesByName } from 'data/actions/sequences';

import {
  CloseIcon,
  ModalTitle,
  Label,
  Footer,
} from '../baseModal/styles';
import { NewSequenceContainer } from './styles';

const AddToSequenceModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const {
    isInsight,
    selectedItems,
    selectedAll,
    totalItems,
    setSelectedRowKeys,
    customFilters,
    perCompany,
    saveAdded,
  } = useSelector(modalOptionsSelector);
  const isAdditingLeads = useSelector(isAdditingLeadsSelector);
  const processing = useSelector(addToSequenceLoadingSelector);
  const { _id, name: sequenceName } = useSelector(selectedSequenceSelector);

  const [sequenceId] = useState(_id);
  const [loading, setLoading] = useState(false);
  const [loadingToCreate, setLoadingToCreate] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [visibleSelect, setVisibleSelect] = useState(false);
  const [openSequence, setOpenSequence] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState({
    sequenceId: isAdditingLeads ? sequenceId : undefined,
    sequenceName: isAdditingLeads ? sequenceName : undefined,
  });
  const [options, setOptions] = useState([]);

  const showContactsSavedMessage = useContactsSavedMessage();

  const selectedContactsLength = selectedAll ? totalItems : get(selectedItems, 'length', 0);

  const handleClose = () => dispatch(toggleModal('add_to_sequence', false));

  const handleSearch = useCallback(debounce((value) => {
    setSearchValue(value);
    dispatch(getSequencesByName(value, ({ data }) => {
      setOptions(data.filter((sequence) => sequence?.name?.replace(/\s/g, '')).map((sequence) => ({ title: sequence.name, value: sequence._id })));
      setLoading(false);
    }, () => setLoading(false)));
  }, 1000), [dispatch]);

  const handleSaveSearch = usePaidOperation(CREDITS_TYPE.EMAIL_SEARCH, () => {
    const limits = {
      total: totalItems,
    };

    const needToProcess = selectedContactsLength === 0;
    dispatch(createInsightsSearch(
      {
        selected: totalItems,
        source: SOURCE_TYPE.CONTACT,
        listIds: [],
        limits,
        selectedAll,
        saveAdded,
        selectedItems,
        customFilters,
        sequenceId: selectedSequence.sequenceId || sequenceId,
        perCompany,
        onSuccess: () => {
          if (needToProcess || !openSequence) {
            showContactsSavedMessage({
              pending: true,
              countUpdatedItems: totalItems,
              selectedContactsLength,
              openSequence,
              sequenceId,
              selectedSequenceId: selectedSequence.sequenceId,
            });
          }
          if (setSelectedRowKeys) setSelectedRowKeys([]);
          if (openSequence) {
            history.push(`/sequences/${selectedSequence.sequenceId}/steps`);
          }
          handleClose();
        },
      },
    ));
  }, undefined, 1);

  const handleAddLeads = () => {
    if (isInsight) {
      handleSaveSearch();
    } else {
      dispatch(addContactsToSequence({
        sequenceId: selectedSequence.sequenceId || sequenceId,
        contactsIds: selectedItems,
        customFilters,
        onSuccess: (data) => {
          showContactsSavedMessage({
            ...data,
            selectedContactsLength,
            openSequence,
            sequenceId,
            selectedSequenceId: selectedSequence.sequenceId,
          });
          if (setSelectedRowKeys) setSelectedRowKeys([]);
          handleClose();
          if (openSequence) {
            history.push(`/sequences/${selectedSequence.sequenceId}/steps`);
          }
        },
      }));
    }
  };

  const handleCreateSequence = () => {
    setLoadingToCreate(true);
    dispatch(createSequence({ name: searchValue }, (createdSequence) => {
      setVisibleSelect(false);
      setSearchValue('');
      setLoadingToCreate(false);
      setSelectedSequence({ sequenceName: createdSequence.name, sequenceId: createdSequence._id });
      setOptions([{ title: createdSequence.name, value: createdSequence._id }]);
    }, () => setLoading(false)));
  };

  const renderNotFoundContent = () => {
    if (loading) return <Loader style={{ marginLeft: 15 }} loading />;
    if (!searchValue) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    return (
      <NewSequenceContainer loading={loadingToCreate} onClick={() => !loadingToCreate && handleCreateSequence()}>
        <Icon style={{ marginLeft: 10 }} type="ic-add" />
        <span style={{ marginLeft: 15, marginRight: loadingToCreate ? 5 : 0 }}>
          {`Create sequence "${searchValue}"`}
        </span>
        <Loader loading={loadingToCreate} />
      </NewSequenceContainer>
    );
  };

  useEffect(() => {
    dispatch(getSequencesByName('', ({ data }) => {
      setOptions(data.filter((sequence) => sequence?.name?.replace(/\s/g, '')).map((sequence) => ({ title: sequence.name, value: sequence._id })));
    }));
  }, [dispatch]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Add leads to sequence
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={450}
      closable={false}
      footer={false}
      onCancel={handleClose}
    >
      {
        !isAdditingLeads ? (
          <>
            <Label style={{ marginBottom: '20px', display: 'block' }}>
              Please, select or create the sequence to add leads to
            </Label>
            <Select
              open={visibleSelect}
              onDropdownVisibleChange={setVisibleSelect}
              dropdownClassName="add-to-sequence"
              allowClear
              notFoundContent={renderNotFoundContent()}
              placeholder="Select sequence"
              showSearch
              onSearch={(e) => {
                setLoading(true);
                handleSearch(e);
              }}
              size="default"
              value={selectedSequence.sequenceId}
              filterOption={(input, o) => o?.props?.children && o.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value, _options) => {
                setSelectedSequence({
                  sequenceId: value,
                  sequenceName: get(_options, 'props.children'),
                });
              }}
            >
              {options.map((option) => (
                <Option
                  value={option.value}
                  key={`property_item_option_${option.value}`}
                >
                  {option.title}
                </Option>
              ))}
            </Select>
            <Checkbox
              style={{ marginTop: 10 }}
              size="default"
              checked={openSequence}
              onChange={(e) => {
                setOpenSequence(e.target.checked);
              }}
            >
              Open sequence
            </Checkbox>
          </>
        ) : (
          <Label style={{ marginBottom: '20px', display: 'block' }}>
            {`Do you want to add ${selectedContactsLength > 0 ? selectedContactsLength : ''} contact(s) ${selectedSequence.sequenceName ? 'to the:' : ''}`}
            {
              selectedSequence.sequenceName && (
                <>
                  &nbsp;
                  &quot;
                  <strong>{selectedSequence.sequenceName}</strong>
                  &quot;?
                </>
              )
            }
          </Label>
        )
      }
      <Footer>
        <Button
          data-form={!selectedSequence.sequenceId ? 'false' : 'true'}
          type="primary"
          size="large"
          loading={processing}
          onClick={handleAddLeads}
          disabled={!selectedSequence.sequenceId}
        >
          Add leads
        </Button>
        <Button size="large" type="normal" onClick={handleClose}>Cancel</Button>
      </Footer>
    </Modal>
  );
};

export default AddToSequenceModal;
