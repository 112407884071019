import React from 'react';
import moment from 'moment';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import useFetch from 'hooks/useFetch';
import { getAllTeammates } from 'data/actions/teammates';
import { allTeammatesSelector } from 'data/selectors/teammates';
import { currentPlanSelector, subscriptionsSelector } from 'data/selectors/subscriptions';
import { AskText } from 'components/modals/cancelSubscriptionModal/styles';
import {
  Button,
} from 'components/common';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../../../../modals/baseModal/styles';

const Confirmation = ({
  onClose,
  onNext,
}) => {
  const currentPlan = useSelector(currentPlanSelector);
  const currentSubscription = useSelector(subscriptionsSelector);
  const { data: teammates } = useFetch({
    action: getAllTeammates,
    selector: allTeammatesSelector,
  });

  return (
    <Modal
      visible
      title={(
        <ModalTitle>
          Confirm your cancellation
          <CloseIcon
            onClick={onClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={onClose}
    >
      <div>
        <AskText style={{ marginBottom: 10 }}>
          Please carefully review the changes before confirming:
        </AskText>
        <ul style={{ paddingLeft: 15 }}>
          <li>
            <strong>Plan Change:</strong>
            &nbsp;
            {`Your current ${currentPlan.name} plan will automatically cancel on ${moment(currentSubscription.subscriptionExpiration).format('MMMM DD, YYYY')}, and your account will switch to the Free plan.`}
          </li>
          <li>
            <strong>Data Preservation:</strong>
            &nbsp;
            All your data will remain intact, and you can export it at any time.
          </li>
          <li>
            <strong>Credits:</strong>
            &nbsp;
            You can use the remaining credits until the subscription expiration date; unused credits will not roll over to the Free plan.
          </li>
          {
            teammates.length > 0 && (
              <li>
                <strong>Team Members:</strong>
                &nbsp;
                {`${teammates.length} team members are using this subscription, and they will also revert to the Free plan upon cancellation.`}
              </li>
            )
          }
        </ul>
        <Footer>
          <Button
            data-form="true"
            type="danger"
            onClick={() => onNext({})}
          >
            Confirm and cancel my subscription
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default Confirmation;
