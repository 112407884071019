import get from 'lodash/get';
import moment from 'moment';
import * as SearchFiltersTypes from 'data/types/search.filters.types';

export const getNameFilters = ({
  name,
  filters,
  sourceType,
}) => {
  if (name) return name;
  return filters.some((filter) => !!get(filter, 'included.value.length', 0))
    ? filters
      .map((filter) => {
        if (filter?.property === SearchFiltersTypes.CONTACT_EMAIL) {
          const emailFilter = get(filter, 'included.value[0]', null);
          if (emailFilter) return emailFilter === 'all' ? ['With emails'] : ['All contacts'];
        }
        if ([SearchFiltersTypes.CONTACT_UPDATED_AT, SearchFiltersTypes.COMPANY_UPDATED_AT].includes(filter?.property)) {
          const date = get(filter, 'included.value[0][0]', null);
          const now = moment();
          const monthPast = moment(now).diff(date, 'months');
          return `< ${monthPast} months ago`;
        }
        if (filter?.property === SearchFiltersTypes.COMPANY_FOUNDED_AT) {
          const minDate = get(filter, 'included.value[0][0]', null);
          const maxDate = get(filter, 'included.value[0][1]', null);
          const unknown = get(filter, 'included.value[0][2]', null);
          return `${minDate ?? '<'}-${maxDate ?? '>'} ${unknown ? ' or unknown' : ''}`;
        }
        if (filter?.property === SearchFiltersTypes.COMPANY_TECHNOLOGIES) {
          const technologyName = get(filter, 'included.value[0][0]', null);
          return technologyName;
        }
        return get(filter, 'included.value', []);
      })
      .flat()
      .map((value) => {
        if (typeof value === 'object') {
          if (value?.printedData) {
            return value.printedData;
          }
          if (value?.[0]) return value?.[0];
          return '';
        }
        return value;
      })
      .join(', ')
    : `${sourceType ? `Object ${sourceType}` : ''}`;
};
