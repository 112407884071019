import { Modal } from 'antd';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { exportColumnsConfigurationSelector } from 'data/selectors/user';
import { updateWorkspaceSettings } from 'data/actions/user';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { renameLabel } from 'helpers/renameLabel';
import { propertiesSelector } from 'data/selectors/properties';
import { SOURCE_TYPE } from 'data/types/source.types';
import PropertiesContent from 'components/propertyListEditor/propertiesContent';

import {
  Footer,
  ModalWrapper,
} from '../../propertyListEditor/styles';

import {
  ModalTitle,
  CloseIcon,
} from '../baseModal/styles';

const excludedFields = ['name', 'associated_company'];
const excludeCompanyFields = ['technologies', 'keywords'];
const systemProperties = [
  {
    name: 'getProspectId',
    label: 'GetProspect id',
    sourceType: 'contact',
  },
  {
    label: 'Owner',
    name: 'owner',
    sourceType: 'contact',
  },
  {
    label: 'Workspace',
    name: 'workspace',
    sourceType: 'contact',
  },
  {
    label: 'List',
    name: 'list',
    sourceType: 'contact',
  },
  {
    label: 'Country',
    name: 'country',
    sourceType: 'contact',
  },
];

const ExportConfigurationEditor = () => {
  const dispatch = useDispatch();

  const properties = useSelector(propertiesSelector);
  const currentColumns = useSelector(exportColumnsConfigurationSelector);

  const [loading, setLoading] = useState(false);
  const { visible } = useSelector(modalsSelector);
  const modalOptions = useSelector(modalOptionsSelector);
  const backModal = get(modalOptions, 'backModal', 'export');
  const backOptions = get(modalOptions, 'backOptions', {});
  const sourceType = get(modalOptions, 'sourceType', SOURCE_TYPE.CONTACT);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const configurationSourceType = sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies';

  const handleClose = () => dispatch(toggleModal(backModal, true, { ...modalOptions, ...backOptions }));

  const fullProperties = useMemo(() => {
    const companyPropertiesForContacts = sourceType === SOURCE_TYPE.CONTACT ? properties.filter((property) => property.sourceType === SOURCE_TYPE.COMPANY).map((property) => ({
      _id: property._id,
      label: property.label.startsWith('Company') ? property.label : `Company ${property.label}`,
      name: `associated_company_${property.name}`,
      sourceType: SOURCE_TYPE.COMPANY,
      group: {
        name: 'Company properties',
      },
      virtualRequired: !excludeCompanyFields.includes(property.name),
      default: property?.default,
    })) : [];
    const virtualProperties = [
      {
        label: 'Owner',
        name: 'owner',
        sourceType: SOURCE_TYPE.CONTACT,
      },
      {
        label: 'Owner',
        name: 'owner',
        sourceType: SOURCE_TYPE.COMPANY,
      },
    ];
    return [...properties, ...companyPropertiesForContacts, ...virtualProperties];
  }, [sourceType, properties]);

  useEffect(() => {
    if (currentColumns && visible && currentColumns[configurationSourceType]) {
      setSelectedColumns(currentColumns[configurationSourceType]);
    }
  }, [currentColumns, visible, sourceType, configurationSourceType]);

  const getPropertyLabel = (propertyName) => get(fullProperties.find((prop) => prop.name === propertyName), 'label', startCase(propertyName));
  const getPropertyIsDefault = (propertyName) => get([...systemProperties, ...properties].find((prop) => prop.name === propertyName), 'default');

  useEffect(() => {
    const filteredProperties = fullProperties.filter((item) => ((sourceType === item.sourceType && !item.hidden) || item.virtualRequired) && !excludedFields.includes(renameLabel(item.name)));
    const visibleColumns = currentColumns[configurationSourceType].map((item) => ({ name: get(item, 'propertyName'), hidden: false, sourceType: item.sourceType }));
    const nonVisibleColumns = filteredProperties.filter((property) => {
      const hasColumn = visibleColumns.find((column) => column.name === property.name);
      return !hasColumn;
    }).map((property) => ({ name: property.name, hidden: true, sourceType: property.sourceType }));
    const _selectedColumns = [
      ...visibleColumns,
      ...nonVisibleColumns,
    ];
    setSelectedColumns(_selectedColumns);
  }, [currentColumns, sourceType, configurationSourceType, fullProperties]);

  const getUpdatedRequest = () => {
    const exportColumnsConfiguration = {
      ...currentColumns,
      [configurationSourceType]: selectedColumns.filter((item) => !item.hidden).map((item) => ({ propertyName: item.name, sourceType: item.sourceType })),
    };
    return { exportColumnsConfiguration };
  };

  const isDefaultColumn = () => false;

  const hideColumns = () => setSelectedColumns(selectedColumns.map((column) => ({ ...column, hidden: !isDefaultColumn(column.name) })));

  const onDragEnd = (result) => {
    const { destination, source } = result;
    const newSelectedColumns = [...selectedColumns];

    if (!destination) return;
    if ((destination.droppableId === source.droppableId && destination.index === source.index) || destination.index === 0) return;

    newSelectedColumns.splice(source.index, 1);
    newSelectedColumns.splice(destination.index, 0, selectedColumns[source.index]);
    setSelectedColumns(newSelectedColumns);
  };

  const updateColumns = (column, value) => {
    const updatedColumns = [...selectedColumns].map((item) => {
      if (item.name === column) {
        return {
          ...item,
          hidden: value,
        };
      }
      return item;
    });
    setSelectedColumns(updatedColumns);
  };

  const handleSave = () => {
    setLoading(true);
    dispatch(updateWorkspaceSettings(getUpdatedRequest(), () => {
      setLoading(false);
      dispatch(toggleModal(backModal, true, { ...modalOptions, ...backOptions }));
    }, () => {
      setLoading(false);
    }));
  };

  return (
    <ModalWrapper>
      <Modal
        visible={visible}
        title={(
          <ModalTitle>
            Exported columns
            <CloseIcon
              onClick={handleClose}
              className="ic_close"
            />
          </ModalTitle>
        )}
        width={350}
        footer={false}
        bodyStyle={{ padding: '20px 0px' }}
        closable={false}
        onCancel={handleClose}
      >
        <PropertiesContent
          onDragEnd={onDragEnd}
          hideColumns={hideColumns}
          updateColumns={updateColumns}
          selectedColumns={selectedColumns}
          getPropertyLabel={getPropertyLabel}
          getPropertyIsDefault={getPropertyIsDefault}
          isDefaultColumn={isDefaultColumn}
        />
        <Footer>
          <Button
            data-form={loading ? 'false' : 'true'}
            loading={loading}
            type="primary"
            size="large"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            type="normal"
            size="large"
          >
            Back
          </Button>
        </Footer>
      </Modal>
    </ModalWrapper>
  );
};

export default ExportConfigurationEditor;
