import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Popover,
} from 'antd';

import { Icon } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import {
  IconCaretDown,
  Button,
  MenuItem,
  MobileButtonContainer,
  MobileMoreButton,
} from '../listAllOptions/styles';

const SavedSearchOptions = ({
  name = '',
  sourceType,
  savedSearchId,
  placement = 'bottomRight',
  backModal,
  backOptions,
  backScrollListId,
  titleExists = true,
  hasArrow,
  setIsShowedOptions,
  align = {
    offset: ['0px', '-13px'],
  },
}) => {
  const dispatch = useDispatch();

  const [visible, toggleVisible] = useState(false);

  useEffect(() => {
    if (setIsShowedOptions) {
      setIsShowedOptions(visible);
    }
  }, [setIsShowedOptions, visible]);

  const newBackOptions = backOptions ? { ...backOptions } : undefined;
  if (newBackOptions && backScrollListId) {
    newBackOptions.scrollTop = document.getElementById(backScrollListId)?.scrollTop;
  }

  const content = [
    <MenuItem
      isActivity
      onClick={
        (e) => {
          e.stopPropagation();
          toggleVisible(false);
          window.open(`/search/${sourceType}/${savedSearchId}`, '_blank');
        }
      }
    >
      Open in new tab
    </MenuItem>,
    <MenuItem
      isActivity
      onClick={
        (e) => {
          e.stopPropagation();
          toggleVisible(false);
          dispatch(toggleModal('delete_saved_search', true, { savedSearchId, backModal, backOptions: newBackOptions }));
        }
      }
    >
      Delete
    </MenuItem>,
    <MenuItem
      isActivity
      onClick={
        (e) => {
          e.stopPropagation();
          toggleVisible(false);
          dispatch(toggleModal('rename_saved_search', true, {
            savedSearchId,
            oldName: name,
            backModal,
            backOptions: newBackOptions,
          }));
        }
      }
    >
      Rename
    </MenuItem>,
  ];

  const contentWithKeys = content.map((item, index) => ({
    ...item,
    key: `list-options-${index}`,
  }));

  return (
    <Popover
      onVisibleChange={toggleVisible}
      visible={visible}
      placement={placement}
      content={contentWithKeys}
      trigger="click"
      align={align}
      overlayClassName={`list_popover ${hasArrow ? '' : 'hide-popover-arrow'}`}
      getPopupContainer={(node) => node}
    >
      {/* eslint-disable-next-line */}
      <div style={{ height: 24 }} onClick={(e) => e.stopPropagation()}>
        {
          titleExists ? (
            <>
              <Button visible={visible} className="more_button" type="button">
                Actions
                <IconCaretDown rotate={visible} type="caret-down" />
              </Button>
              <MobileButtonContainer className="mobile_more_button_container">
                <MobileMoreButton className="mobile_more_button" />
              </MobileButtonContainer>
            </>
          ) : (
            <Icon style={{ cursor: 'pointer' }} type="ic-more-vert" />
          )
        }
      </div>
    </Popover>
  );
};

export default SavedSearchOptions;
