import { get, sum } from 'lodash';

import React, {
  useRef,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { demoUserSelector } from 'data/selectors/user';
import { ClearFilterTag } from 'components/sidebar/styles';
import { searchFiltersSelector } from 'data/selectors/search';
import { clearSearchFilter } from 'data/actions/search';

import {
  CollapseContainer,
  FiltersHeader,
  FilterContainer,
  HeaderText,
  FooterContainer,
  TabsContainer,
  SavedFiltersContainer,
} from './styles';
import FiltersStructure from './filtersStructure';
import FilterItem from './filtersGenerator/index';
import SavedFilters from './savedFilters';

const SearchFilters = ({ sourceType }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isDemoUser = useSelector(demoUserSelector);
  const filters = useSelector(searchFiltersSelector);

  const closeFilters = useRef([]);

  const [tab, setTab] = useState('filters');
  const [filtersCount, setFiltersCount] = useState(0);
  const [collapseKey, setCollapseKey] = useState('');

  useEffect(() => {
    if (filters.length >= 1) {
      const emailCount = filters.some((i) => i.property === 'email') ? 1 : 0;
      const count = [];
      filters.forEach((element) => {
        count.push(element.included.value?.length || 0);
      });
      setFiltersCount(sum(count) - emailCount);
    } else {
      setFiltersCount(0);
    }
  }, [filters]);

  return (
    <>
      <FilterContainer className="filter-date-target">
        <TabsContainer>
          <FiltersHeader
            active={tab === 'filters'}
            borderRight
            onClick={() => setTab('filters')}
            filtersCount={filtersCount}
          >
            <HeaderText active={tab === 'filters'}>
              Filters
            </HeaderText>
            {
              filtersCount > 0 && (
                <ClearFilterTag
                  closable
                  type="primary"
                  onClose={() => {
                    dispatch(clearSearchFilter());
                    history.push('/search/contact/activity');
                  }}
                  style={{ marginRight: 0 }}
                >
                  {filtersCount}
                </ClearFilterTag>
              )
            }
          </FiltersHeader>
          <FiltersHeader
            active={tab === 'savedFilters'}
            onClick={() => setTab('savedFilters')}
          >
            <HeaderText active={tab === 'savedFilters'}>
              Saved filters
            </HeaderText>
          </FiltersHeader>
        </TabsContainer>
        {
          tab === 'filters' && (
            <>
              <CollapseContainer className="search_filters">
                {
                FiltersStructure.filter(({ visibleTabs }) => visibleTabs.includes(sourceType)).map((filtersGroup) => (
                  <div
                    key={`collapse_group_contacts_${filtersGroup.groupName}`}
                    data-tour={get(filtersGroup, 'dataTour', [])?.join(',')}
                  >
                    {
                      filtersGroup.filters.map((filter, index) => (
                        <FilterItem
                          first={index === 0}
                          closeFilter={(callback) => {
                            const isExist = closeFilters?.current?.find((existsCallback) => existsCallback === callback);

                            if (!isExist) {
                              closeFilters.current.push(callback);
                            }
                          }}
                          collapseKey={collapseKey}
                          setCollapseKey={setCollapseKey}
                          filter={filter}
                          key={`filter_item_${filtersGroup.groupName}_${filter.property}`}
                        />
                      ))
                    }
                  </div>
                ))
              }
              </CollapseContainer>
              <FooterContainer>
                <Button
                  disabled={!filtersCount || isDemoUser}
                  style={{ width: '100%' }}
                  type="normal"
                  size="medium"
                  onClick={() => { dispatch(toggleModal('save_search', true, { sourceType })); }}
                >
                  Save filters
                </Button>
              </FooterContainer>
            </>
          )
        }
        {
          tab === 'savedFilters' && (
            <SavedFiltersContainer>
              <SavedFilters
                onClick={(search) => {
                  history.push(`/search/${search.sourceType}/${search._id}`);
                  setTab('filters');
                }}
              />
            </SavedFiltersContainer>
          )
        }
      </FilterContainer>
    </>
  );
};
export default SearchFilters;
