import get from 'lodash/get';
import { nanoid } from 'nanoid';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';

import engine from 'helpers/liquid.engine';
import { removeSignature } from 'helpers/sequences/signature';
import { contactSelector } from 'data/selectors/contacts';
import { omitDeep, removeEmpty } from 'helpers/objectHelpers';
import { SEQUENCE_MESSAGE_STATUS_TYPE, SEQUENCE_STATUS_TYPE } from 'data/types/sequences.types';
import {
  customizeEmailSelector, enrollmentContactIdsSelector, leadReviewIdSelector, selectedSequenceSelector, sequenceActiveStepSelector,
  currentStepContextSelector,
  specificLeadStepContextSelector,
} from 'data/selectors/sequences';
import { createBulkOperation } from './bulkOperations';

export const getSequencesAnalytics = (sequenceIds, type = 'GET_SEQUENCES_ANALYTICS') => (dispatch) => {
  dispatch({
    type,
    payload: {
      endpoint: 'api/v1/sequences/analytics',
      method: 'POST',
      body: {
        sequenceIds,
      },
      showResponseError: true,
    },
    components: ['sequencesTable'],
  });
};

export const getDashboardSequences = () => (dispatch) => {
  const onSuccessProxy = (result) => {
    dispatch(getSequencesAnalytics(result.data.map(({ _id }) => _id)));
  };

  dispatch({
    type: 'GET_DASHBOARD_SEQUENCES',
    payload: {
      endpoint: 'api/v1/sequences/search',
      method: 'POST',
      params: {
        pageNumber: 1,
        pageSize: 5,
      },
      delayLoad: 0,
    },
    onSuccess: onSuccessProxy,
    components: ['sequencesTable'],
  });
};

export const getSequences = ({
  name,
  status,
  account,
  sort,
  pageSize: size,
  pageNumber,
  onSuccess = () => {},
} = {}) => (dispatch, getState) => {
  const state = getState();

  const {
    pageSize, page, sort: { column, order },
  } = get(state, 'sequences.sequences', {});

  const filters = get(state, 'sequences.sequences.filters', []);
  const { value: searchValue } = get(state, 'sequences.sequences.search', {});

  const params = {
    pageSize: size || pageSize,
    pageNumber: pageNumber || page,
  };

  if (!isEmpty(sort)) {
    params.sort = sort.column;
    params.order = sort.order;
  } else if (typeof sort === 'undefined') {
    params.sort = column;
    params.order = order;
  }

  const statusFilter = filters.find((filter) => filter.property === 'status')?.status;
  const accountFilter = filters.find((filter) => filter.property === 'account')?.account;

  const newStatus = status || statusFilter;
  const newAccount = account || accountFilter;
  const newName = name || searchValue;

  const body = {};

  if (status || (typeof status === 'undefined' && statusFilter)) {
    body.filters = [{
      property: 'status',
      value: newStatus,
    }];
  }

  if (account || (typeof account === 'undefined' && accountFilter)) {
    if (!body.filters) body.filters = [];
    body.filters.push({
      property: 'account',
      value: newAccount,
    });
  }

  if (name || (typeof name === 'undefined' && searchValue)) {
    body.search = {
      properties: ['name'],
      value: newName,
    };
  }

  const onSuccessProxy = (result) => {
    dispatch(getSequencesAnalytics(result.data.map(({ _id }) => _id)));
    onSuccess(result);
  };

  dispatch({
    type: 'GET_SEQUENCES',
    payload: {
      endpoint: 'api/v1/sequences/search',
      method: 'POST',
      body,
      params,
      showResponseError: true,
      delayLoad: 0,
    },
    ...body,
    onSuccess: onSuccessProxy,
    components: ['sequencesTable'],
  });
};

export const getSequencesByName = (name, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'GET_SEQUENCES_BY_NAME',
    payload: {
      endpoint: 'api/v1/sequences/search',
      method: 'POST',
      params: {
        pageSize: 100,
        pageNumber: 1,
      },
      body: {
        search: {
          properties: ['name'],
          value: name,
        },
      },
      delayLoad: 0,
      showResponseError: true,
    },
    components: ['getSequencesByNameLoader'],
    onSuccess,
    onFailed,
  });
};

export const removeSequences = (sequenceIds, onSuccess, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const filters = get(state, 'sequences.sequences.filters', []);
  const search = get(state, 'sequences.sequences.search');

  const selectAll = get(state, 'sequences.sequences.selectAll', false);

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'delete_sequences' }));
    }
    onSuccess(data);
  };

  dispatch({
    type: 'REMOVE_SEQUENCES',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'delete_sequences',
        sourceType: 'contacts',
        search,
        itemIds: selectAll ? [] : sequenceIds,
        filters,
      },
      showResponseError: true,
    },
    sequenceIds,
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const removeSequenceLeads = (sequenceId, contactsIds, onSuccess, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const filters = get(state, 'sequences.selectedSequence.leads.filters', []);
  const search = get(state, 'sequences.selectedSequence.leads.search', {});

  const selectAll = get(state, 'sequences.selectedSequence.leads.selectAll', false);

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'delete_sequence_contacts' }));
    }
    if (onSuccess) {
      onSuccess(data);
    }
  };

  dispatch({
    type: 'REMOVE_SEQUENCE_LEADS',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'delete_sequence_contacts',
        sourceType: 'contacts',
        sequenceId,
        search,
        itemIds: selectAll ? [] : contactsIds,
        filters,
      },
      showResponseError: true,
    },
    sequenceId,
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const unsubscribeSequenceLeads = (sequenceId, contactsIds, onSuccess, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const filters = get(state, 'sequences.selectedSequence.leads.filters', []);
  const search = get(state, 'sequences.selectedSequence.leads.search', {});

  const selectAll = get(state, 'sequences.selectedSequence.leads.selectAll', false);

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'unsubscribe_sequence_contacts' }));
    }
    onSuccess(data);
  };

  dispatch({
    type: 'UNSUBSCRIBE_SEQUENCE_LEADS',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'unsubscribe_sequence_contacts',
        sourceType: 'contacts',
        sequenceId,
        search,
        itemIds: selectAll ? [] : contactsIds,
        filters,
      },
      showResponseError: true,
    },
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const pauseSequenceLeads = (sequenceId, contactIds, onSuccess, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const filters = get(state, 'sequences.selectedSequence.leads.filters', []);
  const search = get(state, 'sequences.selectedSequence.leads.search', {});

  const selectAll = get(state, 'sequences.selectedSequence.leads.selectAll', false);

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'pause_sequence_contacts' }));
    }
    onSuccess(data);
  };

  dispatch({
    type: 'PAUSE_SEQUENCE_LEADS',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'pause_sequence_contacts',
        sourceType: 'contacts',
        sequenceId,
        search,
        itemIds: selectAll ? [] : contactIds,
        filters,
      },
      showResponseError: true,
    },
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const resumeSequenceLeads = (sequenceId, contactsIds, onSuccess, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const filters = get(state, 'sequences.selectedSequence.leads.filters', []);
  const search = get(state, 'sequences.selectedSequence.leads.search', {});

  const selectAll = get(state, 'sequences.selectedSequence.leads.selectAll', false);

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'resume_sequence_contacts' }));
    }
    onSuccess(data);
  };

  dispatch({
    type: 'PAUSE_SEQUENCE_LEADS',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'resume_sequence_contacts',
        sourceType: 'contacts',
        sequenceId,
        search,
        itemIds: selectAll ? [] : contactsIds,
        filters,
      },
      showResponseError: true,
    },
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const pauseSequences = (sequenceIds, onSuccess = () => {}, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const selectAll = get(state, 'sequences.sequences.selectAll', false);

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'pause_sequences' }));
    }
    onSuccess(data);
  };

  dispatch({
    type: 'PAUSE_SEQUENCES',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'pause_sequences',
        sourceType: 'contacts',
        itemIds: selectAll ? [] : sequenceIds,
        filters: [],
      },
      showResponseError: true,
    },
    sequenceIds,
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const resumeSequences = (sequenceIds, onSuccess = () => {}, onFailed = () => {}) => (dispatch, getState) => {
  const state = getState();
  const selectAll = get(state, 'sequences.sequences.selectAll', false);

  const onSuccessProxy = (data) => {
    if (data._id) {
      dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'resume_sequences' }));
    }
    onSuccess(data);
  };

  dispatch({
    type: 'RESUME_SEQUENCES',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'resume_sequences',
        sourceType: 'contacts',
        itemIds: selectAll ? [] : sequenceIds,
        filters: [],
      },
      showResponseError: true,
    },
    sequenceIds,
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const cloneSequence = (sequenceId, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'CLONE_SEQUENCE',
    payload: {
      endpoint: `api/v1/sequences/${sequenceId}/clone`,
      method: 'POST',
      showResponseError: true,
    },
    onSuccess,
  });
};

export const addContactsToSequence = ({
  sequenceId, contactsIds, onSuccess, onFailed = () => {},
  customFilters,
  sequence,
}) => (dispatch, getState) => {
  const state = getState();
  const filters = customFilters || get(state, 'contacts.contacts.filters', []);
  const search = get(state, 'contacts.contacts.search');

  const params = {
    selectAll: get(state, 'contacts.selectAllContacts.selectAll', false),
  };

  const onSuccessProxy = (data) => {
    if (onSuccess) {
      onSuccess(data);
    }
  };

  const itemIds = params.selectAll ? [] : contactsIds;

  dispatch({
    type: 'ADD_CONTACTS_TO_SEQUENCE',
    payload: {
      endpoint: 'api/v1/bulk-operations',
      method: 'POST',
      body: {
        type: 'add_to_sequence',
        sourceType: 'contacts',
        sequenceId,
        search,
        itemIds,
        filters,
      },
      delayLoad: 0,
    },
    itemIds,
    sequenceId,
    sequence,
    components: ['addContactsToSequenceLoader'],
    onSuccess: onSuccessProxy,
    onFailed,
  });
};

export const setEnrollmentDate = (enrollmentDate) => (dispatch) => {
  dispatch({
    type: 'SET_ENROLLMENT_DATE',
    data: enrollmentDate,
  });
};

export const setEnrollmentContacts = (enrollmentContacts = []) => (dispatch) => {
  dispatch({
    type: 'SET_ENROLLMENT_CONTACTS',
    data: enrollmentContacts,
  });
};

export const getSequenceStats = () => (dispatch, getState) => {
  const state = getState();

  const sequenceId = get(state, 'sequences.selectedSequence._id');
  const enrollmentDate = get(state, 'sequences.selectedSequence.stats.enrollmentDate', {});
  const contactStatusFields = [
    SEQUENCE_MESSAGE_STATUS_TYPE.SENT,
    SEQUENCE_MESSAGE_STATUS_TYPE.OPENED,
    SEQUENCE_MESSAGE_STATUS_TYPE.CLICKED,
    SEQUENCE_MESSAGE_STATUS_TYPE.REPLIED,
    SEQUENCE_MESSAGE_STATUS_TYPE.INTERESTED,
    SEQUENCE_MESSAGE_STATUS_TYPE['OPT-OUT'],
    SEQUENCE_MESSAGE_STATUS_TYPE.BOUNCED,
  ];

  dispatch({
    type: 'GET_SEQUENCE_STATS',
    payload: {
      endpoint: 'api/v1/sequences/analytics',
      method: 'POST',
      body: {
        sequenceIds: [sequenceId],
        contactStatusFields,
        ...removeEmpty({
          enrollmentDateFrom: enrollmentDate.from,
          enrollmentDateTo: enrollmentDate.to,
        }),
      },
      showResponseError: true,
    },
    contactStatusFields,
    components: ['sequenceStats'],
  });
};

export const getSequenceLeadsStats = () => (dispatch, getState) => {
  const state = getState();

  const sequenceId = get(state, 'sequences.selectedSequence._id');
  const contactStatusFields = [
    SEQUENCE_MESSAGE_STATUS_TYPE.SENT,
    SEQUENCE_MESSAGE_STATUS_TYPE.OPENED,
    SEQUENCE_MESSAGE_STATUS_TYPE.CLICKED,
    SEQUENCE_MESSAGE_STATUS_TYPE.REPLIED,
    SEQUENCE_MESSAGE_STATUS_TYPE.INTERESTED,
    SEQUENCE_MESSAGE_STATUS_TYPE['OPT-OUT'],
    SEQUENCE_MESSAGE_STATUS_TYPE.BOUNCED,
  ];

  dispatch({
    type: 'GET_SEQUENCE_LEADS_STATS',
    payload: {
      endpoint: 'api/v1/sequences/analytics',
      method: 'POST',
      body: {
        sequenceIds: [sequenceId],
        contactStatusFields,
      },
      showResponseError: true,
    },
    contactStatusFields,
    components: ['sequenceStats'],
  });
};

export const getSequenceStatsEngagedAudience = ({ filterBy } = {}, onSuccess = () => {}) => (dispatch, getState) => {
  const state = getState();

  const sequenceId = get(state, 'sequences.selectedSequence._id');
  const enrollmentDate = get(state, 'sequences.selectedSequence.stats.enrollmentDate', {});
  const columns = get(state, 'sequences.selectedSequence.settings.engagedAudienceColumns', ['position', 'industry', 'size']);
  const filterByPrevious = get(state, 'sequences.selectedSequence.stats.engagedAudience.filterBy', SEQUENCE_MESSAGE_STATUS_TYPE.SENT);

  const request = {
    filterBy: filterBy || filterByPrevious,
  };

  dispatch({
    type: 'GET_SEQUENCE_STATS_ENGAGED_AUDIENCE',
    payload: {
      endpoint: 'api/v1/sequences/contacts/analytics/engaged-audience',
      method: 'POST',
      body: {
        filterBy: filterBy === 'all' ? undefined : request.filterBy,
        columns,
        sequenceId,
        ...removeEmpty({
          enrollmentDateFrom: enrollmentDate.from,
          enrollmentDateTo: enrollmentDate.to,
        }),
      },
      showResponseError: true,
    },
    onSuccess,
    components: ['sequenceEngagedDetailsLoading'],
    filterBy: request.filterBy,
  });
};

export const getSequenceStatsAudienceDetails = ({ filterBy } = {}) => (dispatch, getState) => {
  const state = getState();

  const sequenceId = get(state, 'sequences.selectedSequence._id');
  const enrollmentDate = get(state, 'sequences.selectedSequence.stats.enrollmentDate', {});
  const filterByPrevious = get(state, 'sequences.selectedSequence.stats.audienceDetails.filterBy', 'position');

  const request = {
    filterBy: filterBy || filterByPrevious,
  };

  dispatch({
    type: 'GET_SEQUENCE_STATS_AUDIENCE_DETAILS',
    payload: {
      endpoint: 'api/v1/sequences/contacts/analytics/audience-details',
      method: 'POST',
      body: {
        ...request,
        sequenceId,
        ...removeEmpty({
          enrollmentDateFrom: enrollmentDate.from,
          enrollmentDateTo: enrollmentDate.to,
        }),
      },
      showResponseError: true,
    },
    components: ['sequenceAudienceDetailsLoading'],
    filterBy: request.filterBy,
  });
};

export const getAnalyticsOfSentMails = () => (dispatch, getState) => {
  const state = getState();

  const sequenceId = get(state, 'sequences.selectedSequence._id');

  dispatch({
    type: 'GET_SEQUENCE_SENT_MAILS',
    payload: {
      endpoint: `api/v1/sequences/contacts/analytics/sent-mails?sequenceId=${sequenceId}`,
      method: 'POST',
      delayLoad: 0,
      showResponseError: true,
    },
    components: ['getSequenceSentMailsLoader'],
  });
};

export const getSequenceStepsStats = () => (dispatch, getState) => {
  const state = getState();

  const sequenceId = get(state, 'sequences.selectedSequence._id');
  const enrollmentDate = get(state, 'sequences.selectedSequence.stats.enrollmentDate', {});
  const enrollmentContactIds = enrollmentContactIdsSelector(state);

  dispatch({
    type: 'GET_SEQUENCE_STEPS_STATS',
    payload: {
      endpoint: 'api/v1/sequences/steps/analytics',
      method: 'POST',
      body: {
        sequenceId,
        ...removeEmpty({
          enrollmentDateFrom: enrollmentDate.from,
          enrollmentDateTo: enrollmentDate.to,
          enrollmentContactIds: enrollmentContactIds.length > 0 ? enrollmentContactIds : undefined,
        }),
      },
      delayLoad: 0,
      showResponseError: true,
    },
    components: ['sequenceStepStatsLoading'],
  });
};

export const createSequence = (sequence = {}, onSuccess = () => {}, onFailed = () => {}) => (dispatch) => {
  dispatch({
    type: 'CREATE_SEQUENCE',
    payload: {
      endpoint: 'api/v1/sequences',
      method: 'POST',
      body: sequence,
      showResponseError: true,
      delayLoad: 0,
    },
    onSuccess,
    onFailed,
    components: ['createSequenceLoader'],
  });
};

export const getCountSentMailsPerDay = (sequenceId) => (dispatch) => {
  dispatch({
    type: 'GET_COUNT_SENT_MAILS_PER_DAY',
    payload: {
      endpoint: `api/v1/sequences/${sequenceId}/count-sent-mails`,
      method: 'GET',
    },
  });
};

export const getSelectedSequence = ({ id, keepLeads = false }, { onSuccess = () => {}, onFailed = () => {} }) => (dispatch) => {
  const onSuccessProxy = (params) => {
    dispatch(getCountSentMailsPerDay(id));
    onSuccess(params);
  };

  dispatch({
    type: 'GET_SELECTED_SEQUENCE',
    payload: {
      endpoint: `api/v1/sequences/${id}`,
      method: 'GET',
      showResponseError: true,
    },
    onSuccess: onSuccessProxy,
    onFailed,
    keepLeads,
  });
};

export const clearSequence = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SEQUENCE',
    data: {},
  });
};

export const updateSequenceSteps = (steps) => (dispatch, getState) => {
  const state = getState();

  const sequence = get(state, 'sequences.selectedSequence');
  if (!sequence) {
    return;
  }

  const sequenceSteps = steps || sequence.steps;

  dispatch({
    type: 'UPDATE_SEQUENCE_STEPS',
    payload: {
      endpoint: `api/v1/sequences/${sequence._id}`,
      method: 'PUT',
      body: {
        steps: sequenceSteps.map((step) => (typeof step === 'object' ? step._id : step)),
      },
    },
  });
};

export const getStepTemplates = ({
  name,
  owner,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const {
    name: prevName,
    owner: prevOwner,
  } = get(state, 'sequences.stepTemplates.filters', {});
  const body = {};
  const newName = name || prevName;
  if (name || (typeof name === 'undefined' && prevName)) {
    body.name = newName;
  }
  const newOwner = owner || prevOwner;
  if (owner || (typeof owner === 'undefined' && prevOwner)) {
    body.owner = newOwner;
  }

  dispatch({
    type: 'GET_STEP_TEMPLATES',
    payload: {
      endpoint: 'api/v1/sequences/templates/search',
      method: 'POST',
      body,
    },
    filters: body,
  });
};

export const addStep = ({
  followUp,
  versions,
  activeVersion,
  indexPreviousStepId,
  onSuccess = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const sequenceId = get(state, 'sequences.selectedSequence._id');

  dispatch({
    type: 'ADD_STEP',
    payload: {
      endpoint: 'api/v1/sequences/steps',
      method: 'POST',
      body: {
        sequenceId,
        followUp,
        versions,
      },
      showResponseError: true,
    },
    activeVersion,
    indexPreviousStepId,
    onSuccess,
  });
};

export const copyStep = ({
  stepId,
  indexPreviousStepId,
}) => (dispatch, getState) => {
  const state = getState();
  const foundStep = get(state, 'sequences.selectedSequence.steps', []).find((step) => step._id === stepId);

  dispatch(addStep({
    ...foundStep,
    indexPreviousStepId,
  }));
};

export const createStepTemplate = ({
  name, subject, content, onSuccess,
}) => (dispatch) => {
  dispatch({
    type: 'CREATE_STEP_TEMPLATE',
    payload: {
      endpoint: 'api/v1/sequences/templates',
      method: 'POST',
      body: {
        name,
        subject,
        content,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    onSuccess: (data) => onSuccess?.(data),
    components: ['createStepTemplateLoader'],
  });
};

export const updateStepTemplate = ({
  templateId, name, subject, content, onSuccess,
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_STEP_TEMPLATE',
    payload: {
      endpoint: `api/v1/sequences/templates/${templateId}`,
      method: 'PATCH',
      body: {
        name,
        subject,
        content,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    onSuccess: (data) => onSuccess?.(data),
  });
};

export const deleteStepTemplate = ({
  templateId,
}) => (dispatch) => {
  dispatch({
    type: 'DELETE_STEP_TEMPLATE',
    payload: {
      endpoint: `api/v1/sequences/templates/${templateId}`,
      method: 'DELETE',
    },
    templateId,
  });
};

export const removeStep = (stepId) => (dispatch, getState) => {
  const state = getState();

  const sequenceId = get(state, 'sequences.selectedSequence._id');

  dispatch({
    type: 'REMOVE_STEP',
    payload: {
      endpoint: `api/v1/sequences/steps/${stepId}`,
      method: 'DELETE',
      params: { sequenceId },
      showResponseError: true,
    },
    stepId,
  });
};

export const changePositionStep = ({ oldIndex, newIndex }) => (dispatch) => {
  dispatch({
    type: 'CHANGE_POSITION_STEP',
    data: {
      oldIndex,
      newIndex,
    },
  });
};

export const renameSequence = ({ sequenceId, name, onSuccess = () => {} }) => (dispatch, getStore) => {
  const store = getStore();
  const sequenceName = get(store, 'sequences.selectedSequence.name');

  if (sequenceName !== name) {
    dispatch({
      type: 'RENAME_SEQUENCE',
      payload: {
        endpoint: `api/v1/sequences/${sequenceId}`,
        method: 'PUT',
        body: {
          name,
        },
      },
      sequenceId,
      name,
    });
  }
  onSuccess();
};

export const changeStatusSequence = ({
  sequenceId,
  status,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'CHANGE_STATUS_SEQUENCE',
    payload: {
      endpoint: `api/v1/sequences/${sequenceId}`,
      method: 'PUT',
      body: {
        status,
      },
      showResponseError: true,
    },
    sequenceId,
    status,
    onSuccess,
    onFailed,
  });
};

export const updateStepRemotely = ({
  stepId,
  version,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch, getState) => {
  const state = getState();

  const selectedSequence = selectedSequenceSelector(state);
  const step = selectedSequence.steps.find((sequence) => sequence._id === stepId);

  const leadReviewId = leadReviewIdSelector(state);
  const { wholeSequence } = customizeEmailSelector(state);

  let versions;
  if (leadReviewId && !wholeSequence) {
    versions = step.contacts.find((item) => item.contact === leadReviewId).versions;
  } else {
    versions = step.versions;
  }
  versions = cloneDeep(versions);
  Object.keys(versions).forEach((key) => {
    if (versions[key].content) {
      const contentWithoutSignature = removeSignature(versions[key].content);
      versions[key].content = contentWithoutSignature;
    }
  });

  dispatch({
    type: 'UPDATE_STEP_REMOTELY',
    payload: {
      endpoint: `api/v1/sequences/steps/${stepId}`,
      method: 'PUT',
      body: {
        versions,
        followUp: step.followUp,
        wholeSequence: Boolean(wholeSequence || selectedSequence.status !== SEQUENCE_STATUS_TYPE.DRAFT),
        ...removeEmpty({
          contactId: leadReviewId,
        }),
      },
      showResponseError: true,
      delayLoad: 0,
    },
    stepId,
    version,
    onSuccess,
    onFailed,
    components: ['updateStepRemotelyLoader'],
  });
};

export const updateStepErrors = debounce(({
  stepId,
  version,
  subject,
  content,
  dispatch,
  getState,
  updateRemotely,
  onSuccess,
}) => {
  Promise.all([
    engine.parseAndRender(subject),
    engine.parseAndRender(content),
  ])
    .then(() => {
      const newState = getState();
      const _currentStep = get(newState, 'sequences.selectedSequence.steps', []).find((item) => item._id === stepId);
      const errors = get(_currentStep, 'errors', {});
      delete errors[version];
      if (updateRemotely) {
        dispatch(updateStepRemotely({ stepId, version, onSuccess }));
      }
      dispatch({
        type: 'UPDATE_STEP',
        data: {
          step: {
            _id: stepId,
            errors: {
              ...errors,
            },
          },
        },
      });
    })
    .catch((error) => {
      const newState = getState();
      const _currentStep = get(newState, 'sequences.selectedSequence.steps', []).find((item) => item._id === stepId);
      const errors = get(_currentStep, 'errors', {});
      dispatch({
        type: 'UPDATE_STEP',
        data: {
          step: {
            _id: stepId,
            errors: {
              ...errors,
              [version]: error.message,
            },
          },
        },
      });
    });
}, 1000);

export const updateFollowUp = ({ followUp }) => (dispatch, getState) => {
  const state = getState();
  const step = sequenceActiveStepSelector(state);
  dispatch({
    type: 'UPDATE_STEP',
    data: {
      step: {
        _id: step._id,
        followUp,
      },
    },
  });
  dispatch(updateStepRemotely({ stepId: step._id }));
};

export const updateStep = ({
  step,
  version,
  onSuccess,
  checkErrors = true,
  updateRemotely = true,
}) => (dispatch, getState) => {
  let updatedStep = step;
  const state = getState();
  const currentStep = get(state, 'sequences.selectedSequence.steps', []).find((item) => item._id === step._id);
  const specificLeadStepContext = specificLeadStepContextSelector(state);
  const letter = specificLeadStepContext ? 'A' : (version || currentStep.activeVersion);
  const { subject, content } = step;
  const newSubject = subject;
  const newContent = content;
  if (checkErrors) {
    updateStepErrors({
      stepId: step._id,
      version: letter,
      subject: newSubject,
      content: newContent,
      dispatch,
      getState,
      updateRemotely,
    });
  }
  let updatedPart = {};
  const leadReviewId = leadReviewIdSelector(state);
  const { wholeSequence } = customizeEmailSelector(state);
  if (leadReviewId && !wholeSequence) {
    const foundContact = currentStep.contacts.find((item) => item.contact === leadReviewId);
    if (foundContact) {
      updatedPart = {
        contacts: currentStep.contacts.map((item) => {
          if (item.contact === leadReviewId) {
            return {
              ...item,
              versions: {
                ...item.versions,
                [letter]: {
                  ...(item.versions[letter] || {}),
                  subject: newSubject,
                  content: newContent,
                },
              },
            };
          }
          return item;
        }),
      };
    } else {
      updatedPart.contacts = [...currentStep.contacts];
      updatedPart.contacts.push({
        contact: leadReviewId,
        versions: {
          ...currentStep.versions,
          [letter]: {
            subject: newSubject,
            content: newContent,
          },
        },
      });
    }
  } else {
    updatedPart = {
      versions: {
        ...currentStep.versions,
        [letter]: {
          ...currentStep.versions[letter],
          subject: newSubject,
          content: newContent,
        },
      },
      contacts: leadReviewId ? currentStep.contacts.filter((item) => item.contact !== leadReviewId) : currentStep.contacts,
    };
  }
  updatedStep = {
    ...currentStep,
    ...updatedPart,
  };
  dispatch({
    type: 'UPDATE_STEP',
    data: {
      step: updatedStep,
    },
  });
  if (!checkErrors) {
    if (updateRemotely) {
      dispatch(updateStepRemotely({ stepId: step._id, version: letter, onSuccess }));
    } else {
      onSuccess();
    }
  }
};

export const getSequenceLeads = ({
  saveToStore = true,
  pageSize: size,
  pageNumber,
  search,
  components,
  onSuccess = () => {},
} = {}) => (dispatch, getState) => {
  const state = getState();

  const {
    pageSize, sort, filters = [],
  } = get(state, 'sequences.selectedSequence.leads', {});
  const sequenceId = get(state, 'sequences.selectedSequence._id');

  const searchValue = get(state, 'sequences.selectedSequence.leads.search.value');

  const params = {
    pageSize: size || pageSize,
    pageNumber: pageNumber || 1,
    sort: sort?.column,
    order: sort?.order,
  };

  const copiedFilters = cloneDeep(filters);

  const body = {
    sequenceId,
    filters: copiedFilters.map((filter) => {
      if (filter.included) {
        filter.included.value = filter.included.value.map((value) => value.value);
      }
      return filter;
    }),
  };

  const newSearch = search || searchValue;

  if (search || (typeof search === 'undefined' && searchValue)) {
    body.search = {
      properties: ['firstName', 'lastName'],
      value: newSearch,
    };
  }

  dispatch({
    type: `GET_SEQUENCE_LEADS${saveToStore ? '' : '_'}`,
    payload: {
      endpoint: 'api/v1/sequences/contacts/search',
      method: 'POST',
      body,
      params,
      showResponseError: true,
      delayLoad: 0,
    },
    components: components || ['sequenceLeadsLoading'],
    search: body.search,
    onSuccess,
  });
};

export const setSequenceLeadEvent = (selectedStatus) => (dispatch) => {
  dispatch({
    type: 'SET_SEQUENCE_LEAD_EVENT',
    data: {
      selectedStatus,
    },
  });
  dispatch(getSequenceLeads({ pageNumber: 1 }));
};

export const setSequenceLeadStatus = (selectedStatus) => (dispatch) => {
  dispatch({
    type: 'SET_SEQUENCE_LEAD_STATUS',
    data: {
      selectedStatus,
    },
  });
  dispatch(getSequenceLeads({ pageNumber: 1 }));
};

export const addSequenceLeadFilter = (name, property, operator, value) => (dispatch) => {
  const included = {
    operator,
    value: [{ _id: nanoid() }],
  };

  if (value) {
    included.value[0].value = value;
  }

  dispatch({
    type: 'ADD_SEQUENCE_LEAD_FILTER',
    data: {
      name,
      property,
      included,
    },
  });
  dispatch(getSequenceLeads({ pageNumber: 1 }));
};

export const removeSequenceLeadFilter = (id) => (dispatch) => {
  dispatch({
    type: 'REMOVE_SEQUENCE_LEAD_FILTER',
    data: {
      id,
    },
  });
  dispatch(getSequenceLeads({ pageNumber: 1 }));
};

export const addStepVersion = (id) => (dispatch) => {
  dispatch({
    type: 'ADD_STEP_VERSION',
    data: {
      id,
    },
  });
  dispatch(updateStepRemotely({ stepId: id }));
};

export const copyStepVersion = (id, version) => (dispatch) => {
  dispatch({
    type: 'COPY_STEP_VERSION',
    data: {
      id,
      version,
    },
  });
  dispatch(updateStepRemotely({ stepId: id }));
};

export const selectStepActiveVersion = (id, letter) => (dispatch) => {
  dispatch({
    type: 'SELECT_STEP_ACTIVE_VERSION',
    data: {
      id,
      letter,
    },
  });
};

export const deleteStepVersion = (stepId, letter) => (dispatch, getState) => {
  const state = getState();

  const steps = get(state, 'sequences.selectedSequence.steps', []);
  const neededStepIndex = steps.findIndex((step) => step._id === stepId);
  if (neededStepIndex === -1) {
    return;
  }
  delete steps[neededStepIndex].versions[letter];
  let baseLetter = 'A';
  const versionKeys = Object.keys(steps[neededStepIndex].versions);
  steps[neededStepIndex].versions = versionKeys.reduce((obj, version) => {
    obj[baseLetter] = steps[neededStepIndex].versions[version];
    baseLetter = String.fromCharCode(baseLetter.charCodeAt() + 1);
    return obj;
  }, {});
  steps[neededStepIndex].activeVersion = 'A';

  dispatch({
    type: 'DELETE_STEP_VERSION',
    payload: {
      endpoint: `api/v1/sequences/steps/${stepId}/versions/${letter}`,
      method: 'DELETE',
      showResponseError: true,
    },
    stepId,
    letter,
    steps,
    activeVersion: steps[neededStepIndex].activeVersion,
  });
};

export const disableStepVersion = (stepId, letter, disabled) => (dispatch, getState) => {
  const state = getState();

  const steps = get(state, 'sequences.selectedSequence.steps', []);
  const neededStepIndex = steps.findIndex((step) => step._id === stepId);
  if (neededStepIndex === -1) {
    return;
  }
  steps[neededStepIndex].versions[letter].disabled = disabled;
  if (steps[neededStepIndex].activeVersion === letter) {
    steps[neededStepIndex].activeVersion = letter === 'A' ? String.fromCharCode(letter.charCodeAt() + 1) : String.fromCharCode(letter.charCodeAt() - 1);
  }
  dispatch({
    type: 'DISABLE_STEP_VERSION',
    payload: {
      endpoint: `api/v1/sequences/steps/${stepId}`,
      method: 'PUT',
      body: {
        ...steps[neededStepIndex],
        wholeSequence: true,
      },
      showResponseError: true,
    },
    steps,
    activeVersion: steps[neededStepIndex].activeVersion,
  });
};

export const addAttachmentsToStep = ({
  stepId,
  attachments,
  version,
  resetAttachments = false,
  onSuccess = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const contact = specificLeadStepContextSelector(state);

  dispatch({
    type: 'ADD_ATTACHMENTS_TO_STEP',
    payload: {
      endpoint: `api/v1/sequences/steps/${stepId}/attachments`,
      method: 'POST',
      body: {
        attachments,
        version,
        resetAttachments,
        contactId: contact?.contact,
      },
      showResponseError: true,
    },
    resetAttachments,
    version,
    stepId,
    contactId: contact?.contact,
    onSuccess,
  });
};

export const addAttachmentsToTemplate = ({
  templateId,
  attachments,
  onSuccess = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'ADD_ATTACHMENTS_TO_TEMPLATE',
    payload: {
      endpoint: `api/v1/sequences/templates/${templateId}/attachments`,
      method: 'POST',
      body: {
        attachments,
      },
      showResponseError: true,
    },
    onSuccess,
  });
};

export const deleteAttachmentsFromStep = ({
  stepId,
  attachmentIds,
  version,
  onSuccess = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const contact = specificLeadStepContextSelector(state);
  const { _id: sequenceId } = get(state, 'sequences.selectedSequence');

  dispatch({
    type: 'DELETE_ATTACHMENTS_FROM_STEP',
    payload: {
      endpoint: `api/v1/sequences/steps/${stepId}/attachments?sequenceId=${sequenceId}`,
      body: {
        attachmentIds,
        version,
        contactId: contact?.contact,
      },
      method: 'DELETE',
      showResponseError: true,
      delayLoad: 0,
    },
    stepId,
    contactId: contact?.contact,
    attachmentIds,
    onSuccess,
    components: ['deleteFilesFromStepLoader'],
  });
};

export const deleteAttachmentsFromTemplate = ({
  attachmentIds,
  templateId,
  onSuccess = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'DELETE_ATTACHMENTS_FROM_TEMPLATE',
    payload: {
      endpoint: `api/v1/sequences/templates/${templateId}/attachments`,
      body: {
        attachmentIds,
      },
      method: 'DELETE',
      showResponseError: true,
      delayLoad: 0,
    },
    attachmentIds,
    onSuccess,
    components: ['deleteFilesFromStepLoader'],
  });
};

export const updateSequenceAccount = ({
  sequenceId,
  account,
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SEQUENCE_ACCOUNT',
    data: {
      sequenceId,
      account,
    },
  });
};

export const updateSequenceSettingsRemotely = ({
  data,
  sequenceId,
  onSuccess = () => {},
  onFailed = () => {},
}) => (dispatch, getState) => {
  const state = getState();
  const { _id: selectedSequenceId } = get(state, 'sequences.selectedSequence');
  dispatch({
    type: 'UPDATE_SEQUENCE_SETTINGS_REMOTELY',
    payload: {
      endpoint: 'api/v1/sequences/settings',
      method: 'PUT',
      body: {
        sequenceId: sequenceId || selectedSequenceId,
        ...data,
      },
      delayLoad: 0,
      showResponseError: !onFailed,
    },
    sequenceId: sequenceId || selectedSequenceId,
    components: ['updateSequenceSettingsRemotelyLoader'],
    onSuccess,
    onFailed,
    updatedData: data,
  });
};

export const updateSequenceSettings = (data = {}, onSuccess = () => {}, onFailed) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SEQUENCE_SETTINGS',
    data,
  });
  dispatch(updateSequenceSettingsRemotely({
    data,
    onSuccess,
    onFailed,
  }));
};

export const getSequenceSchedules = () => (dispatch, getState) => {
  const state = getState();
  const { _id: sequenceId } = get(state, 'sequences.selectedSequence');
  dispatch({
    type: 'GET_SEQUENCE_SCHEDULES',
    payload: {
      endpoint: 'api/v1/sequences/schedules',
      method: 'GET',
      params: {
        sequenceId,
      },
      delayLoad: 0,
    },
    components: ['schedulesLoading'],
  });
};

export const addSequenceSchedule = (name, scheduleId) => (dispatch, getState) => {
  const state = getState();
  const activeSchedule = get(state, 'sequences.schedules.data', []).find((schedule) => schedule._id === scheduleId);
  if (activeSchedule) {
    dispatch({
      type: 'ADD_SEQUENCE_SCHEDULE',
      payload: {
        endpoint: 'api/v1/sequences/schedules',
        method: 'POST',
        body: omitDeep({ ...activeSchedule, name }, ['_id', 'sequence']),
        showResponseError: true,
      },
      onSuccess: () => {
        dispatch(getSequenceSchedules());
      },
    });
  }
};

export const updateSequenceSchedule = (data = {}, updateRemotely = false) => (dispatch, getState) => {
  if (updateRemotely) {
    const state = getState();
    const activeSchedule = get(state, 'sequences.schedules.data', []).find((schedule) => schedule._id === data._id);
    if (activeSchedule) {
      dispatch({
        type: 'UPDATE_SEQUENCE_SCHEDULE',
        payload: {
          endpoint: `api/v1/sequences/schedules/${activeSchedule._id}`,
          method: 'PUT',
          body: omitDeep(activeSchedule, ['_id', 'sequence']),
          showResponseError: true,
        },
        updateRemotely,
      });
    }
  } else {
    dispatch({
      type: 'UPDATE_SEQUENCE_SCHEDULE',
      data,
      updateRemotely,
    });
  }
};

export const removeSequenceSchedule = (scheduleId) => (dispatch) => {
  dispatch({
    type: 'REMOVE_SEQUENCE_SCHEDULE',
    payload: {
      endpoint: `api/v1/sequences/schedules/${scheduleId}`,
      method: 'DELETE',
      showResponseError: true,
    },
    scheduleId,
  });
};

export const updateSequenceScheduleDayStatus = (scheduleId, scheduleDay, status) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SEQUENCE_SCHEDULE_DAY_STATUS',
    data: {
      _id: scheduleId,
      scheduleDay,
      status,
    },
  });
};

export const copySequenceScheduleTime = (scheduleId, scheduleDay, days) => (dispatch) => {
  dispatch({
    type: 'COPY_SEQUENCE_SCHEDULE_TIME',
    data: {
      _id: scheduleId,
      scheduleDay,
      days,
    },
  });
};

export const addSequenceScheduleTime = (scheduleId, scheduleDay, time) => (dispatch) => {
  dispatch({
    type: 'ADD_SEQUENCE_SCHEDULE_TIME',
    data: {
      _id: scheduleId,
      scheduleDay,
      time: {
        ...time,
        _id: nanoid(),
      },
    },
  });
};

export const updateSequenceScheduleTime = ({
  scheduleId,
  scheduleDay,
  time,
  saved = false,
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SEQUENCE_SCHEDULE_TIME',
    data: {
      _id: scheduleId,
      scheduleDay,
      time,
      saved,
    },
  });
};

export const removeSequenceScheduleTime = (scheduleId, scheduleDay, timeId) => (dispatch) => {
  dispatch({
    type: 'REMOVE_SEQUENCE_SCHEDULE_TIME',
    data: {
      _id: scheduleId,
      scheduleDay,
      timeId,
    },
  });
};

export const selectSequenceActiveSchedule = (schedule) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SEQUENCE_SETTINGS',
    data: {
      schedule,
    },
  });
};

export const selectSequenceActiveStep = (stepId) => (dispatch) => {
  dispatch({
    type: 'SELECT_SEQUENCE_ACTIVE_STEP',
    data: {
      stepId,
    },
  });
};

export const setSelectedLeads = (selectedLeads = []) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_LEADS',
    data: selectedLeads,
  });
};

export const resetSequenceSchedule = () => (dispatch) => {
  dispatch({
    type: 'RESET_SEQUENCE_SCHEDULE',
    data: {},
  });
};

export const addOptOutContacts = (emails, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_OPT_OUT_CONTACTS',
    payload: {
      endpoint: 'api/v1/sequences/opt-out/contacts',
      method: 'POST',
      body: { emails },
    },
    onFailed,
  });
};

export const deleteOptOutContacts = (emails) => (dispatch) => {
  dispatch({
    type: 'DELETE_OPT_OUT_CONTACTS',
    payload: {
      endpoint: 'api/v1/sequences/opt-out/contacts',
      method: 'DELETE',
      body: { emails },
    },
    emails,
  });
};

export const getOptOutContacts = ({
  email,
  pageNumber,
  pageSize: size,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const {
    pageSize, page,
  } = get(state, 'sequences.optOutContacts', {});

  const { value: searchValue } = get(state, 'sequences.sequences.search', {});

  const params = {
    pageSize: size || pageSize,
    pageNumber: pageNumber || page,
  };

  const body = {};
  const newEmail = email || searchValue;

  if (email || (typeof email === 'undefined' && searchValue)) {
    body.search = {
      properties: ['email'],
      value: newEmail,
    };
  }

  dispatch({
    type: 'GET_OPT_OUT_CONTACTS',
    payload: {
      endpoint: 'api/v1/sequences/opt-out/contacts/search',
      method: 'POST',
      params,
      body,
    },
    components: ['optOutContactsLoading'],
  });
};

export const checkOpportunityToUnsubscribe = ({
  workspaceId,
  contactId,
  sequenceId,
}, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'CHECK_OPPORTUNITY_TO_UNSUBSCRIBE',
    payload: {
      endpoint: 'api/v1/sequences/opt-out/contacts',
      method: 'HEAD',
      params: {
        workspaceId,
        contactId,
        sequenceId,
      },
      skipAuth: true,
    },
    onSuccess,
    onFailed,
  });
};

export const unsubscribeSequence = ({
  workspaceId,
  contactId,
  sequenceId,
}, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'UNSUBSCRIBE_SEQUENCE',
    payload: {
      endpoint: 'api/v1/sequences/opt-out/contacts/unsubscribe',
      method: 'PATCH',
      params: {
        workspaceId,
        contactId,
        sequenceId,
      },
      delayLoad: 0,
      skipAuth: true,
    },
    components: ['unsubscribeSequenceLoading'],
    onSuccess,
    onFailed,
  });
};

export const sendTestMessage = ({
  subject,
  content,
  sendTo,
}, onSuccess) => (dispatch, getState) => {
  const state = getState();
  const { _id: sequenceId } = get(state, 'sequences.selectedSequence');

  dispatch({
    type: 'SEND_TEST_MESSAGE',
    payload: {
      endpoint: 'api/v1/sequences/steps/test-message',
      method: 'POST',
      body: {
        subject,
        content,
        sequenceId,
        sendTo,
      },
      showResponseError: true,
      delayLoad: 0,
    },
    components: ['sendTestMessageLoading'],
    onSuccess,
  });
};

export const sendTestCampaign = ({
  sendTo,
  contactId,
}, onSuccess = () => {}) => (dispatch, getState) => {
  const state = getState();
  const { _id: sequenceId } = get(state, 'sequences.selectedSequence');

  dispatch({
    type: 'SEND_TEST_CAMPAIGN',
    payload: {
      endpoint: `api/v1/sequences/${sequenceId}/test-campaign`,
      method: 'POST',
      body: {
        contactId,
        sendTo,
      },
      showResponseError: true,
      delayLoad: 0,
    },
    components: ['sendTestCampaignLoader'],
    onSuccess,
  });
};

export const closeConnectMailbox = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_CONNECT_MAILBOX',
    data: {},
  });
};

export const getTemplateTeammates = () => (dispatch) => {
  dispatch({
    type: 'GET_TEMPLATE_TEAMMATES',
    payload: {
      endpoint: 'api/v1/sequences/templates/teammates',
      method: 'GET',
    },
  });
};

export const resetSequence = (data = {}) => (dispatch) => {
  dispatch({
    type: 'RESET_SEQUENCE',
    data,
  });
};

export const setSavedSequence = (saved) => (dispatch) => {
  dispatch({
    type: 'SET_SAVED_SEQUENCE',
    data: {
      saved,
    },
  });
};

export const updateSequenceDraftStatus = (draftStatus, onSuccess = () => {}) => (dispatch, getState) => {
  const state = getState();
  const { _id: sequenceId } = get(state, 'sequences.selectedSequence');
  dispatch({
    type: 'UPDATE_SEQUENCE_DRAFT_STATUS',
    payload: {
      endpoint: `api/v1/sequences/${sequenceId}`,
      method: 'PATCH',
      body: {
        draftStatus,
      },
      showResponseError: true,
      delayLoad: 0,
    },
    onSuccess,
    draftStatus,
    components: ['updateSequenceDraftStatusLoader'],
  });
};

export const setCustomizeEmail = (data) => (dispatch, getState) => {
  dispatch({
    type: 'SET_CUSTOMIZE_EMAIL',
    data,
  });
  const state = getState();
  const currentStep = sequenceActiveStepSelector(state);
  const stepContext = currentStepContextSelector(state);
  dispatch(updateStep({
    step: {
      _id: currentStep._id,
      subject: stepContext.subject,
      content: stepContext.content,
    },
    updateRemotely: false,
  }));
};

export const setLeadReview = ({ lead, position }) => (dispatch) => {
  dispatch({
    type: 'SET_LEAD_REVIEW',
    data: {
      lead,
      position,
    },
  });
};

export const insertTokenIntoEditor = (token) => (dispatch) => {
  dispatch({
    type: 'INSERT_TOKEN_INTO_EDITOR',
    data: { token },
  });
};

export const insertUnsubscribeLinkIntoEditor = () => (dispatch) => {
  dispatch({
    type: 'INSERT_UNSUBSCRIBE_LINK_INTO_EDITOR',
    data: {},
  });
};

export const toggleSelectAllSequenceLeads = (value) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_SELECT_ALL_SEQUENCE_LEADS',
    data: {
      value,
    },
  });
};

export const setAdditingLeads = (status) => (dispatch) => {
  dispatch({
    type: 'SET_ADDITING_LEADS',
    data: {
      status,
    },
  });
};

export const searchContactSequences = (searchValue) => (dispatch, getState) => {
  const state = getState();

  const contact = contactSelector(state);
  const countContactSequences = contact?.sequences?.length || 0;

  dispatch({
    type: 'SEARCH_CONTACT_SEQUENCES',
    payload: {
      endpoint: 'api/v1/sequences/search',
      method: 'POST',
      body: {
        search: {
          value: searchValue,
          properties: ['name'],
        },
      },
      params: {
        pageNumber: 1,
        pageSize: countContactSequences + 4,
      },
    },
    sequences: contact?.sequences || [],
    components: ['searchContactSequencesLoading'],
  });
};

export const updateSequenceContactStatus = ({
  sequenceId,
  contactId,
  status,
}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SEQUENCE_CONTACT_STATUS',
    payload: {
      endpoint: `api/v1/sequences/contacts/${contactId}`,
      method: 'PATCH',
      body: {
        sequenceId,
        status,
      },
    },
    contactId,
    sequenceId,
    status,
  });
};

export const getSequenceFolders = () => (dispatch) => {
  dispatch({
    type: 'GET_SEQUENCE_FOLDERS',
    payload: {
      endpoint: 'api/v1/contacts/folders/tree/sequence',
      method: 'GET',
      delayLoad: 0,
    },
    components: ['foldersTable'],
  });
};

export const getFolderSequencesRelation = () => (dispatch) => {
  dispatch({
    type: 'GET_FOLDER_SEQUENCES_RELATION',
    payload: {
      endpoint: 'api/v1/contacts/folders/sequences',
      method: 'GET',
    },
    onSuccess: (relations) => {
      const sequenceIds = relations.map(({ sequence }) => sequence?._id).filter(Boolean);
      if (sequenceIds) {
        dispatch(getSequencesAnalytics(sequenceIds, 'GET_SEQUENCE_RELATION_ANALYTICS'));
      }
    },
  });
};
