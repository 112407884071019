import get from 'lodash/get';

export const plansForBilling = (plans, subscription) => {
  const finalPlans = [];
  const selectedPlanIndex = plans.findIndex((item) => get(item, '_id') === get(subscription, 'plan._id'));
  const nextPlanIndex = selectedPlanIndex + 1;
  const currentPlan = get(subscription, 'plan');

  if (selectedPlanIndex === -1 && currentPlan) {
    finalPlans.push(currentPlan);

    if (plans[nextPlanIndex]) {
      finalPlans.push(plans[nextPlanIndex]);
    } else if (currentPlan.discount && ['Pro', 'Growth 50K'].includes(currentPlan.name)) {
      const nextPlan = { ...currentPlan };
      nextPlan.name = 'Pro';
      nextPlan.planId = currentPlan.originalPlanWithoutDiscount.planId;
      nextPlan.price = Math.ceil((currentPlan.price * 100) / (100 - currentPlan.discount));
      nextPlan.customQuantity = (currentPlan.customQuantity || 1) + 1;
      finalPlans.push(nextPlan);
    }
    return finalPlans;
  }
  const selectedPlan = plans[selectedPlanIndex];
  const proPlan = plans.find((plan) => ['Pro', 'Growth 50K'].includes(plan.name));

  if (selectedPlan?.isCustom && selectedPlan?.price > proPlan?.price) {
    if (proPlan) {
      selectedPlan.viewPlan = proPlan;
      selectedPlan.customQuantity = selectedPlan?.maxDiscoveredEmails / proPlan?.maxDiscoveredEmails;
      finalPlans.push(selectedPlan);

      if (selectedPlan.customQuantity < 10) {
        const nextPlan = { ...proPlan };
        nextPlan.customQuantity = selectedPlan.customQuantity + 1;
        finalPlans.push(nextPlan);
      }
    }
  } else if (selectedPlan) {
    finalPlans.push(selectedPlan);

    if (selectedPlan === proPlan && get(subscription, 'subscriptionQuantity', 1) < 10) {
      const nextPlan = { ...proPlan };
      nextPlan.customQuantity = get(subscription, 'subscriptionQuantity', 1) + 1;
      finalPlans.push(nextPlan);
    } else if (plans?.[nextPlanIndex] && (!plans?.[nextPlanIndex]?.isCustom || plans?.[nextPlanIndex]?.isNewPricing)) {
      finalPlans.push(plans[nextPlanIndex]);
    } else if (currentPlan.discount && currentPlan.name.startsWith('Pro')) {
      const nextPlan = { ...currentPlan };
      nextPlan.name = 'Pro';
      nextPlan.planId = currentPlan.originalPlanWithoutDiscount.planId;
      nextPlan.price = Math.ceil((currentPlan.price * 100) / (100 - currentPlan.discount));
      nextPlan.customQuantity = (currentPlan.customQuantity || 1) + 1;
      finalPlans.push(nextPlan);
    }
  }

  return finalPlans;
};

export const plansForPricing = (plans, subscription) => {
  const basicPlans = plans;
  const selectedPlan = plans.find((item) => get(item, '_id') === get(subscription, 'plan._id') && get(item, 'isCustom') && !get(item, 'isNewPricing') && !get(subscription, 'plan.originalPlanWithoutDiscount'));

  if (!selectedPlan) return basicPlans;
  basicPlans.forEach((plan) => {
    const proPlan = plan.name === 'Pro';

    if (proPlan) {
      plan.viewPlan = selectedPlan;
    }
  });
  return basicPlans;
};

export const planNameTransform = (planName) => {
  if (planName === 'Bulk plan') return 'Verifications';
  if (planName === 'Custom plan') return 'Pro plan';
  return planName;
};
