import { Tooltip } from 'antd';
import isObject from 'lodash/isObject';
import React, { useEffect, useState } from 'react';

import { EllipsisSpan } from 'components/commonStyles';

const EllipsisText = ({
  title,
  style = {},
  placement = 'top',
  children,
  checkParent,
  className,
  onClick = () => {},
  dangerouslySetInnerHTML,
}) => {
  const [textRef, setTextRef] = useState();
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [isAllowedToBeVisible, setIsAllowedToBeVisible] = useState(false);

  let fixedChildren = children;

  if (isObject(children) && 'value' in children) {
    fixedChildren = children.value;
  }

  useEffect(() => {
    if (textRef && (textRef.offsetWidth < textRef.scrollWidth || (checkParent && textRef.parentElement.offsetWidth < textRef.parentElement.scrollWidth))) {
      setIsAllowedToBeVisible(true);
    }
  }, [textRef, checkParent]);

  return (
    <Tooltip placement={placement} title={title} visible={visibleTooltip} onVisibleChange={(visible) => isAllowedToBeVisible && setVisibleTooltip(visible)}>
      {/* eslint-disable-next-line */}
      <EllipsisSpan onClick={onClick} dangerouslySetInnerHTML={dangerouslySetInnerHTML} style={style} className={className} ref={setTextRef}>{fixedChildren}</EllipsisSpan>
    </Tooltip>
  );
};

export default EllipsisText;
