import React from 'react';
import { useSelector } from 'react-redux';

import { cancelSubscriptionSelector, isDiscountAvailableSelector } from 'data/selectors/subscriptions';
import Discount from '../../components/discount';
import Confirmation from '../../components/confirmation';

const CommonCase = ({
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const details = useSelector(cancelSubscriptionSelector);
  const isDiscountAvailable = useSelector(isDiscountAvailableSelector);

  let steps = {
    1: {
      component: Discount,
      params: {
        onClose,
        onNext: setNextStep,
      },
    },
    2: !details.cheapPlanId ? {
      component: Confirmation,
      params: {
        onClose,
        onNext: setNextStep,
      },
    } : undefined,
  };
  if (!isDiscountAvailable) {
    steps = {
      1: {
        component: Confirmation,
        params: {
          onClose,
          onNext: setNextStep,
        },
      },
    };
  }

  const step = steps[stepNumber];

  if (!step) {
    const needToAct = !details.cheapPlanId;
    onFinish({ needToAct });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default CommonCase;
