import React from 'react';
import { useSelector } from 'react-redux';

import Confirmation from 'components/stepModals/common/components/confirmation';
import { BasePauseSubscriptionModal } from 'components/modals/pauseSubscriptionModal';
import { cancelSubscriptionSelector, isDisabledPauseSelector } from 'data/selectors/subscriptions';
import PauseQuestion from '../../common/components/pauseQuestion';

const FinishedWork = ({
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const details = useSelector(cancelSubscriptionSelector);
  const isDisabledPause = useSelector(isDisabledPauseSelector);

  let steps = {
    1: {
      component: PauseQuestion,
      params: {
        onClose,
        onNext: ({ wantsToPause }) => {
          setNextStep({ wantsToPause });
        },
      },
    },
    2: details.wantsToPause ? {
      component: BasePauseSubscriptionModal,
      params: {
        onClose,
        onSuccess: ({ period }) => {
          setNextStep({ pausingPeriod: period });
        },
      },
    } : {
      component: Confirmation,
      params: {
        onClose,
        onNext: setNextStep,
      },
    },
  };
  if (isDisabledPause) {
    steps = {
      1: {
        component: Confirmation,
        params: {
          onClose,
          onNext: setNextStep,
        },
      },
    };
  }

  const step = steps[stepNumber];

  if (!step) {
    onFinish({ needToAct: true });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default FinishedWork;
