import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.BLACK_G1};
`;

export const Container = styled.div`
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  border: 1px solid ${COLORS.GREY_G14};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 260px;

  ${(props) => ((props.active) && `
    background-color: ${COLORS.PRIMARY_P4};
    border: 1px solid ${COLORS.PRIMARY};

    ${Label}, .material-icons {
      color: ${COLORS.PRIMARY} !important;
    }
  `)}
`;

export const LabelContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
