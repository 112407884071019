export const DESKTOP_HORIZONTAL_PADDING = 35;
export const TABLET_HORIZONTAL_PADDING = 20;

export const TOP_BAR_HEIGHT = 60;
export const BIG_TOP_BAR_HEIGHT = 94;

export const HEADER_HEIGHT = 52;
export const SIDEBAR_WIDTH = 210;
export const DRAWER_WIDTH = 420;
export const TAB_HEIGHT = 51;
export const TAB_BAR_HEIGHT = 50;
export const DRAWER_PADDING = 24;
export const TABLE_BORDER_RADIUS = 4;
export const FIELD_HEIGHT = 36;
export const HEADER_TABLE_HEIGHT = 42;
export const ROW_TABLE_HEIGHT = 48;
export const TABLE_SELECTION_WIDTH = 50;
export const DEFAULT_COLUMN_WIDTH = 240;
export const MAX_VISIBLE_TEXT = 266;
export const CUSTOM_CELL_DEFAULT_WIDTH = 240;
export const DEFAULT_CELL_WIDTH = 250;
export const WELCOME_QUESTIONS_WIDTH = 900;
export const PAGINATION_HEIGHT = 62;
export const TABS_HEADER_HEIGHT = 52;
export const TABS_HEIGHT = 65;
export const CREDIT_BAR_HEIGHT = 35;
export const CONNECT_MAILBOX_HEIGHT = 92;

export const CONTENT_MARGIN_TOP = TOP_BAR_HEIGHT + 15;
export const BIG_HEADER_CONTENT_MARGIN_TOP = BIG_TOP_BAR_HEIGHT + 11;

export const BIG_HEADER_CONTENT_MARGIN_TOP_MOBILE = BIG_HEADER_CONTENT_MARGIN_TOP + 45;

export const TABLET_MAX_WIDTH = 1024;
export const TABLET_PORTRAIT_MAX_WIDTH = 768;
export const SIDEBAR_LEFT = 15;
export const MACBOOK_AIR_MAX_WIDTH = 1440;
export const HD_MAX_WIDTH = 1280;
export const CONTENT_MARGIN_LEFT = SIDEBAR_LEFT * 2 + SIDEBAR_WIDTH;
export const SIDEBAR_FOOTER_HEIGHT = 78;

export const MOBILE_MAX_WIDTH = 640;
export const LAYOUT_WITHOUT_SIDEBAR_WIDTH = 1250;
export const COMPANY_LEFT_WIDTH = 300;
export const SIDEBAR_SEARCH_WIDTH = 280;
export const CONTENT_SEARCH_MARGIN_LEFT = SIDEBAR_LEFT * 2 + SIDEBAR_SEARCH_WIDTH;

const SMALL_BUTTON_FONT_SIZE = 12;
const DEFAULT_BUTTON_FONT_SIZE = 13;
const LARGE_BUTTON_FONT_SIZE = 14;

export const Z_INDEXES = Object.freeze({
  HEADER: 1100,
  MOBILE_MODAL: 1200,
  POPOVER: 1300,
});

export const COLORS = Object.freeze({
  BLACK: '#000000',
  BLACK_G1: '#071427',
  BLACK_WITH_OPACITY: 'rgba(0, 0, 0, 0.65)',
  BLACK_WITH_OPACITY_B1: 'rgba(0, 0, 0, 0.85)',
  BLACK_WITH_OPACITY_B2: 'rgba(0, 0, 0, 0.3)',
  BLACK_WITH_OPACITY_B3: 'rgba(0, 0, 0, 0.25)',
  WHITE: '#FFFFFF',
  WHITE_W1_OPACITY1: 'rgba(255,255,255, .7)',
  PRIMARY: '#1890FF',
  PRIMARY_P1: 'rgba(24, 144, 255, 0.1)',
  PRIMARY_P2: 'rgba(24, 144, 255, 0.2)',
  PRIMARY_P3: '#3585CE',
  PRIMARY_P4: '#E8F4FF',
  PRIMARY_P5: '#D5E2F5',
  PRIMARY_P6: '#4e73f7',
  BLUE: '#2565C7',
  BLUE_B1: '#40A9FF',
  BLUE_B2: 'rgba(24, 144, 255, 0.05)',
  BLUE_B3: '#2867B2',
  BLUE_B4: '#EAF0F6',
  BLUE_B5: '#E3F3FE',
  BLUE_B6: '#0067CE',
  BLUE_B7: '#E6F7FF',
  BLUE_B8: '#1C908D',
  DARK_BLUE: '#123B77',
  DARKEST_BLUE: '#102A51',
  RED: '#E44D5F',
  RED_R1: 'rgb(228, 77, 95)',
  RED_R2: '#F34336',
  RED_D3: '#F73B57',
  RED_R4: '#901111',
  RED_R5: '#d55592',
  RED_WITH_OPACITY: 'rgba(228, 77, 95, 0.2)',
  GREY_G1: '#4F5964',
  GREY_G2: '#979FA8',
  GREY_G3: '#BEC4CA',
  GREY_G4: '#DFE2E5',
  GREY_G5: '#F7F8FA',
  GREY_G6: '#BED1EE',
  GREY_G6_OPACITY: 'rgba(190, 209, 238, 0.25)',
  GREY_G7: '#E8E8E8',
  GREY_G8: '#D9D9D9',
  GREY_G9: '#d8d8d8',
  GREY_DISABLED: '#F5F5F5',
  GREY_G10: '#FAFAFA',
  GREY_G11: 'rgba(190, 209, 238, 0.2)',
  GREY_G12: 'rgba(190, 209, 238, 0.4)',
  GREY_G13: '#969696',
  GREY_G14: '#EFF0F2',
  GREY_G15: '#E6E7E8',
  GREY_BORDER: '#DDD',
  GREEN: '#52c41a',
  GREEN_G2: '#f9ffef',
  GREEN_G3: '#1C9086',
  GREEN_G4: '#38B9AE',
  GREEN_G5: '#3BB33F',
  GREEN_G6: '#B3EED9',
  GREEN_WITH_OPACITY: 'rgba(56, 185, 174, 0.2)',
  SUCCESS: '#52C41A',
  SUCCESS_S1: '#04BBAD',
  SUCCESS_S2: '#EAFFD5',
  GREY_WITH_OPACITY: 'rgba(223, 226, 229, 0.5)',
  GREY_WITH_OPACITY_G1: 'rgba(0, 0, 0, 0.43)',
  GREY_WITH_OPACITY_G2: 'rgba(0, 0, 0, 0.02)',
  FOLLOW_STAR: '#F1C40F',
  FOLLOW_STAR_HOVER: '#D4AC0D',
  YELLOW: '#FFBE25',
  YELLOW_Y1: '#F7B500',
  YELLOW_Y2: '#FFE7A5',
  YELLOW_Y3: '#CD7901',
  YELLOW_Y4: '#F5A623',
  YELLOW_Y5: '#908A11',
  YELLOW_WITH_OPACITY: 'rgba(247, 181, 0, 0.2)',
  ORANGE: '#FA6400',
  ORANGE_O1: '#FD6A00',
  PURPLE: '#5855D6',
  PURPLE_P1: '#5819C4',
  PURPLE_WITH_OPACITY: 'rgba(88, 85, 214, 0.2)',
  PINK: '#D655B9',
  PINK_WITH_OPACITY: 'rgba(214, 85, 185, 0.2)',
});

export const ComponentsConfig = Object.freeze({
  icon: {
    smallest: {
      fontSize: 18,
    },
    small: {
      fontSize: 20,
    },
    default: {
      fontSize: 24,
    },
    large: {
      fontSize: 40,
    },
    largest: {
      fontSize: 60,
    },
  },
  button: {
    common: {
      fontWeight: 500,
      borderRadius: 4,
    },
    smallest: {
      height: 24,
      minWidth: 70,
      fontSize: SMALL_BUTTON_FONT_SIZE,
    },
    small: {
      height: 28,
      minWidth: 70,
      fontSize: SMALL_BUTTON_FONT_SIZE,
    },
    medium: {
      height: 30,
      minWidth: 90,
      fontSize: SMALL_BUTTON_FONT_SIZE,
    },
    default: {
      height: 32,
      minWidth: 90,
      fontSize: DEFAULT_BUTTON_FONT_SIZE,
    },
    large: {
      height: 40,
      minWidth: 100,
      fontSize: LARGE_BUTTON_FONT_SIZE,
    },
  },
  input: {
    common: {
      borderRadius: 4,
    },
    small: {
      height: 28,
      minWidth: 80,
      fontSize: DEFAULT_BUTTON_FONT_SIZE + 2,
    },
    default: {
      height: 32,
      minWidth: 150,
      fontSize: DEFAULT_BUTTON_FONT_SIZE + 2,
      top: 35,
    },
    large: {
      height: 40,
      minWidth: 100,
      fontSize: LARGE_BUTTON_FONT_SIZE + 2,
    },
  },
  select: {
    common: {
      borderRadius: 4,
    },
    small: {
      height: 28,
      minWidth: 80,
      fontSize: 12,
    },
    default: {
      height: 32,
      minWidth: 150,
      fontSize: 12,
      top: 35,
    },
    large: {
      height: 40,
      minWidth: 100,
      fontSize: 14,
    },
  },
  checkbox: {
    small: {
      fontSize: 11,
    },
    common: {
      fontWeight: 500,
      fontSize: 14,
    },
    default: {
      height: 16,
      width: 16,
    },
    large: {
      height: 20,
      width: 20,
    },
  },
  radio: {
    common: {
      fontWeight: 500,
    },
    default: {
      height: 16,
      width: 16,
    },
    large: {
      height: 20,
      width: 20,
    },
  },
  tag: {
    common: {
      borderRadius: 4,
    },
    default: {
      height: 20,
    },
    large: {
      height: 24,
    },
  },
  badge: {
    small: {
      fontSize: 11,
      height: 17,
      padding: '2px 4px',
    },
    common: {
      fontSize: 14,
      height: 23,
      padding: '4px 6px',
    },
  },
});

export const CONTACT_ICON_COLORS = Object.freeze([
  { backgroundColor: '#bed1ee', color: '#2565c7' },
  { backgroundColor: '#eec4be', color: '#c86e61' },
  { backgroundColor: '#0069ff', color: '#ffffff' },
  { backgroundColor: '#d5eebe', color: '#699243' },
  { backgroundColor: '#d8beee', color: '#7a549b' },
  { backgroundColor: '#eedebe', color: '#977c46' },
  { backgroundColor: '#bec1ee', color: '#6065ba' },
  { backgroundColor: '#beeec6', color: '#3c944b' },
  { backgroundColor: '#ebeebe', color: '#8d9338' },
  { backgroundColor: '#eebecc', color: '#b43f61' },
  { backgroundColor: '#c1cacf', color: '#55656e' },
]);

export const SHADOWS = Object.freeze({
  BLUE_B1: '0 0 0 2px rgba(24, 144, 255, 0.2)',
  RED_R1: '0px 0px 0px 2px rgba(228, 77, 95, 0.2)',
  BLACK_B1: '0 4px 6px 0 rgba(0, 0, 0, 0.1)',
  BLACK_B2: '0 0 5px 1px rgba(0, 0, 0, 0.1)',
  BLACK_B3: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
});

export const SIDEBAR_PREMADE_SEQUENCES_WIDTH = 470;
export const PAYMENT_DEFAULT_MESSAGE_OPTION = Object.freeze({
  duration: 15,
});
