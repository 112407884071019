import get from 'lodash/get';
import { createSelector } from 'reselect';

import { plansForBilling } from 'helpers/planHelpers';
import { subscriptionsSelector } from './subscriptions';

const basePlansSelector = (state) => get(state, 'plans.plans', []);
export const plansSelector = createSelector(
  basePlansSelector,
  (plans) => plans,
);

export const plansForBillingSelector = createSelector(
  subscriptionsSelector,
  plansSelector,
  (subscription, plans) => plansForBilling(plans, subscription),
);

export const currentPlanForBillingSelector = createSelector(
  subscriptionsSelector,
  plansForBillingSelector,
  (subscription, plans) => plans.find((item) => get(item, '_id') === get(subscription, 'plan._id')),
);

const baseCheapestPlansSelector = (state) => get(state, 'plans.cheapestPlans', []);
export const cheapestPlansSelector = createSelector(
  baseCheapestPlansSelector,
  (plans) => plans,
);
