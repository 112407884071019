import React from 'react';

import Confirmation from '../../components/confirmation';

const Other = ({
  onFinish,
  setNextStep,
  stepNumber,
  onClose,
}) => {
  const steps = {
    1: {
      component: Confirmation,
      params: {
        onClose,
        onNext: setNextStep,
      },
    },
  };

  const step = steps[stepNumber];

  if (!step) {
    onFinish({ needToAct: true });
    return null;
  }

  const { component: Component, params = {} } = step;

  // eslint-disable-next-line
  return <Component {...params} />;
};

export default Other;
