import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Collapse } from 'antd';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { isEqual } from 'lodash';
import { TOUR } from 'data/types/tours.types';

import { Icon } from 'components/common';
import { Badge } from 'components/commonStyles';
import { removeOptionFromFilters, removePropertyFromFilter } from 'data/actions/search';
import { getFilterOptionsSelector, getFiltersSelector } from 'data/selectors/search';
import {
  SEARCH_FILTER_TYPES, CONTACT_SENIORITY, COMPANY_SIZE, COMPANY_TYPE, CONTACT_UPDATED_AT,
} from 'data/types/search.filters.types';
import * as SearchFiltersTypes from 'data/types/search.filters.types';
import { tourCurrentNameSelector } from 'data/selectors/tour';
import useResetParams from 'hooks/useResetParams';
import {
  FilterItemWrapper,
  ContentWrapper,
  ControlWrapper,
  ClearFilterTag,
} from '../../sidebar/styles';
import ListFilter from './listFilter';
import CheckboxFilter from './radioFilter';
import useLoadOptions from './useLoadOptions';
import IncludedFilters from './listFilter/includedFilters';
import ExcludedFilters from './listFilter/excludedFilters';
import DateRangeFilter from './dateRangeFilter';

const propertiesForAdditionalExpandedRules = [CONTACT_SENIORITY, COMPANY_SIZE, COMPANY_TYPE, CONTACT_UPDATED_AT];

const { Panel } = Collapse;

const FilterItem = ({
  filter,
  first,
  closeFilter,
  collapseKey,
  setCollapseKey,
}) => {
  const currentTour = useSelector(tourCurrentNameSelector);
  const filterKey = `filter_item_${filter.property}_key`;
  const [showOptionsList, setShowOptionsList] = useState(true);

  const selector = getFiltersSelector(filter.property);
  const filters = useSelector(selector);
  const optionsSelector = getFilterOptionsSelector(filter.property);
  const options = useSelector(optionsSelector);
  const propertyFilterCount = filters?.included?.value?.reduce((p) => p + 1, 0) + filters?.excluded?.value?.reduce((p) => p + 1, 0);

  const openCollapse = useCallback(() => setCollapseKey(filterKey), [filterKey, setCollapseKey]);
  const closeCollapse = useCallback(() => setCollapseKey(''), [setCollapseKey]);

  useEffect(() => {
    closeFilter(closeCollapse);
  }, [closeFilter, closeCollapse]);

  const isIncludedFilters = get(filters, 'included.value.length', 0) > 0;
  const isExcludedFilters = get(filters, 'excluded.value.length', 0) > 0;
  const [lockCollapse, setLockCollapse] = useState(filter.showActiveControls && isIncludedFilters);

  if (!lockCollapse && filter.showActiveControls && isIncludedFilters) {
    setLockCollapse(true);
  }

  const includedOptions = filter.isDynamic ? get(filters, 'included.value', []) : options.filter((option) => {
    const hasOption = get(filters, 'included.value', []).some((includedValue) => isEqual(includedValue, get(option, 'value', option)));
    if (!hasOption) {
      return isEqual(get(filters, 'included.value'), get(option, 'value', option));
    }
    return true;
  });
  const excludedOptions = filter.isDynamic ? get(filters, 'excluded.value', []) : options.filter((option) => {
    const hasOption = get(filters, 'excluded.value', []).some((excludedValue) => isEqual(excludedValue, get(option, 'value', option)));
    if (!hasOption) {
      return isEqual(get(filters, 'excluded.value'), get(option, 'value', option));
    }
    return true;
  });

  const removeFilter = useResetParams(() => removeOptionFromFilters({ property: filter.property, element: filters?.included?.value[0] }));
  const removePropertyFilter = useResetParams(() => removePropertyFromFilter({ property: filter.property }));

  const excludedPropertiesTags = ['email'];

  useLoadOptions(filter);

  const showListForSingle = useCallback(() => {
    if (filter?.count === SearchFiltersTypes.COUNT_OPTIONS_TYPES.SINGLE) {
      setShowOptionsList(true);
    }
  }, [filter]);

  useEffect(() => {
    if (!isIncludedFilters && !isExcludedFilters && !showOptionsList && filter.collapseIfEmpty) {
      closeCollapse();
    }
  }, [isIncludedFilters, isExcludedFilters, showOptionsList, filter, closeCollapse]);

  const renderBodyFilter = useCallback(() => {
    switch (filter.type) {
      case SEARCH_FILTER_TYPES.TREE:
      case SEARCH_FILTER_TYPES.LIST:
        return (
          <ListFilter
            filter={filter}
            filters={filters}
            showOptionsList={showOptionsList}
            setShowOptionsList={setShowOptionsList}
          />
        );
      case SEARCH_FILTER_TYPES.CHECKBOX:
        return (
          <CheckboxFilter
            filter={filter}
          />
        );
      case SEARCH_FILTER_TYPES.DATE_RANGE:
        return (<DateRangeFilter filter={filter} />);
      default:
        return null;
    }
  }, [filter, filters, showOptionsList]);

  const filterTags = useMemo(() => (!filter.disableTags ? (
    <>
      { (isIncludedFilters && !excludedPropertiesTags.includes(filter.property)) && <IncludedFilters property={filter.property} options={includedOptions} excludedOp={filter.excludedOp} excluded={filter.excluded} handleClose={showListForSingle} /> }
      { (isExcludedFilters && !excludedPropertiesTags.includes(filter.property)) && <ExcludedFilters property={filter.property} options={excludedOptions} includedOp={filter.includedOp} splittable={filter.splittable} /> }
    </>
  ) : null), [showListForSingle, filter.disableTags, isIncludedFilters, isExcludedFilters, filter.property, excludedPropertiesTags, includedOptions, excludedOptions, filter.excludedOp, filter.includedOp, filter.splittable, filter.excluded]);

  return (
    <FilterItemWrapper
      key={`filter_item_${filter.property}`}
      data-tour={get(filter, 'dataTour', []).join(' ')}
      first={first}
      active={filter?.activeInDemoTour && TOUR.SEARCH_LEADS_DATABASE === currentTour}
      areOptionExists={(includedOptions.length || excludedOptions.length) && propertiesForAdditionalExpandedRules.some((item) => item === filter.property)}
    >
      <Collapse
        expandIconPosition="right"
        onChange={(props) => {
          if (props.length) {
            openCollapse();
            setShowOptionsList(true);
            setLockCollapse(filter.showActiveControls && isIncludedFilters);
          } else {
            if (filter.showActiveControls) {
              if (isIncludedFilters) removeFilter();
              setLockCollapse(false);
            }
            closeCollapse([]);
          }
        }}
        activeKey={filterKey}
      >
        <Panel
          header={(
            <>
              <div
                style={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                }}
                className={`filter_${filter.property.replace('.', '_')}`}
              >
                <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {filter.label}
                  {filter.isNew && <Badge size="small" style={{ marginLeft: 3 }}>new</Badge>}
                </div>
                <ControlWrapper>
                  {propertyFilterCount ? (
                    <ClearFilterTag
                      closable
                      type="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        removePropertyFilter();
                      }}
                      onClose={() => removePropertyFilter()}
                    >
                      {propertyFilterCount}
                    </ClearFilterTag>
                  ) : null}
                  {(collapseKey === filterKey || lockCollapse) ? <Icon size="small" type="ic-remove" fill="primary" className="ic_minus" /> : <Icon size="small" type="ic-add" fill="primary" className="ic_plus" />}
                </ControlWrapper>
              </div>
              {filterKey !== collapseKey || (!filter?.input && !(filter?.type === SEARCH_FILTER_TYPES.DATE_RANGE)) ? (<div>{filterTags}</div>) : null}
            </>
          )}
          key={(collapseKey === filterKey || lockCollapse) ? filterKey : ''}
          showArrow={false}
        >
          <ContentWrapper>
            {renderBodyFilter()}
            {filterKey === collapseKey && (filter?.input || filter?.type === SEARCH_FILTER_TYPES.DATE_RANGE) ? filterTags : null}
          </ContentWrapper>
        </Panel>
      </Collapse>
    </FilterItemWrapper>
  );
};

export default FilterItem;
