import { COLORS } from 'constants/styles';
import styled from 'styled-components';

export const ErrorLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.BLACK_G1};
  text-align: center;

  b {
    color: ${COLORS.RED};
  }
`;
