/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import {
  Skeleton,
  Dropdown,
  Menu,
} from 'antd';
import moment from 'moment';

import { billingCyclesSelector } from 'data/selectors/billingCycles';
import PaymentButton from 'components/paymentGate/components/PaymentButtons';
import useFastSpringCheckout from 'hooks/useFastSpring/useFastSpringCheckout';
import useInitBulkVerification from 'hooks/verifications/useInitBulkVerification';
import calculatePrice from 'helpers/calculatePrice';

import {
  cardsSelector,
  existFastSpringCardSelector,
  getDefaultCard,
} from 'data/selectors/cards';
import {
  Icon,
} from 'components/common';
import { discountCount } from 'helpers/calcHelpers';
import { toggleModal } from 'data/actions/modals';
import Loader from 'components/loading';
import {
  cardIconStyles,
  planDescriptionMap,
  cardIcon,
  planDescriptionMapSecondRow,
} from 'data/types/subscription.types';
import { fastSpringDataSelector, selectSubscriptionQuantitySelector, subscriptionsSelector } from 'data/selectors/subscriptions';
import { isExistsFSInvoiceSelector } from 'data/selectors/invoices';
import { verificationsBulkSelector } from 'data/selectors/verifications';
import { sendViewItemAnalytic, sendAddToCartAnalytic } from 'helpers/googleAnalytics';
import { PAYERS } from 'constants/payers';
import { updateFSPayer, updateSelectSubscriptionQuantity } from 'data/actions/subscriptions';
import { fastSpringUpdateLoadingSelector } from 'data/selectors/loading';
import { isBlackFridayPricingSelector } from 'data/selectors/user';
import usePayHelper from './usePayHelper';
import {
  LabelItalic,
  FastSpringWrapper,
  BillingWrapper,
  BillingBtnWrapper,
  PaymentCardWrapper,
  BillingHeader,
  PaymentIconWrapper,
  PaymentIcon,
  PaymentContainer,
  PaymentDescriptionGrey,
  PaymentDescriptionGreyLite,
  PeriodWrapper,
  PlanDescription,
  PlanDetails,
  BillingSubHeader,
  SummaryWrapper,
  SummaryRow,
  QuantityWrapper,
  QuantityCount,
  SummaryRowItem,
  SummaryTotal,
  PayerRadioGroup,
  PayerRadio,
  PayerWrapper,
  DropDownLabel,
} from '../styles';

const FastSpringGate = ({
  billingCycle,
  plan,
  bulkId,
  phoneSearchPlan,
  phoneSearchQuantity,
  payDueTodayPhoneSearch,
}) => {
  const dispatch = useDispatch();
  const subscription = useSelector(subscriptionsSelector);
  const selectSubscriptionQuantity = useSelector(selectSubscriptionQuantitySelector);
  const bulk = useSelector(verificationsBulkSelector(bulkId));
  const cards = useSelector(cardsSelector);
  const existsFastSpringCard = useSelector(existFastSpringCardSelector);
  const billingCyclesData = useSelector(billingCyclesSelector);
  const fastSpringLibData = useSelector(fastSpringDataSelector);
  const defaultCartItem = useSelector(getDefaultCard);
  const isExistsFSInvoice = useSelector(isExistsFSInvoiceSelector);
  const fastSpringUpdateLoading = useSelector(fastSpringUpdateLoadingSelector);
  const isBlackFriday = useSelector(isBlackFridayPricingSelector);

  const [isBulkVerification, setIsBulkVerification] = useState(false);
  const [isSubscriptionUpdate, setIsSubscriptionUpdate] = useState(false);
  const [price, setPrice] = useState(fastSpringLibData?.totalValue);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedCard, setSelectedCard] = useState(false);
  const [isNewCard, setIsNewCard] = useState(true);

  const [checkoutCycle, setCheckoutCycle] = useState(billingCycle);
  const [quantity, setQuantity] = useState(get(plan, 'customQuantity', 1));
  const pauseDiscount = useMemo(() => {
    if (!bulkId && subscription?.status === 'pause' && moment().diff(subscription?.pauseData?.pauseSetAt, 'month') >= 1) {
      return 30;
    }
    return 0;
  }, [subscription, bulkId]);
  const getFastSpringPlanName = useMemo(() => {
    if (checkoutCycle?.period === 'year') return isBlackFriday ? `plan-yearly-${plan?.planId}-50` : `plan-yearly-${plan?.planId}`;
    return pauseDiscount ? `plan-${plan?.planId}-30` : `plan-${plan?.planId}`;
  }, [pauseDiscount, checkoutCycle, plan, isBlackFriday]);

  const [fastSpringData, setFastSpringData] = useState(() => (bulk ? 'verifications' : getFastSpringPlanName));

  useEffect(() => {
    sendViewItemAnalytic({
      plan, price, billingCycle, quantity,
    });
  }, [price]); // eslint-disable-line

  useEffect(() => {
    if (bulk || plan) setFastSpringData(bulk ? 'verifications' : getFastSpringPlanName);
  }, [bulk, getFastSpringPlanName, plan]);

  const { fastSpringCheckout, updateItemQuantity, addItemToCheckout } = useFastSpringCheckout({ setLoading: setIsProcessing });
  const { executeBulkVerification } = useInitBulkVerification(bulkId);
  const usePayHelperData = {
    setIsProcessing,
    plan,
    checkoutCycle,
    quantity,
    bulkId,
    selectedCard,
    bulk,
    isBulkVerification,
    phoneSearchQuantity,
  };
  if (phoneSearchPlan) {
    usePayHelperData.phoneSearchPrice = phoneSearchPlan?.price ? 49 : 0;
    usePayHelperData.phoneNewCredits = (payDueTodayPhoneSearch / 49) * 150;
  }
  const { handlePay } = usePayHelper(usePayHelperData);

  useEffect(() => {
    let newPrice = fastSpringLibData?.totalValue;

    if (defaultCartItem && !existsFastSpringCard) {
      const count = get(bulk, 'fileInfo.billable') % 1000 ? get(bulk, 'fileInfo.total') : get(bulk, 'fileInfo.billable');
      newPrice = calculatePrice({
        planPrice: get(plan, 'price'),
        period: get(checkoutCycle, 'period'),
        discount: pauseDiscount || get(checkoutCycle, 'discount'),
        quantity: bulkId ? count : quantity,
        isCustom: get(plan, 'isCustom'),
        isBlackFriday,
      });
    } else if (pauseDiscount) {
      newPrice = calculatePrice({
        planPrice: get(plan, 'price'),
        period: get(checkoutCycle, 'period'),
        discount: pauseDiscount || get(checkoutCycle, 'discount'),
        quantity,
        isCustom: get(plan, 'isCustom'),
        isBlackFriday,
      });
    }
    setPrice(newPrice);
  }, [fastSpringLibData, defaultCartItem, isBlackFriday, existsFastSpringCard, quantity, checkoutCycle, plan, bulkId, bulk, pauseDiscount]);

  useEffect(() => {
    let itemCheckoutQuantity = plan?.customQuantity || quantity;

    if (bulk) {
      const count = Math.ceil(get(bulk, 'fileInfo.billable') / 1000);
      // eslint-disable-next-line no-nested-ternary
      itemCheckoutQuantity = bulk ? count < 2 ? 2 : count : 1;
    }

    if ((['Pro', 'Growth 50K'].includes(plan?.name) || ['Pro', 'Growth 50K'].includes(plan?.newName)) && quantity !== selectSubscriptionQuantity) {
      itemCheckoutQuantity = selectSubscriptionQuantity;
      // plan.customQuantity = selectSubscriptionQuantity;
      setQuantity(selectSubscriptionQuantity);
    }

    if (phoneSearchPlan && (!payDueTodayPhoneSearch || payDueTodayPhoneSearch > 0)) {
      const finalPhoneSearchQuantity = payDueTodayPhoneSearch ? payDueTodayPhoneSearch / 49 : phoneSearchQuantity;
      addItemToCheckout('phone-search', () => {
        updateItemQuantity('phone-search', finalPhoneSearchQuantity);
      });
    } else {
      addItemToCheckout('phone-search', () => {
        updateItemQuantity('phone-search', 0);
      });
    }

    if (bulk || plan) {
      addItemToCheckout(fastSpringData, () => {
        updateItemQuantity(fastSpringData, itemCheckoutQuantity);
      });
    }
  }, [fastSpringData, addItemToCheckout, plan, updateItemQuantity, bulk, phoneSearchQuantity, phoneSearchPlan, quantity, selectSubscriptionQuantity, payDueTodayPhoneSearch]);

  useEffect(() => {
    const defaultCart = cards.find((card) => get(card, 'isDefault'));
    setIsNewCard(!defaultCart);
  }, [cards]);

  useEffect(() => {
    if (checkoutCycle) {
      setIsSubscriptionUpdate(!!price);
    }
    if (bulkId) {
      setIsBulkVerification(!!price);
    }
  }, [price, checkoutCycle, bulkId]);

  useEffect(() => {
    if (!isNewCard) {
      const defaultCart = cards.find((card) => get(card, 'isDefault'));
      setSelectedCard(defaultCart || get(cards, '[0]'));
    }
  }, [cards, isNewCard]);

  const fastSpringSuccessCallback = useCallback(() => {
    if (isBulkVerification) {
      executeBulkVerification();
      dispatch(toggleModal('billing', false));
    }
  }, [dispatch, isBulkVerification, executeBulkVerification]);

  const fsCheckoutInit = useCallback(() => {
    sendAddToCartAnalytic(plan, checkoutCycle, quantity);
    fastSpringCheckout({
      firstPayment: !existsFastSpringCard,
      billingCycle: get(checkoutCycle, '_id'),
      planPrice: get(plan, 'price'),
      bulkId,
      isBulkVerification,
      planId: plan?.planId,
      phoneSearchPrice: phoneSearchPlan?.price,
      phoneSearchQuantity,
      successCallback: (event) => {
        const { detail = {} } = event;

        if (detail?.reference) {
          fastSpringSuccessCallback();
        }
      },
    });
  }, [existsFastSpringCard, fastSpringSuccessCallback, checkoutCycle, plan, bulkId, isBulkVerification, fastSpringCheckout, quantity, phoneSearchPlan, phoneSearchQuantity]);

  const initCheckout = useCallback(() => {
    if (phoneSearchPlan?.price > 0 && !existsFastSpringCard) fsCheckoutInit();
    else if ((defaultCartItem && !(bulkId && existsFastSpringCard))) {
      handlePay();
    } else {
      fsCheckoutInit();
    }
  }, [existsFastSpringCard, bulkId, handlePay, defaultCartItem, fsCheckoutInit, phoneSearchPlan]);

  const paymentCardSection = useMemo(() => {
    const cardType = get(selectedCard, 'type')?.toLowerCase();

    if (existsFastSpringCard && bulkId) return null;
    return (
      <PaymentCardWrapper>
        <BillingHeader>Payment card</BillingHeader>
        <PaymentContainer>
          {cardIcon[cardType] ? (
            <PaymentIconWrapper>
              <PaymentIcon
                style={cardIconStyles[cardType]}
                src={cardIcon[cardType]}
              />
            </PaymentIconWrapper>
          ) : null}
          <PaymentDescriptionGrey>
            {`${get(selectedCard, 'firstName', '')} ${get(selectedCard, 'lastName', '')} xxxx-${get(selectedCard, 'number', '')} `}
          </PaymentDescriptionGrey>
          <PaymentDescriptionGreyLite>
            {get(selectedCard, 'expireAt') ? `Expires ${moment(get(selectedCard, 'expireAt', '')).format('MM/YYYY')} ` : null}
          </PaymentDescriptionGreyLite>
        </PaymentContainer>
      </PaymentCardWrapper>
    );
  }, [selectedCard, bulkId, existsFastSpringCard]);

  const dropdownItems = useCallback(() => billingCyclesData.map((cycle) => (
    <Menu.Item
      key={cycle?.name}
      onClick={() => {
        if (isProcessing || fastSpringUpdateLoading) return;
        const newCurrentBilling = billingCyclesData.find((cycleItem) => cycleItem._id === cycle._id);
        setCheckoutCycle(newCurrentBilling);
      }}
    >
      {cycle?.name}
    </Menu.Item>
  )), [billingCyclesData, isProcessing, fastSpringUpdateLoading]);

  const payerData = useCallback(() => (
    <PayerWrapper>
      <BillingHeader style={{ marginBottom: 16 }}>Payer</BillingHeader>
      <PayerRadioGroup
        onChange={(payerType) => {
          const payer = get(payerType, 'target.value', PAYERS.INDIVIDUAL);
          setIsProcessing(true);

          if (quantity > 1) {
            updateItemQuantity(fastSpringData, 1, () => {
              setQuantity(1);
              dispatch(updateFSPayer(payer));
            }, true);
          } else {
            dispatch(updateFSPayer(payer));
          }
        }}
        value={get(subscription, 'payer', PAYERS.INDIVIDUAL)}
      >
        <PayerRadio size="default" value={PAYERS.INDIVIDUAL}>Individual</PayerRadio>
        <PayerRadio size="default" value={PAYERS.COMPANY}>Company</PayerRadio>
      </PayerRadioGroup>
    </PayerWrapper>
  ), [dispatch, subscription, quantity, fastSpringData, updateItemQuantity]);

  const summaryData = useCallback(() => {
    const isYearly = checkoutCycle.period === 'year';
    let finalPrice = price;
    const discount = discountCount(plan?.price, pauseDiscount || checkoutCycle.discount, checkoutCycle.period, isBlackFriday) * quantity;
    const planCost = ((isYearly ? plan?.price * 12 * quantity : (plan?.price * quantity)) || 0);
    let phoneSearchConst = phoneSearchPlan?.price;
    if (payDueTodayPhoneSearch) phoneSearchConst = payDueTodayPhoneSearch < 0 ? 0 : payDueTodayPhoneSearch;
    let subtotal = planCost;
    if (phoneSearchPlan?.price && (!payDueTodayPhoneSearch || payDueTodayPhoneSearch > 0)) {
      subtotal += payDueTodayPhoneSearch || phoneSearchPlan?.price || 0;
    }
    let tax = fastSpringLibData.taxValue ? fastSpringLibData.taxValue : 0;
    const skeletonOptions = {
      loading: isProcessing || fastSpringUpdateLoading,
      paragraph: false,
      active: true,
    };

    if (!existsFastSpringCard && cards?.length) {
      tax = 0;
    }

    if (defaultCartItem?.taxId && !bulkId && existsFastSpringCard) {
      tax = 0;
      finalPrice -= fastSpringLibData.taxValue;
    }

    return (
      <SummaryWrapper>
        <BillingHeader>Summary</BillingHeader>
        {
          ['Pro', 'Growth 50K'].includes(plan?.name) ? (
            <SummaryRow>
              <SummaryRowItem lite>
                Plan Quantity
              </SummaryRowItem>
              <SummaryRowItem>
                <QuantityWrapper>
                  <Icon
                    type="ic-remove"
                    fill="grey"
                    onClick={() => {
                      if (quantity === 1 || isProcessing) return;
                      const newQuantity = quantity - 1;
                      updateItemQuantity(fastSpringData, newQuantity, () => {
                        setQuantity(newQuantity);
                        dispatch(updateSelectSubscriptionQuantity(newQuantity));
                      });
                    }}
                  />
                  <QuantityCount>{quantity}</QuantityCount>
                  <Icon
                    type="ic-add"
                    fill="grey"
                    onClick={() => {
                      if (quantity >= 10 || isProcessing) return;
                      const newQuantity = quantity + 1;
                      updateItemQuantity(fastSpringData, newQuantity, () => {
                        dispatch(updateSelectSubscriptionQuantity(newQuantity));
                        setQuantity(newQuantity);
                      });
                    }}
                  />
                </QuantityWrapper>
              </SummaryRowItem>
            </SummaryRow>
          ) : null
        }
        <SummaryRow>
          {
            plan && !plan.isCheapest && (
              <>
                <SummaryRowItem lite>Period</SummaryRowItem>
                <Dropdown
                  trigger={['click']}
                  overlay={(
                    <Menu>
                      {dropdownItems()}
                    </Menu>
                  )}
                >
                  <DropDownLabel>
                    {checkoutCycle?.name}
                    <Icon type="ic-arrow-drop-down" />
                  </DropDownLabel>
                </Dropdown>
              </>
            )
          }
        </SummaryRow>
        <SummaryRow>
          {plan && (
            <>
              <SummaryRowItem lite>Plan cost</SummaryRowItem>
              <SummaryRowItem>{`$${planCost?.toFixed(0)} USD`}</SummaryRowItem>
            </>
          )}
        </SummaryRow>
        {phoneSearchPlan && (
          <SummaryRow>
            <SummaryRowItem lite>Phone number credits</SummaryRowItem>
            <SummaryRowItem>{`$${phoneSearchConst.toFixed(0)} USD`}</SummaryRowItem>
          </SummaryRow>
        )}
        <SummaryRow>
          <SummaryRowItem lite>Subtotal</SummaryRowItem>
          <SummaryRowItem><Skeleton {...skeletonOptions}>{`$${subtotal?.toFixed(0)} USD`}</Skeleton></SummaryRowItem>
        </SummaryRow>
        {pauseDiscount || discount ? (
          <SummaryRow>
            <SummaryRowItem lite>Discount</SummaryRowItem>
            <SummaryRowItem><Skeleton {...skeletonOptions}>{`- $${discount?.toFixed(0)} USD`}</Skeleton></SummaryRowItem>
          </SummaryRow>
        ) : null}
        <SummaryRow>
          <SummaryRowItem lite>Tax</SummaryRowItem>
          <SummaryRowItem><Skeleton {...skeletonOptions}>{`$${tax?.toFixed(0)} USD`}</Skeleton></SummaryRowItem>
        </SummaryRow>
        <SummaryTotal showBorder={isExistsFSInvoice}>
          <SummaryRowItem>Total</SummaryRowItem>
          <SummaryRowItem><Skeleton {...skeletonOptions}>{`$${finalPrice?.toFixed(0)} USD`}</Skeleton></SummaryRowItem>
        </SummaryTotal>
      </SummaryWrapper>
    );
  }, [isExistsFSInvoice, quantity, price, plan, checkoutCycle, isProcessing, fastSpringData, fastSpringLibData, payDueTodayPhoneSearch,
    updateItemQuantity, existsFastSpringCard, cards, pauseDiscount, defaultCartItem, bulkId, phoneSearchPlan, dropdownItems, dispatch, fastSpringUpdateLoading, isBlackFriday]);

  const bulkSummaryData = useCallback(() => {
    const finalPrice = price;
    let tax = fastSpringLibData.taxValue ? fastSpringLibData.taxValue : 0;
    const skeletonOptions = {
      loading: isProcessing || fastSpringUpdateLoading,
      paragraph: false,
      active: true,
    };

    if (!existsFastSpringCard && bulkId) {
      tax = 0;
    }

    return (
      <SummaryWrapper>
        <BillingHeader>Summary</BillingHeader>
        <SummaryRow>
          <SummaryRowItem lite>Tax</SummaryRowItem>
          <SummaryRowItem><Skeleton {...skeletonOptions}>{`$${tax?.toFixed(2)} USD`}</Skeleton></SummaryRowItem>
        </SummaryRow>
        <SummaryTotal showBorder={isExistsFSInvoice}>
          <SummaryRowItem>Total</SummaryRowItem>
          <SummaryRowItem><Skeleton {...skeletonOptions}>{`$${finalPrice?.toFixed(2)} USD`}</Skeleton></SummaryRowItem>
        </SummaryTotal>
      </SummaryWrapper>
    );
  }, [price, isProcessing, fastSpringLibData, bulkId, existsFastSpringCard, isExistsFSInvoice, fastSpringUpdateLoading]);

  return (
    <Loader loading={isProcessing || fastSpringUpdateLoading}>
      <FastSpringWrapper>
        <BillingWrapper>
          {selectedCard ? paymentCardSection : null}
          <PeriodWrapper>
            {!bulkId && plan ? (
              <>
                <BillingHeader>Plan and period</BillingHeader>
                <PlanDescription>
                  <PlanDetails>
                    <BillingSubHeader>
                      {plan?.name}
                      {' '}
                      plan
                    </BillingSubHeader>
                    {planDescriptionMap.map((item) => (plan[item.key] ? <PaymentDescriptionGreyLite key={item.key}>{`${parseInt(quantity * plan[item.key] * (billingCycle?.period === 'year' ? 12 : 1), 10)?.toLocaleString()?.replace(/,/g, ' ')} ${item.label}`}</PaymentDescriptionGreyLite> : null))}
                  </PlanDetails>
                  <PlanDetails style={{ marginTop: '26px' }}>
                    {planDescriptionMapSecondRow.map((item) => (plan[item.key] ? <PaymentDescriptionGreyLite key={item.key}>{`${parseInt(quantity * plan[item.key], 10)?.toLocaleString()?.replace(/,/g, ' ')} ${item.label}`}</PaymentDescriptionGreyLite> : null))}
                    {phoneSearchPlan?.credits > 0 && <PaymentDescriptionGreyLite>{`${parseInt(phoneSearchPlan?.credits * (billingCycle?.period === 'year' ? 12 : 1), 10)?.toLocaleString()?.replace(/,/g, ' ')} phone number credits`}</PaymentDescriptionGreyLite>}
                  </PlanDetails>
                </PlanDescription>
              </>
            ) : null}
            {bulk ? bulkSummaryData() : summaryData()}
            {!isExistsFSInvoice ? payerData() : null}
          </PeriodWrapper>
        </BillingWrapper>
        <BillingBtnWrapper>
          <PaymentButton fsCheckoutInit={fsCheckoutInit} bulkId={bulkId} price={price} isProcessing={isProcessing || fastSpringUpdateLoading} isSubscriptionUpdate={isSubscriptionUpdate} checkout={initCheckout} />
          <LabelItalic>
            Note:
            {' '}
            This transaction will appear on your card statement as
            {' '}
          </LabelItalic>
          <strong style={{ fontWeight: 600, display: 'inline-block' }}> FS*GetProspect</strong>
        </BillingBtnWrapper>
      </FastSpringWrapper>
    </Loader>
  );
};

export default FastSpringGate;
