import styled from 'styled-components';
import {
  Checkbox,
  Modal as ModalA,
} from 'antd';

import * as CONSTANTS from 'constants/styles';
import plusIcon from 'assets/images/ic-plus-blue.svg';
import { Input, Button, Select } from 'components/common';
import infoImgPath from 'assets/images/ic-info-outline.svg';
import infoImgPathPrimary from 'assets/images/ic-info-outline-primary.svg';
import { ModalTitle as BaseModalTitle } from '../baseModal/styles';

export const DropdownWrapper = styled.div`
  .ant-select-dropdown-menu {
    .ant-select-dropdown-menu-item:not(.saved-list):not(.ant-select-dropdown-menu-item-disabled) {
      color: ${CONSTANTS.COLORS.PRIMARY};
      border-bottom: 1px solid ${CONSTANTS.COLORS.GREY_G7} !important;
      position: relative;
      background-image: url(${plusIcon});
      background-position: 10px;
      background-repeat: no-repeat;
      &:before {
        content: " Create '";
        position: inherit;
        margin-left: 20px;
      }

      &:after {
        content: "' list";
      }
    }

    .ant-select-dropdown-menu-item.saved-list {
      display: flex;
      justify-content: space-between;
      align-content: center;
      overflow: hidden;
    }

    .ant-select-dropdown-menu-item-selected:not(.saved-list) {
      display: none;
    } 
  }

  .ant-tooltip-inner {
    text-align: left;
    background-color: rgba(0, 0, 0, 0.85);
    width: 270px;
  }

  .ant-tooltip-arrow {
    display: none;
  }
`;

export const ListSelect = styled(Select)`
  width: 100%;
  align-self: center;
  .ant-select-arrow {
    top: 15px !important;
  }

  .ant-select-selection {
    width: 100%;
    margin-bottom: 20px;

    .ant-select-selection__choice {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      max-width: 180px;
      background-color: ${CONSTANTS.COLORS.WHITE};
      position: relative;
      border: none;
      padding: 0 0 0 3px;
      transition: unset !important;
      &:nth-child(2) {
        display: none;
      }

      .ant-select-selection__choice__content {
        font-family: AvenirNext;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: ${CONSTANTS.COLORS.BLACK_G1};
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
        margin: 0;
        
        span {
          &:nth-child(2) {
            display: none;
          }
        }
      }
      .anticon-close {
        display: none;
      } 
    }
  }
`;

export const OptionsContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
`;

export const OptionsItem = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: column;
    margin-bottom: 18px;
    justify-content: flex-end;
    color: ${CONSTANTS.COLORS.BLACK_G1};

    &:first-child {
      margin-right: 10px;
    }
`;

export const InfoTextWrapper = styled.div`
  font-weight: 500;
  display: inline-flex;
  justify-content: row;
  margin-bottom: 20px;
  width: 100%;
`;

export const Text = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
  color: ${(props) => (props.color)};
`;

export const AmountInput = styled(Input)`

`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${CONSTANTS.COLORS.GREY_G1};
  margin-bottom: 5px;
  display: flex;
`;

export const Border = styled.div`
  border-top: 1px solid #e3e3e3;
  margin: 0 -20px;
`;

export const OptionCheckbox = styled(Checkbox)`
  margin: 20px 0 0 0 !important;
  width:100%;

  ${(props) => props.bottomSpace && `
    margin-bottom: 20px !important;
  `}

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border: 2px solid ${CONSTANTS.COLORS.PRIMARY};

      &::after {
        width: 8px;
        height: 15px;
        top: 40%;
        left: 10%;
      }
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 2px solid ${CONSTANTS.COLORS.GREY_G8};
  }

  span {
    color: ${CONSTANTS.COLORS.BLACK_G1};
    font-weight: 500;
  }
`;

export const OptionStyle = {
  textTransform: 'capitalize',
};

export const SaveSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: ${CONSTANTS.COLORS.GREY_G5};
  padding: 24px 24px 20px 24px;
  margin: 0 -20px;
`;

export const InputNameWrapper = styled.div`
  margin-right: 20px;
  flex: 0.7;

  input {
    width: 400px !important;
  }

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    input {
      width: 100% !important;
    }
  }
`;

export const SelectFrequencyWrapper = styled.div` 
  flex: 0.3;
`;

export const ModalSelect = styled(Select)`
  text-transform: capitalize;
  width: 100%;
`;

export const ModalInput = styled(Input)``;

export const ArrowImg = styled.img`
  transform: ${(props) => (props.open ? 'rotate(180deg)' : '')};
  transition: transform 0.2s ease; 
  cursor: pointer;
`;

export const ListName = styled.span`
  text-overflow: ellipsis;
  margin: 0;
  color: ${CONSTANTS.COLORS.BLACK_G1};
  overflow: hidden;
  text-align: start;
  max-width: ${(props) => (props.folder ? '60%' : '100%')};
`;

export const FolderName = styled.span`
  font-size: 12px;
  text-align: end;
  color: ${CONSTANTS.COLORS.GREY_G2};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const InfoImg = styled.div`
  cursor: pointer;
  background-image: url(${infoImgPath});
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;

  &:hover {
    background-image: url(${infoImgPathPrimary});
  }
`;

export const ListSelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const PrimaryButton = styled(Button)`
  width: 150px;
`;

export const SecondaryButton = styled(Button)`
  width: 100px;
`;

export const Modal = styled(ModalA)`
  .ant-modal-body, .ant-modal-header {
    padding: 20px;
  }
`;

export const ModalTitle = styled(BaseModalTitle)`
  font-size: 16px;
  font-weight: 500;
  margin: -20px;
`;

export const InfoBlock = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  color: ${CONSTANTS.COLORS.GREY_G1};
`;

export const BodyContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
  height: 300px;
`;
