import { getWorkspaceLimits } from './user';

export const getSubscription = () => (dispatch) => {
  dispatch({
    type: 'GET_SUBSCRIPTION',
    payload: {
      endpoint: 'api/v1/subscriptions',
      method: 'GET',
      retries: 2,
    },
    components: ['subscriptionsLoader'],
  });
};

export const updateSubscription = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SUBSCRIPTION',
    payload: {
      endpoint: 'api/v1/subscriptions/update',
      method: 'POST',
      body: data,
    },
    onSuccess: (subscription) => onSuccess(subscription),
    onFailed,
    components: ['updateSubscription'],
  });
};

export const updateSubscriptionStatus = ({
  status,
  reason,
  period,
}, onSuccess = () => {}) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SUBSCRIPTION_STATUS',
    payload: {
      endpoint: 'api/v1/subscriptions/status',
      method: 'POST',
      body: {
        status,
        reason,
        period,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    components: ['updateSubscriptionStatusLoader'],
    onSuccess: (data) => {
      dispatch(getWorkspaceLimits());
      onSuccess(data);
    },
  });
};

export const cancelFastSpringSubscription = ({ data, onSuccess = () => {} }) => (dispatch) => {
  dispatch({
    type: 'CANCEL_FASTSPRING_SUBSCRIPTION',
    payload: {
      method: 'POST',
      endpoint: `api/v1/subscriptions/fastspring/cancel/${data}`,
    },
    onSuccess,
  });
};

export const encryptSensitiveData = ({ data, onSuccess = () => {} }) => (dispatch) => {
  dispatch({
    type: 'ENCRYPT_FASTSPRING_DATA',
    payload: {
      method: 'POST',
      endpoint: 'api/v1/subscriptions/fastspring/sensitive',
      body: data,
    },
    onSuccess,
  });
};

export const setPauseForSubscription = ({ plan, onSuccess = () => {} }) => (dispatch) => {
  dispatch({
    type: 'PAUSE_ACTIVE_SUBSCRIPTION',
    payload: {
      method: 'POST',
      endpoint: 'api/v1/subscriptions/fastspring/pause',
      body: {
        plan,
      },
    },
    onSuccess,
  });
};

export const setRedirectAfterFirstPay = (redirectStatus) => (dispatch) => {
  dispatch({
    type: 'REDIRECT_AFTER_FIRST_PAY',
    data: {
      redirectStatus,
    },
  });
};

export const setFastSpringData = (data = {}) => (dispatch) => {
  dispatch({
    type: 'SET_FAST_SPRING_DATA',
    data,
  });
};

export const setCancelSubscription = (data = {}) => (dispatch) => {
  dispatch({
    type: 'SET_CANCEL_SUBSCRIPTION',
    data,
  });
};

export const resetCancelSubscription = () => (dispatch) => {
  dispatch({
    type: 'RESET_CANCEL_SUBSCRIPTION',
    data: {},
  });
};

export const updateFSPayer = (payerType) => (dispatch) => {
  dispatch({
    type: 'UPDATE_FS_PAYER',
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/subscriptions/fastspring/payer',
      body: {
        payer: payerType,
      },
    },
    onSuccess: () => {
      dispatch(getSubscription());
    },
  });
};

export const updateSelectSubscriptionQuantity = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SELECT_SUBSCRIPTION_QUANTITY',
    data: {
      value,
    },
  });
};
