import React from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Label } from '@newlab-io/getprospect-react-components/lib/ui';

import { Button } from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { createImportLoaderSelector } from 'data/selectors/loading';
import { modalOptionsSelector, modalsSelector } from 'data/selectors/modals';
import {
  ModalTitle,
  CloseIcon,
  Footer,
} from '../baseModal/styles';

const OutOfLimitsModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { visible } = useSelector(modalsSelector);
  const { handleContinue } = useSelector(modalOptionsSelector);
  const loading = useSelector(createImportLoaderSelector);

  const handleClose = () => dispatch(toggleModal('out_of_limits', false));

  const handleUpgradePlan = () => {
    handleClose();
    history.push('/pricing');
  };

  const handleProxyContinue = () => {
    handleClose();
    handleContinue();
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Out of limits
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={420}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <div style={{ paddingBottom: 10 }}>
          <Label kind="tertiary">
            Your current number of credits is not enough to process the entire file. You can continue the action, and we&lsquo;ll enrich rows within your limit.
          </Label>
        </div>
        <Footer>
          <Button
            data-form="true"
            type="primary"
            onClick={handleUpgradePlan}
          >
            Upgrade plan
          </Button>
          <Button
            type="normal"
            onClick={handleProxyContinue}
            loading={loading}
          >
            Continue
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default OutOfLimitsModal;
