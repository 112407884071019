import get from 'lodash/get';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IMPORT_TYPE } from 'data/types/import.types';
import { isAuthSelector } from 'data/selectors/user';
import { removeOperation, clearFireTrigger } from 'data/actions/storeTriggers';
import { searchFiltersSelector, insightsSelector } from 'data/selectors/search';
import { getBlockedContacts, searchContacts, setContactsInitialRequest } from 'data/actions/contacts';
import { getBlockedCompanies, searchCompanies, setCompaniesInitialRequest } from 'data/actions/companies';
import { storeTriggersOperationsSelector, storeTriggersFireSelector } from 'data/selectors/storeTriggers';

import { Progress } from 'data/types/progress.types';
import { SOURCE_TYPE } from 'data/types/source.types';
import { downloadExportFile } from 'data/actions/export';
import { updateWorkspaceLimit } from 'data/actions/user';
import { getSequenceLeads } from 'data/actions/sequences';
import { contactsSelector } from 'data/selectors/contacts';
import { companiesSelector } from 'data/selectors/companies';
import { FROM, SOURCE, TYPE } from 'data/types/storeTriggers.types';
import useInsightSearch from './useInsightSearch';

const useStoreTriggers = () => {
  const dispatch = useDispatch();
  const operations = useSelector(storeTriggersOperationsSelector);
  const fireTrigger = useSelector(storeTriggersFireSelector);
  const isAuth = useSelector(isAuthSelector);
  const contactsPageData = useSelector(contactsSelector);
  const companiesPageData = useSelector(companiesSelector);
  const contactData = useSelector(insightsSelector(SOURCE_TYPE.CONTACT));
  const companyData = useSelector(insightsSelector(SOURCE_TYPE.COMPANY));
  const filters = useSelector(searchFiltersSelector);
  const dispatchSearchAction = useInsightSearch();

  const notificationOperation = useCallback((operation) => {
    const {
      type, source, data,
    } = operation;
    const attachedData = get(data, 'attachedData');
    const sequenceId = get(attachedData, 'sequenceId');

    if (type === TYPE.IMPORT) {
      const typeImport = get(attachedData, 'typeImport');
      const status = get(attachedData, 'status');

      if (status === Progress.DONE) {
        if (typeImport !== IMPORT_TYPE.IGNORE_LIST) {
          switch (source) {
            case SOURCE.CONTACT: {
              dispatch(setContactsInitialRequest(true));
              break;
            }
            case SOURCE.COMPANY: {
              dispatch(setCompaniesInitialRequest(true));
              break;
            }
            default: break;
          }
        }
        switch (source) {
          case SOURCE.CONTACT: {
            if (typeImport === IMPORT_TYPE.IGNORE_LIST) {
              if (window.location.pathname.includes('settings')) {
                dispatch(getBlockedContacts());
              }
            } else if (sequenceId) {
              if (window.location.pathname.includes(`sequences/${sequenceId}/leads`)) {
                dispatch(getSequenceLeads({ pageNumber: 1 }));
              }
            } else {
              dispatch(searchContacts());
            }
            break;
          }
          case SOURCE.COMPANY: {
            if (typeImport === IMPORT_TYPE.IGNORE_LIST) {
              if (window.location.pathname.includes('settings')) {
                dispatch(getBlockedCompanies());
              }
            } else {
              dispatch(searchCompanies());
            }
            break;
          }
          default: break;
        }
      } else if (status === Progress.FAILED) {
        const isImportLimit = get(attachedData, 'isImportLimit');
        if (isImportLimit) {
          dispatch(updateWorkspaceLimit({ key: 'isImportLimit', value: isImportLimit }));
        }
      }
    }

    if (type === TYPE.ADD_TO_STATIC_LIST || type === TYPE.MOVE_TO_STATIC_LIST) {
      const listIds = get(attachedData, 'listIds');

      switch (source) {
        case SOURCE.CONTACTS: {
          if (listIds) {
            if (window.location.pathname.includes('contacts')) {
              dispatch(searchContacts());
            }
          }
          break;
        }
        case SOURCE.COMPANIES: {
          if (window.location.pathname.includes('companies')) {
            dispatch(searchCompanies());
          }
          break;
        }
        default: break;
      }
    }

    if ([TYPE.UPDATE, TYPE.DELETE, TYPE.DELETE_FROM_STATIC_LIST].includes(type)) {
      switch (source) {
        case SOURCE.CONTACTS: {
          if (window.location.pathname.includes('contacts')) {
            dispatch(searchContacts());
          }
          break;
        }
        case SOURCE.COMPANIES: {
          if (window.location.pathname.includes('companies')) {
            dispatch(searchCompanies());
          }
          break;
        }
        default: break;
      }
    }

    if (type === TYPE.EXPORT && attachedData) {
      dispatch(downloadExportFile(attachedData));
    }

    if (type === TYPE.ADD_TO_SEQUENCE) {
      if (window.location.pathname.includes(`sequences/${sequenceId}/leads`)) {
        dispatch(getSequenceLeads({ pageNumber: 1 }));
      }
    }
  }, [dispatch]);

  const makeOperations = useCallback(() => {
    operations.forEach((operation) => {
      const {
        from,
      } = operation;

      if (from === FROM.NOTIFICATION) {
        notificationOperation(operation);
        dispatch(removeOperation(operation));
      }
    });
  }, [operations, dispatch, notificationOperation]);

  useEffect(() => {
    if (isAuth && operations.length) {
      makeOperations();
    }
  }, [operations, isAuth, makeOperations]);

  const dispatchSearchActionHook = useCallback((sourceType) => {
    dispatchSearchAction({
      filters, sourceType, pageSize: 25, page: 1,
    }, sourceType);
  }, [filters, dispatchSearchAction]);

  const makeFireTrigger = useCallback(() => {
    const trigger = operations.find((operation) => operation.id === fireTrigger);

    if (trigger) {
      const {
        type, source, data, from,
      } = trigger;
      if (type === TYPE.INSIGHT) {
        if ([FROM.CONTACTS, FROM.COMPANIES].includes(from)) {
          const insightData = new Map();
          insightData.set('contact', contactData.data);
          insightData.set('company', companyData.data);

          if (data.selectAll) {
            dispatchSearchActionHook(source);
          } else if (['contact', 'company'].includes(source)) {
            const haveInsightData = get(data, 'ids', []).some((id) => insightData.get(source).find((insightItem) => insightItem.savedWorkspaceId === id));
            if (haveInsightData) {
              dispatchSearchActionHook(source);
            }
          }
        }

        if (from === FROM.SEARCH) {
          switch (source) {
            case SOURCE.CONTACT: {
              if (get(contactsPageData, 'data.length', 0)) {
                dispatch(searchContacts());
              }
              break;
            }
            case SOURCE.COMPANY: {
              if (get(companiesPageData, 'data.length', 0)) {
                dispatch(searchCompanies());
              }
              break;
            }
            default: break;
          }
        }
      }
      dispatch(clearFireTrigger());
    }
  }, [operations, fireTrigger, dispatchSearchActionHook, dispatch, companyData, contactData, contactsPageData, companiesPageData]);

  useEffect(() => {
    if (isAuth && fireTrigger) {
      makeFireTrigger();
    }
  }, [fireTrigger, isAuth, makeFireTrigger]);
};

export default useStoreTriggers;
