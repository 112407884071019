import React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@newlab-io/getprospect-react-components/lib/ui';

import { modalOptionsSelector } from 'data/selectors/modals';
import ExportBody from 'components/modals/exportModal/exportBody';
import Text from '../text';

const Export = ({
  format,
  setFormat,
  sourceType,
  includeProperties = 'columns_only',
  setIncludeProperties,
  showCustomConfiguration,
}) => {
  const options = useSelector(modalOptionsSelector);

  return (
    <Flex gap={12} direction="column">
      <Text />
      <div style={{ width: 320 }}>
        <ExportBody
          format={format}
          setFormat={setFormat}
          sourceType={sourceType}
          includeProperties={includeProperties}
          setIncludeProperties={setIncludeProperties}
          showCustomConfiguration={showCustomConfiguration}
          hasBigLastGap={false}
          backModal="save_from_search"
          backOptions={{
            ...options,
            defaultActiveAction: 'Export',
          }}
        />
      </div>
    </Flex>
  );
};

export default Export;
