import React from 'react';
import { useHistory } from 'react-router-dom';
import { Flex } from '@newlab-io/getprospect-react-components/lib/ui';

import { Button } from 'components/common';
import Text from '../text';
import { ErrorLabel } from './styles';
import { Text as BaseText } from '../styles';

const PhoneNumbers = ({
  phoneCredits,
  isPaidPhoneSubscription,
}) => {
  const history = useHistory();

  if (isPaidPhoneSubscription && phoneCredits.current > 0) {
    return <Text />;
  }

  return (
    <Flex gap={12} direction="column">
      {
        !isPaidPhoneSubscription && (
          <ErrorLabel>
            Oops... your&nbsp;
            <b>Free plan</b>
            &nbsp;doesn’t support phone numbers
          </ErrorLabel>
        )
      }
      <BaseText style={{ textAlign: 'center' }}>Premium users are 219% more likely to connect with prospects via the phone when they call the direct line.</BaseText>
      <Flex justify="center">
        <Button
          style={{ width: 275 }}
          size="default"
          type="primary"
          onClick={() => history.push('/pricing')}
        >
          Buy more credits
        </Button>
      </Flex>
    </Flex>
  );
};

export default PhoneNumbers;
