import React,
{
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '@newlab-io/getprospect-react-components/lib/ui';
import { useHistory } from 'react-router-dom';

import { CREDITS_TYPE } from 'data/types/user.types';
import { upperCaseFirst } from 'helpers/upperCaseFirst';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import {
  setSelectedLists,
  createList,
} from 'data/actions/lists';
import { selectedInsightContactsSelector } from 'data/selectors/contacts';
import { selectedInsightCompaniesSelector } from 'data/selectors/companies';
import { SOURCE_TYPE } from 'data/types/source.types';
import { isPaidPhoneSubscriptionSelector } from 'data/selectors/subscriptions';
import {
  setSelectedInsightContacts,
  addDemoContacts,
} from 'data/actions/contacts';
import { saveFromSearchSelectorLoading } from 'data/selectors/loading';
import {
  setSelectedInsightCompanies,
} from 'data/actions/companies';
import {
  createInsightsSearch,
  getCompaniesLimit,
} from 'data/actions/search';
import {
  accountSelector,
  demoUserSelector,
  userCreditsPhoneSearchSelector,
} from 'data/selectors/user';
import {
  selectedListsSelector,
  listsSelector,
} from 'data/selectors/lists';
import { tourCurrentNameSelector } from 'data/selectors/tour';
import { setOperation } from 'data/actions/storeTriggers';
import usePaidOperation from 'hooks/usePaidOperation';
import { SEARCH_CONTENT } from 'constants/searchContent';
import { getSequencesByName } from 'data/actions/sequences';
import {
  SecondaryButton,
  PrimaryButton,
  Modal,
  ModalTitle,
  BodyContainer,
} from './styles';
import {
  CloseIcon,
  Footer,
} from '../baseModal/styles';
import Action from './action';
import AddToList from './content/addToList';
import PhoneNumbers from './content/phoneNumbers';
import AssignOwner from './content/assignOwner';
import Export from './content/export';
import AddToSequence from './content/addToSequence';

const SaveFromSearchModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lists = useSelector(listsSelector);
  const isDemoUser = useSelector(demoUserSelector);
  const { visible } = useSelector(modalsSelector);
  const {
    total,
    selectedAll,
    selectedItems,
    setSelectedItems,
    sourceType,
    customFilters,
    onSave,
    perCompany,
    saveAdded,
    defaultActiveAction,
  } = useSelector(modalOptionsSelector);
  const account = useSelector(accountSelector);
  const selectedLists = useSelector(selectedListsSelector);
  const selectedInsightContacts = useSelector(selectedInsightContactsSelector);
  const selectedInsightCompanies = useSelector(selectedInsightCompaniesSelector);
  const tourName = useSelector(tourCurrentNameSelector);
  const phoneCredits = useSelector(userCreditsPhoneSearchSelector);
  const isPaidPhoneSubscription = useSelector(isPaidPhoneSubscriptionSelector);
  const loading = useSelector(saveFromSearchSelectorLoading);

  const [ownerId, setOwnerId] = useState(account._id);
  const [sequenceId, setSequenceId] = useState();
  const [openSequence, setOpenSequence] = useState(false);
  const [isSavePhoneNumbers, setIsSavePhoneNumbers] = useState(false);
  const [selectedList, setSelectedList] = useState(selectedLists.map((list) => list._id));
  const [activeAction, setActiveAction] = useState(defaultActiveAction || 'Add to list');
  const [sequences, setSequences] = useState([]);

  const [isExport, setIsExport] = useState(false);
  const [isAddToList, setIsAddToList] = useState(false);
  const [isAddToSequence, setIsAddToSequence] = useState(false);
  const [includeProperties, setIncludeProperties] = useState('columns_only');
  const [fileFormat, setFileFormat] = useState(localStorage.getItem('lastExportFormat') ?? 'csv');

  const actions = useMemo(() => [
    {
      title: 'Add to list',
      icon: 'ic-playlist-add',
      checked: isAddToList,
      Content: () => (
        <AddToList
          selectedList={selectedList}
          onSelect={(list) => {
            setSelectedList(list.filter(Boolean));
            setIsAddToList(true);
          }}
        />
      ),
    },
    {
      title: 'Assign owner',
      icon: 'ic-person-add',
      checked: true,
      Content: () => <AssignOwner owner={ownerId} onAssign={setOwnerId} />,
    },
    process.env.REACT_APP_SEQUENCES_ON ? {
      title: 'Add to sequence',
      icon: 'ic-send',
      checked: isAddToSequence,
      Content: () => (
        <AddToSequence
          sequences={sequences}
          sequenceId={sequenceId}
          openSequence={openSequence}
          onSelectSequence={(id) => {
            setSequenceId(id);
            setIsAddToSequence(true);
          }}
          onChangeOpenSequence={setOpenSequence}
        />
      ),
    } : undefined,
    {
      title: 'Phone numbers',
      icon: 'ic-phone',
      checked: isSavePhoneNumbers,
      Content: () => <PhoneNumbers phoneCredits={phoneCredits} isPaidPhoneSubscription={isPaidPhoneSubscription} />,
    },
    {
      title: 'Export',
      icon: 'ic-file-download',
      checked: isExport,
      Content: () => (
        <Export
          format={fileFormat}
          setFormat={setFileFormat}
          sourceType={sourceType}
          includeProperties={includeProperties}
          setIncludeProperties={setIncludeProperties}
          showCustomConfiguration={sourceType === SOURCE_TYPE.CONTACT}
        />
      ),
    },
  ].filter(Boolean), [selectedList, isSavePhoneNumbers, fileFormat, sourceType, includeProperties, ownerId, isAddToList, isAddToSequence, isExport, sequenceId, openSequence, sequences, phoneCredits, isPaidPhoneSubscription]);

  const { Content } = actions.find(({ title }) => activeAction === title);

  let selectedInsights = selectedItems;
  if (!selectedItems) {
    selectedInsights = sourceType === SOURCE_TYPE.CONTACT ? selectedInsightContacts : selectedInsightCompanies;
  }
  const setSelectedRowKeys = sourceType === SOURCE_TYPE.CONTACT ? setSelectedInsightContacts : setSelectedInsightCompanies;
  const { tour } = Object.fromEntries(new URLSearchParams(window.location.search));

  useEffect(() => {
    dispatch(getSequencesByName('', ({ data }) => {
      setSequences(data.filter((sequence) => sequence?.name?.replace(/\s/g, '')));
    }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCompaniesLimit());
  }, [dispatch]);

  const getPluralSource = () => {
    if (total <= 1) return sourceType === SOURCE_TYPE.CONTACT ? 'lead' : 'company';
    return sourceType === SOURCE_TYPE.CONTACT ? 'leads' : 'companies';
  };

  const saveToList = () => {
    if (selectedList && !selectedList.includes('no-list')) {
      dispatch(setSelectedLists(selectedList));
    }
  };

  const handleClose = () => dispatch(toggleModal('save_from_search', false));

  const onSuccessSaveInsight = (type, header) => {
    saveToList();
    dispatch(setOperation({
      type: 'insight',
      source: sourceType,
      from: 'search',
    }));
    dispatch(toggleMessage(type, { header }));
    handleClose();
    if (setSelectedItems) {
      setSelectedItems([]);
    } else {
      dispatch(setSelectedRowKeys([]));
    }
    if (!tour) dispatch(toggleModal('searches_preview', true, { show: SEARCH_CONTENT.RECENT_ACTIVITY }));
    if (onSave) onSave();
  };

  const handleSaveDemoClick = () => {
    handleClose();
    dispatch(addDemoContacts());
    onSuccessSaveInsight('success', `${upperCaseFirst(getPluralSource())} saved`);
  };

  const typePaidOperation = isSavePhoneNumbers ? CREDITS_TYPE.PHONE_SEARCH : CREDITS_TYPE.EMAIL_SEARCH;
  const handleSaveClick = usePaidOperation(sourceType === SOURCE_TYPE.CONTACT ? typePaidOperation : CREDITS_TYPE.SAVE_COMPANIES, () => {
    const limits = {
      total,
    };
    const needToProcess = selectedInsights.length === 0;
    if (perCompany) limits.perCompany = perCompany;
    const data = {
      selected: total,
      saveAdded,
      source: sourceType,
      listIds: (selectedList || []).filter((list) => list !== 'no-list'),
      limits,
      customFilters,
      selectedAll,
      ownerId,
      savePhoneNumbers: isSavePhoneNumbers,
      onSuccess: () => {
        if (needToProcess) {
          onSuccessSaveInsight('info', `${upperCaseFirst(getPluralSource())} save in progress`);
        } else {
          onSuccessSaveInsight('success', `${upperCaseFirst(getPluralSource())} saved`);
        }
      },
    };
    if (tourName) data.isDemo = true;
    if (isExport) {
      data.exportData = {
        includeProperties,
        fileFormat,
        filters: [],
        sourceType,
      };
    }
    dispatch(createInsightsSearch(data));
    if (openSequence) {
      history.push(`/sequences/${sequenceId}`);
    }
  }, undefined, total);

  useEffect(() => {
    if (tourName) {
      const existDemoList = lists?.data?.find((item) => item.name === 'Demo list');

      if (!existDemoList) {
        dispatch(createList({ name: 'Demo list', dynamic: false }, (createdList) => {
          setSelectedList([createdList?._id]);
        }));
      } else setSelectedList([existDemoList?._id]);
    }
  }, [tourName, dispatch, lists]);

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          {`Save ${getPluralSource()}`}
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      width={640}
      closable={false}
      footer={false}
      onCancel={handleClose}
    >
      <BodyContainer>
        <Flex gap={10} direction="column">
          {
            actions.map((action) => (
              <Action
                key={action.title}
                title={action.title}
                icon={action.icon}
                active={activeAction === action.title}
                checked={action.checked}
                onClick={() => {
                  setActiveAction(action.title);
                  if (action.title !== 'Add to list' && !selectedLists.length) {
                    setIsAddToList(false);
                  }
                  if (action.title !== 'Add to sequence' && !sequenceId) {
                    setIsAddToSequence(false);
                  }
                }}
                onCheck={() => {
                  if (action.title === 'Phone numbers') {
                    if (isPaidPhoneSubscription && phoneCredits.current > 0) {
                      setIsSavePhoneNumbers(!isSavePhoneNumbers);
                    }
                  } else if (action.title === 'Export') {
                    setIsExport(!isExport);
                  } else if (action.title === 'Add to list') {
                    setIsAddToList(!isAddToList);
                    if (isAddToList) {
                      setSelectedList([]);
                    }
                  } else if (action.title === 'Add to sequence') {
                    setIsAddToSequence(!isAddToSequence);
                    if (isAddToSequence) {
                      setSequenceId();
                    }
                  }
                }}
              />
            ))
          }
        </Flex>
        <Content />
      </BodyContainer>
      <Footer>
        <PrimaryButton
          data-form="true"
          size="large"
          type="primary"
          onClick={isDemoUser ? handleSaveDemoClick : handleSaveClick}
          disabled={!total || loading}
          loading={!isDemoUser && loading}
          data-tour="save-leads-tour-step-4"
        >
          Confirm
        </PrimaryButton>
        <SecondaryButton size="large" type="normal" onClick={handleClose}>Cancel</SecondaryButton>
      </Footer>
    </Modal>
  );
};

export default SaveFromSearchModal;
