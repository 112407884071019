import styled, { css } from 'styled-components';
import {
  Button,
  Checkbox,
  Icon,
  Input as InputA,
  Modal,
  Radio,
  Tabs,
  Tag,
  Select,
  AutoComplete,
  InputNumber,
} from 'antd';

import * as CONSTANTS from 'constants/styles';
import CommonIcon from 'components/common/icon';

const { ComponentsConfig } = CONSTANTS;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0 15px;
  flex-direction: column;
  margin-top: ${(props) => (props.marginTop || CONSTANTS.CONTENT_MARGIN_TOP)}px;
  box-sizing: border-box;
  ${(props) => !props.center && `
    max-width: calc(100vw - ${CONSTANTS.CONTENT_MARGIN_LEFT}px);
    margin-left: ${CONSTANTS.CONTENT_MARGIN_LEFT}px;
  `}

  @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    ${(props) => !props.center && `
      margin-left: 0;
    `}
    margin-top: ${(props) => (props.marginTopMobile || 140)}px;
    padding: 0 15px;
    max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px;
  }
  
  .ant-table-fixed {
    border-top: none !important;
    border-radius: 0;
  }

  ${(props) => props.centerIfTablet && `
    @media (max-width: ${CONSTANTS.TABLET_MAX_WIDTH}px) {
      margin-left: 0;
      padding: 0 17px;
      max-width: 100%;
    }
  `};
`;

export const SearchWrapper = styled(Wrapper)`
  ${(props) => !props.center && `
    max-width: calc(100vw - ${CONSTANTS.CONTENT_SEARCH_MARGIN_LEFT}px);
    margin-left: ${CONSTANTS.CONTENT_SEARCH_MARGIN_LEFT}px;
  `}
`;

export const BtnText = styled.div`
  line-height: 22px;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const BackBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  color: ${CONSTANTS.COLORS.GREY_G2};
`;

export const backArrowStyle = {
  marginRight: '10px',
};

export const OptionBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 0 20px;
  height: ${ComponentsConfig.button.default.height}px;
  min-width: ${ComponentsConfig.button.default.minWidth}px;
  border-radius: ${ComponentsConfig.button.common.borderRadius}px !important;
  font-size: ${ComponentsConfig.input.default.fontSize}px !important;
  padding: 7px;
  span {
    text-align: center;
  }
`;

export const AntIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Label = styled.div`
  margin: 10px 0 5px 0;
  text-align: ${(props) => props.align || 'left'};
  font-weight: 500;
`;

export const SystemTabs = styled(Tabs)`
  .ant-tabs-bar { 
    margin: 0;
    height: 51px;
    
    > div {
      height: inherit !important;
    }
  }
  
  .ant-tabs-tab {
    min-width: 140px;
    min-height: 51px;
    text-align: center;
    background-color: rgba(223, 226, 229, 0.5) !important;
  }
  
  .ant-tabs-tab-active.ant-tabs-tab {
    background-color: ${CONSTANTS.COLORS.WHITE} !important;
  }
  
  .ant-tabs-card-bar .ant-tabs-tab {
    line-height: 50px !important;
    margin-right: 6px !important;
  }
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    .ant-tabs-nav-container-scrolling {
      padding: 0;
      
      .ant-tabs-tab-arrow-show {
        display: none;
      }
    }
    
    .ant-tabs-nav.ant-tabs-nav-animated {
      width: 100%;
    
     > div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      
       > div {
        flex: 1;
        min-width: inherit;
        
        :last-child {
          margin-right: 0 !important;
        }
       }
     }
    }
  }
`;

export const ValidationStatus = styled.div`
  display: ${(props) => (props.success || props.error ? 'block' : 'none')};
  width: max-content;
  text-align: left;
  color: ${(props) => (props.success ? CONSTANTS.COLORS.SUCCESS : CONSTANTS.COLORS.RED)};
`;

export const OptionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 20px 20px 0;
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    justify-content: center;
    padding: 25px 20px 5px 20px;
    flex-wrap: nowrap;
  
    > button {
      margin: 0 25px 0 0;
      max-width: 190px;
      flex: 1;
    }
    
    > span {
      flex: 1.1;
      max-width: 150px;
      
      > div {
        width: 100%;
        
        button {
          margin: 0;
          width: 100% !important;
          max-width: 130px;
        }
      }
    }
    
    ${OptionBtn} {
      width: auto;
    }
  }
`;

export const SystemModal = styled(Modal)`
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    margin-left: auto;
    margin-right: auto;
    
    .ant-modal-body {
      padding: 20px;
    }
  }
`;

export const TabWrapper = styled.div`
  background-color: #fff;
  font-family: AvenirNext;
  color: ${CONSTANTS.COLORS.BLACK};
  
  .ant-table-wrapper {
    border-left: none;
  }
  
  @media(max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
    .ant-table-content {
      min-width: 100% !important;
    }
  }
`;

export const LinkUnderline = styled.a`
  font-weight: ${(props) => props.fontWeight || 'normal'};
  text-decoration: ${(props) => (props.underlined ? 'underline' : 'normal')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    span:first-child {
      text-decoration: underline;
    }
  }
`;

export const IconRotateArrow = styled(CommonIcon).attrs((props) => {
  if (props.ignoreType) {
    return {
      type: 'ic-expand-more',
      from: props.from ?? -90,
      to: props.to ?? 0,
    };
  }
  return {
    fill: props.type === 'default' && !props.isActive ? CONSTANTS.COLORS.GREY_G3 : CONSTANTS.COLORS.PRIMARY,
    type: 'ic-expand-more',
    from: props.from ?? -90,
    to: props.to ?? 0,
  };
})`
  transition: transform 0.25s;
  transform: rotate(${(props) => (props.isActive ? props.to : props.from)}deg);
`;

const primaryStyles = css`
  color: ${CONSTANTS.COLORS.WHITE};
  background-color: ${CONSTANTS.COLORS.PRIMARY};
  border: 1px solid transparent;

  &:focus {
    background-color: ${CONSTANTS.COLORS.PRIMARY};
    border: 1px solid transparent;
  }
`;

const warningStyles = css`
  color: ${CONSTANTS.COLORS.WHITE};
  background-color: ${CONSTANTS.COLORS.YELLOW_Y1};
  border: 1px solid transparent;

  &:hover {
    color: ${CONSTANTS.COLORS.WHITE} !important;
    background: ${CONSTANTS.COLORS.YELLOW_Y1} !important;
    border: 1px solid transparent;
    opacity: 0.95;
  }
`;

const defaultStyles = css`
  background-color: ${CONSTANTS.COLORS.WHITE};
  color: ${CONSTANTS.COLORS.BLACK_WITH_OPACITY};
  border: 1px solid ${CONSTANTS.COLORS.BLACK_WITH_OPACITY};

  &:hover {
    color: ${CONSTANTS.COLORS.PRIMARY};
    border: 1px solid ${CONSTANTS.COLORS.PRIMARY};
  }
`;

const normalStyles = css`
  background-color: ${CONSTANTS.COLORS.WHITE};
  color: ${CONSTANTS.COLORS.BLACK_WITH_OPACITY};
  border: 1px solid ${CONSTANTS.COLORS.GREY_G8};

  &:hover {
    color: ${CONSTANTS.COLORS.PRIMARY};
    border: 1px solid ${CONSTANTS.COLORS.PRIMARY};

    ${(props) => (props.highlightIcons && `
      .material-icons {
        color: ${CONSTANTS.COLORS.PRIMARY} !important;
      }
    `)}
  }
`;

const dangerStyles = css`
  color: ${CONSTANTS.COLORS.RED} !important;
  background-color: ${CONSTANTS.COLORS.WHITE} !important;
  border: 1px solid ${CONSTANTS.COLORS.RED} !important;
  border-color: ${CONSTANTS.COLORS.RED} !important;
  text-shadow: none;

  &:hover {
    color: ${CONSTANTS.COLORS.WHITE} !important;
    background: ${CONSTANTS.COLORS.RED} !important;
  }
`;

const customStyles = css`
  background-color: ${(props) => (props.backgroundColor ?? CONSTANTS.COLORS.WHITE)};
  color: ${(props) => (props.color ?? CONSTANTS.COLORS.BLACK_WITH_OPACITY)};
  border: 1px solid ${(props) => (props.borderColor ?? CONSTANTS.COLORS.GREY_G8)};

  &:hover, &:focus {
    color: ${(props) => (props.hoverColor ?? CONSTANTS.COLORS.PRIMARY)};
    border: 1px solid ${(props) => (props.hoverBorderColor ?? CONSTANTS.COLORS.PRIMARY)};
  }
`;

const dangerTagStyles = css`
  color: ${CONSTANTS.COLORS.WHITE};
  background-color: ${CONSTANTS.COLORS.RED};
  border: 1px solid transparent;
`;

const normalTagStyles = css`
  color: ${CONSTANTS.COLORS.GREY_G1};
  font-weight: 500;
  background-color: ${CONSTANTS.COLORS.GREY_G14};
  border: 1px solid transparent;
`;

export const CustomButton = styled(Button)`
  height: ${(props) => ComponentsConfig.button[props.size].height}px;
  min-width: ${(props) => ComponentsConfig.button[props.size].minWidth}px;
  font-size: ${(props) => ComponentsConfig.button[props.size].fontSize}px !important;
  font-weight: ${ComponentsConfig.button.common.fontWeight} !important;
  border-radius: ${ComponentsConfig.button.common.borderRadius}px !important;

  ${(props) => {
    switch (props.type) {
      case 'danger':
        return dangerStyles;
      case 'primary':
        return primaryStyles;
      case 'normal':
        return normalStyles;
      case 'warning':
        return warningStyles;
      case 'custom':
        return customStyles;
      default:
        return defaultStyles;
    }
  }}

  :disabled {
    border: none;

    :hover {
      color: ${CONSTANTS.COLORS.BLACK_WITH_OPACITY_B2} !important;
      background-color: ${CONSTANTS.COLORS.GREY_DISABLED} !important;
    }
  }
`;

export const CustomInput = styled(InputA)`
  height: ${(props) => ComponentsConfig.input[props.size].height}px;
  min-width: ${(props) => ComponentsConfig.input[props.size].minWidth}px;
  border-radius: ${ComponentsConfig.input.common.borderRadius}px;
  font-size: ${(props) => ComponentsConfig.input[props.size].fontSize}px !important;
  color: ${CONSTANTS.COLORS.BLACK_G1};

  input {
    height: ${(props) => ComponentsConfig.input[props.size].height}px;
  }
  

  ${(props) => props.error && `
    border-color: ${CONSTANTS.COLORS.RED};
    box-shadow: 0 0 0 2px ${CONSTANTS.COLORS.RED_WITH_OPACITY};
  `}

  ${(props) => props.success && `
    border-color: ${CONSTANTS.COLORS.SUCCESS};
  `}
`;

export const CustomAutoComplete = styled(AutoComplete)`
  height: ${(props) => ComponentsConfig.input[props.size].height}px;
  min-width: ${(props) => ComponentsConfig.input[props.size].minWidth}px;
  border-radius: ${ComponentsConfig.input.common.borderRadius}px;
  font-size: ${(props) => ComponentsConfig.input[props.size].fontSize}px !important;

  input {
    height: ${(props) => ComponentsConfig.input[props.size].height}px !important;
    color: ${CONSTANTS.COLORS.BLACK_G1};
  }

  .ant-select-search__field__mirror, .ant-select-selection__rendered  {
    line-height: ${(props) => ComponentsConfig.input[props.size].height}px !important;
  }

  ${(props) => props.error && `
    border-color: ${CONSTANTS.COLORS.RED};
    box-shadow: 0 0 0 2px ${CONSTANTS.COLORS.RED_WITH_OPACITY};
  `}

  ${(props) => props.success && `
    border-color: ${CONSTANTS.COLORS.SUCCESS};
  `}
`;

export const CustomSelect = styled(Select)`
  min-width: ${(props) => ComponentsConfig.select[props.size].minWidth}px;
  border-radius: ${ComponentsConfig.select.common.borderRadius}px;
  font-size: ${(props) => ComponentsConfig.select[props.size].fontSize}px !important;
  width: 100%;

  .ant-select-selection {
    height: ${(props) => ComponentsConfig.select[props.size].height}px;

    ${(props) => props.error && `
      border: 1px solid ${CONSTANTS.COLORS.RED} !important;
      box-shadow: 0 0 0 2px ${CONSTANTS.COLORS.RED_WITH_OPACITY};
    `}

    ${(props) => props.success && `
      border: 1px solid ${CONSTANTS.COLORS.SUCCESS} !important;
    `}

    .ant-select-selection__rendered {
      line-height: ${(props) => ComponentsConfig.select[props.size].height - 2}px;
      margin-right: 29px;

      li {
        height: ${(props) => ComponentsConfig.tag[props.size].height}px;
        line-height: ${(props) => ComponentsConfig.tag[props.size].height}px;
        margin-top: ${(props) => (props.size === 'large' ? 7 : 5)}px;
      }
    }

    .ant-select-selection-selected-value {
      color: ${CONSTANTS.COLORS.BLACK_G1};

      .hidden-selected {
        display: none;
      }
    }

    .ant-select-arrow {
      top: ${(props) => ComponentsConfig.select[props.size].top || 40}%;
    }

    .ant-select-selection__clear {
      right: ${(props) => (props.size === 'large' ? 15 : 17)}px;
    }

    .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
      color: ${CONSTANTS.COLORS.GREY_G2};
    }
  }
`;

export const CustomSearch = styled(InputA.Search)`
  height: ${(props) => ComponentsConfig.input[props.size].height}px;
  border-radius: ${ComponentsConfig.input.common.borderRadius}px;
  min-width: ${(props) => ComponentsConfig.input[props.size].minWidth}px;

  .ant-input {
    font-size: ${(props) => ComponentsConfig.input[props.size].fontSize}px !important;
  }

  ${(props) => props.hidesearchicon && `
    .ant-input-search-icon {
      display: none;
    }
  `}

  .anticon-loading {
    color: ${CONSTANTS.COLORS.PRIMARY}
  }
`;

export const CustomPassword = styled(InputA.Password)`
  height: ${(props) => ComponentsConfig.input[props.size].height}px;
  border-radius: ${ComponentsConfig.input.common.borderRadius}px;
  min-width: ${(props) => ComponentsConfig.input[props.size].minWidth}px;
  font-size: ${(props) => ComponentsConfig.input[props.size].fontSize}px;

  .ant-input {
    font-size: ${(props) => ComponentsConfig.input[props.size].fontSize}px !important;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox + span {
    /* display: inline-block; */
    padding-left: 8px;
    padding-right: 0;
    font-weight: ${ComponentsConfig.checkbox.common.fontWeight} !important;
    font-size: ${(props) => ComponentsConfig.checkbox[props?.size ?? 'common']?.fontSize ?? 14}px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border: 2px solid ${CONSTANTS.COLORS.PRIMARY};

      ${(props) => (props.size === 'large' ? `
        &::after {
          width: 7px;
          height: 15px;
          top: 40%;
          left: 2px;
        }
      ` : `&::after {
        left: 2px;
      }`)}
    }
  }

  .ant-checkbox-inner {
    height: ${(props) => ComponentsConfig.checkbox[props.size].height}px;
    width: ${(props) => ComponentsConfig.checkbox[props.size].width}px;
  }
`;

export const CustomRadio = styled(Radio)`
  .ant-radio + span {
    padding-left: 8px;
    padding-right: 0;
    font-weight: ${ComponentsConfig.checkbox.common.fontWeight} !important;
  }

  .ant-radio-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => ComponentsConfig.radio[props.size].height}px;
    width: ${(props) => ComponentsConfig.radio[props.size].width}px;

    &::after {
      left: auto;
      top: auto;
      height: ${(props) => (props.size === 'large' ? 12 : 8)}px;
      width: ${(props) => (props.size === 'large' ? 12 : 8)}px;
    }
  }
`;

export const CustomTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  border-radius: ${ComponentsConfig.tag.common.borderRadius}px;
  height: ${(props) => ComponentsConfig.tag[props.size].height}px;

  .ant-tag {
    margin-right: 0;
  }

  ${(props) => {
    switch (props.type) {
      case 'danger':
        return dangerTagStyles;
      case 'primary':
        return primaryStyles;
      default:
        return normalTagStyles;
    }
  }}
`;

export const NotificationButton = styled(Button)`
  height: 30px;
  min-width: 105px;
  margin-top: 3px;
  padding: 0;
  font-weight: 600;
  font-size: 12px;
`;

export const NextStepButton = styled(NotificationButton)`
  min-width: 75px;
  height: 32px;
  margin-top: 0;
`;
export const SignUpButton = styled(NotificationButton)`
  margin-top: 0;
  height: 32px;
  min-width: 75px;
`;

export const AnimatedIcon = styled(CommonIcon)`
  transition: all 0.1s;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: #e8ebed;
  }
  &:active {
    transform: scale(0.9);
    background: #f5f7f7;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const EllipsisSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CustomInputNumber = styled(InputNumber)`
  height: ${(props) => ComponentsConfig.input[props.size].height}px;
  min-width: ${(props) => ComponentsConfig.input[props.size].minWidth}px;
  border-radius: ${ComponentsConfig.input.common.borderRadius}px;
  font-size: ${(props) => ComponentsConfig.input[props.size].fontSize}px !important;

  input {
    height: ${(props) => ComponentsConfig.input[props.size].height}px;
  }

  ${(props) => props.error && `
    border-color: ${CONSTANTS.COLORS.RED};
    box-shadow: 0 0 0 2px ${CONSTANTS.COLORS.RED_WITH_OPACITY};
  `}

  ${(props) => props.success && `
    border-color: ${CONSTANTS.COLORS.SUCCESS};
  `}
`;

export const Badge = styled.div`
  background-color: ${CONSTANTS.COLORS.WHITE};
  border-radius: 4px;
  padding: ${(props) => ComponentsConfig.badge[props?.size ?? 'common'].padding};
  color: ${CONSTANTS.COLORS.WHITE};
  height: ${(props) => ComponentsConfig.badge[props?.size ?? 'common'].height}px;
  font-size: ${(props) => ComponentsConfig.badge[props?.size ?? 'common'].fontSize}px;
  display: flex;
  align-items: center;
  line-height: 1.2;
  transition: all 0.5s;
  color: ${CONSTANTS.COLORS.PRIMARY};
  border: 1px solid ${CONSTANTS.COLORS.PRIMARY};
`;

export const cursorMixin = css`
  cursor: ${(props) => {
    if (props.disabled) return 'not-allowed';
    if (props.loading) return 'progress';
    return 'pointer';
  }}
`;

export const InfoLink = styled.div`
  display: inline;
  transition: all .15s ease-out;
  color: ${CONSTANTS.COLORS.PRIMARY};
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CustomDropdownWrapper = styled.div`
  .ant-select-dropdown-menu {
    .ant-select-dropdown-menu-item-active:not(.saved-list):not(.ant-select-dropdown-menu-item-disabled) {
      color: ${CONSTANTS.COLORS.PRIMARY};
    }
  }
`;
