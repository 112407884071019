import React from 'react';

import { Text as BaseText } from './styles';

const Text = () => (
  <BaseText>
    Saving new contacts&nbsp;
    <b>costs 1 email credit</b>
    &nbsp;per contact with email. No credits are charged for adding previously saved contacts.
  </BaseText>
);

export default Text;
