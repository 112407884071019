import React, { forwardRef } from 'react';

import { CustomButton as Button } from 'components/commonStyles';

const CustomButton = forwardRef(({ size = 'large', highlightIcons = true, ...props }, ref) => (
  <Button ref={ref} size={size} highlightIcons={highlightIcons} {...props} /> // eslint-disable-line
));

CustomButton.__ANT_BUTTON = true;

export default CustomButton;
