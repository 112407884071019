const initialState = {
  type: '',
  visible: false,
  options: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL': {
      const { type, value, options } = action.data;
      return {
        ...state,
        visible: value,
        type: value ? type : '',
        options: value && options ? options : {},
      };
    }
    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return {
        type: '',
        visible: false,
        options: {},
      };
    }
    default:
      return state;
  }
};
