import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';

import {
  Option, Radio, RadioGroup, Select,
} from 'components/common';
import { toggleModal } from 'data/actions/modals';
import {
  ButtonEditConfig, CustomConfig, CustomConfigContainer, RadioStyle, SelectLabel, SelectLabel2,
} from './styles';

const options = ['CSV', 'XLSX'];

const ExportBody = ({
  format,
  setFormat,
  sourceType,
  includeProperties = 'columns_only',
  setIncludeProperties,
  showCustomConfiguration,
  hasBigLastGap = true,
  backModal,
  backOptions,
}) => {
  const dispatch = useDispatch();

  const openExportConfigurationEditor = useCallback(() => {
    dispatch(toggleModal('export-configuration-editor', true, {
      sourceType, includeProperties, backModal, backOptions,
    }));
  }, [dispatch, includeProperties, sourceType, backModal, backOptions]);

  return (
    <>
      <SelectLabel>
        File format
      </SelectLabel>
      <Select size="default" onChange={setFormat} value={format}>
        {options.map((item, index) => <Option key={`export-options-${index + 1}`} value={item.toLowerCase()}>{item}</Option>)}
      </Select>
      <br />
      <SelectLabel2>
        Properties
      </SelectLabel2>
      <RadioGroup
        onChange={(e) => setIncludeProperties(e.target.value)}
        value={includeProperties}
        style={{ width: '100%' }}
      >
        <Radio size="default" style={RadioStyle} value="columns_only">
          Include only properties in columns
        </Radio>
        <Radio size="default" style={RadioStyle} value="all">
          Include all properties
        </Radio>
        {showCustomConfiguration && (
          <Radio size="default" style={{ ...RadioStyle, marginBottom: hasBigLastGap ? 20 : 10 }} value="custom">
            <CustomConfigContainer>
              <CustomConfig>
                Custom configuration
                <ButtonEditConfig
                  onClick={openExportConfigurationEditor}
                >
                  Edit
                </ButtonEditConfig>
              </CustomConfig>
            </CustomConfigContainer>
          </Radio>
        )}
      </RadioGroup>
    </>
  );
};

export default ExportBody;
