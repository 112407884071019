import { useCallback } from 'react';
import queryString from 'query-string';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { searchFiltersSelector } from 'data/selectors/search';
import { SOURCE_TYPE } from 'data/types/source.types';
import { setSelectedInsightContacts } from 'data/actions/contacts';
import { setSelectedInsightCompanies } from 'data/actions/companies';
import { toggleSearchCheckAll } from 'data/actions/search';

const useResetParams = (action, path) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const store = useStore();

  const resetParams = useCallback((obj) => {
    if (action) dispatch(action(obj));
    let finalDestination = location.pathname;
    const isIncludeTourSearch = location.search.includes('tour');

    if (isIncludeTourSearch) {
      finalDestination += location.search;
    }

    if (/\/search\/(contact|company)/.test(finalDestination)) {
      const [, , sourcePath] = finalDestination.split('/');
      const setSelectedRowKeys = sourcePath === SOURCE_TYPE.CONTACT ? setSelectedInsightContacts : setSelectedInsightCompanies;
      dispatch(setSelectedRowKeys([]));
      dispatch(toggleSearchCheckAll(false));
    }

    if (/\/search\/(contact|company)\/.*/.test(finalDestination) && !isIncludeTourSearch) {
      const [, , sourcePath] = finalDestination.split('/');
      finalDestination = `/search/${sourcePath}`;
    }

    const state = store.getState();
    const filters = searchFiltersSelector(state);
    const isEmptySearch = filters.filter((filter) => !['email', 'workspaceRelations'].includes(filter.property)).length === 0;

    if (isEmptySearch) {
      finalDestination += '/activity';
    }

    const parsedSearch = queryString.parse(location.search);

    if (parsedSearch.existed) {
      delete parsedSearch.existed;
      finalDestination += queryString.stringify(parsedSearch);
    }

    if (path) {
      finalDestination = path;
    }

    history.push(finalDestination);
  }, [dispatch, action, location, path, store, history]);

  return resetParams;
};

export default useResetParams;
