import React, { useState, useEffect, useMemo } from 'react';
import {
  Modal,
} from 'antd';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
} from 'components/common';
import { toggleModal } from 'data/actions/modals';
import { renameLabel } from 'helpers/renameLabel';
import { toggleMessage } from 'data/actions/message';
import { SOURCE_TYPE } from 'data/types/source.types';
import { searchContacts } from 'data/actions/contacts';
import { updateWorkspaceSettings } from 'data/actions/user';
import { propertiesSelector } from 'data/selectors/properties';
import { updateSequenceSettings } from 'data/actions/sequences';
import { columnsConfigurationSelector } from 'data/selectors/user';
import { engagedAudienceColumnsSelector } from 'data/selectors/sequences';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';

import {
  Footer,
  ModalWrapper,
} from './styles';

import {
  ModalTitle,
  CloseIcon,
} from '../modals/baseModal/styles';

import PropertiesContent from './propertiesContent';

const excludeCompanyFields = ['technologies', 'keywords'];
const excludedFields = ['Company technologies', 'Company keywords', 'firstName', 'lastName', 'name', 'Associated Company Linkedin', 'Keywords', 'Technologies', 'Associated Company Keywords', 'Associated Company Technologies'];
const excludedFieldsForSequence = [...excludedFields, 'Description', 'Postal Code', 'Technologies', 'Name', 'Linkedin'];

const PropertyListEditor = () => {
  const dispatch = useDispatch();

  const properties = useSelector(propertiesSelector);
  const currentColumns = useSelector(columnsConfigurationSelector);
  const sequenceColumns = useSelector(engagedAudienceColumnsSelector);

  const { visible } = useSelector(modalsSelector);
  const { isSequence, maxColumns, sourceType = SOURCE_TYPE.CONTACT } = useSelector(modalOptionsSelector);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const configurationSourceType = sourceType === SOURCE_TYPE.CONTACT ? 'contacts' : 'companies';

  const fullProperties = useMemo(() => {
    const companyPropertiesForContacts = sourceType === SOURCE_TYPE.CONTACT ? properties.filter((property) => property.sourceType === SOURCE_TYPE.COMPANY).map((property) => ({
      _id: property._id,
      label: property.label.startsWith('Company') ? property.label : `Company ${property.label}`,
      name: `associated_company_${property.name}`,
      sourceType: SOURCE_TYPE.COMPANY,
      group: {
        name: 'Company properties',
      },
      virtualRequired: !excludeCompanyFields.includes(property.name),
      default: property?.default,
    })) : [];
    const virtualProperties = isSequence ? [
      {
        label: 'Position',
        name: 'position',
        sourceType: SOURCE_TYPE.COMPANY,
      },
    ] : [
      {
        label: 'Owner',
        name: 'owner',
        sourceType: SOURCE_TYPE.CONTACT,
      },
      {
        label: 'Owner',
        name: 'owner',
        sourceType: SOURCE_TYPE.COMPANY,
      },
    ];
    return [...properties, ...companyPropertiesForContacts, ...virtualProperties];
  }, [sourceType, properties, isSequence]);

  const handleClose = () => dispatch(toggleModal('edit_columns', false));

  const isDefaultColumn = (name) => ['name', 'actions'].includes(name);

  const hideColumns = () => setSelectedColumns(selectedColumns.map((column) => ({ ...column, hidden: !isDefaultColumn(column.name) })));

  useEffect(() => {
    const filteredProperties = fullProperties.filter((item) => ((sourceType === item.sourceType && !item.hidden) || item.virtualRequired) && !(isSequence ? excludedFieldsForSequence : excludedFields).includes(renameLabel(item.name)));
    let visibleColumns = [];
    if (isSequence) {
      visibleColumns = sequenceColumns.map((column) => ({ name: column, hidden: false }));
    } else if (currentColumns && currentColumns[configurationSourceType]) {
      visibleColumns = currentColumns[configurationSourceType].map((item) => ({ name: get(item, 'propertyName'), hidden: false }));
    }
    const nonVisibleColumns = filteredProperties.filter((property) => {
      const hasColumn = visibleColumns.find((column) => column.name === property.name);
      return !hasColumn;
    }).map((property) => ({ name: property.name, hidden: true }));
    const _selectedColumns = [
      ...visibleColumns,
      ...nonVisibleColumns,
    ];
    setSelectedColumns(_selectedColumns);
  }, [isSequence, sequenceColumns, currentColumns, sourceType, configurationSourceType, fullProperties]);

  const getPropertyLabel = (propertyName) => get(fullProperties.find((prop) => prop.name === propertyName), 'label', startCase(propertyName));
  const getPropertyIsDefault = (propertyName) => get(fullProperties.find((prop) => prop.name === propertyName), 'default');

  const getUpdatedRequest = () => {
    const columnsConfiguration = {
      ...currentColumns,
      [configurationSourceType]: selectedColumns.filter((item) => !item.hidden).map((item) => ({ propertyName: item.name })),
    };
    return { columnsConfiguration };
  };

  const updateColumns = (column, value) => {
    const updatedColumns = [...selectedColumns].map((item) => {
      if (item.name === column) {
        return {
          ...item,
          hidden: value,
        };
      }
      return item;
    });
    const countVisibleColumns = updatedColumns.filter((item) => !item.hidden).length;
    if (maxColumns && countVisibleColumns > maxColumns) {
      dispatch(toggleMessage('error', { header: `You can't choose more than ${maxColumns}` }));
    } else {
      setSelectedColumns(updatedColumns);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    const newSelectedColumns = [...selectedColumns];

    if (!destination) return;
    if ((destination.droppableId === source.droppableId && destination.index === source.index) || destination.index === 0) return;

    newSelectedColumns.splice(source.index, 1);
    newSelectedColumns.splice(destination.index, 0, selectedColumns[source.index]);
    setSelectedColumns(newSelectedColumns);
  };

  return (
    <ModalWrapper>
      <Modal
        visible={visible}
        title={(
          <ModalTitle>
            Displayed columns
            <CloseIcon
              onClick={handleClose}
              className="ic_close"
            />
          </ModalTitle>
        )}
        width={350}
        footer={false}
        bodyStyle={{ padding: '20px 0px' }}
        closable={false}
        onCancel={handleClose}
      >
        <PropertiesContent
          onDragEnd={onDragEnd}
          hideColumns={hideColumns}
          updateColumns={updateColumns}
          isDefaultColumn={isDefaultColumn}
          selectedColumns={selectedColumns}
          getPropertyLabel={getPropertyLabel}
          getPropertyIsDefault={getPropertyIsDefault}
        />
        <Footer>
          <Button
            type="primary"
            size="large"
            disabled={selectedColumns.every((column) => column.hidden)}
            onClick={() => {
              if (isSequence) {
                dispatch(updateSequenceSettings({ engagedAudienceColumns: selectedColumns.filter((column) => !column.hidden).map((item) => item.name) }));
              } else {
                dispatch(updateWorkspaceSettings(
                  getUpdatedRequest(),
                  (data) => {
                    dispatch(searchContacts(data));
                  },
                ));
              }
              handleClose();
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={handleClose}
            type="normal"
            size="large"
          >
            Cancel
          </Button>
        </Footer>
      </Modal>
    </ModalWrapper>
  );
};

export default PropertyListEditor;
