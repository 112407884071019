import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@newlab-io/getprospect-react-components/lib/ui';

import useFetch from 'hooks/useFetch';
import { getFullName } from 'helpers/getFullName';
import { Option, Select } from 'components/common';
import { accountSelector } from 'data/selectors/user';
import { getAllTeammates } from 'data/actions/teammates';
import { allTeammatesSelector } from 'data/selectors/teammates';
import Text from '../text';

const AssignOwner = ({
  owner,
  onAssign,
}) => {
  const account = useSelector(accountSelector);

  const {
    data: teammates,
  } = useFetch({
    selector: allTeammatesSelector,
    action: getAllTeammates,
  });
  const teammatesAndOwner = useMemo(() => [
    account,
    ...teammates,
  ], [teammates, account]);

  return (
    <Flex gap={12} direction="column">
      <Text />
      <Select
        size="default"
        value={owner}
        onChange={onAssign}
      >
        {
          teammatesAndOwner.map((teammate) => (
            <Option key={teammate._id} value={teammate._id}>{`${getFullName(teammate)} ${teammate._id === account._id ? ' (You)' : ''}`}</Option>
          ))
        }
      </Select>
    </Flex>
  );
};

export default AssignOwner;
