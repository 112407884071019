import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const Text = styled.span`
  color: ${COLORS.GREY_G1};
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;

  b {
    color: ${COLORS.BLACK_G1};
  }
`;
