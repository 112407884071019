import React from 'react';

import { Checkbox, Icon } from 'components/common';
import { Container, LabelContainer, Label } from './styles';

const Action = ({
  title,
  icon,
  active,
  checked,
  onClick,
  onCheck,
}) => (
  <Container active={active} onClick={onClick}>
    <LabelContainer>
      <Icon size="small" type={icon} />
      <Label>{title}</Label>
    </LabelContainer>
    <Checkbox
      size="small"
      checked={checked}
      onChange={onCheck}
    />
  </Container>
);

export default Action;
