import moment from 'moment';
import React, { useState } from 'react';
import { Calendar, Popover } from 'antd';

import { Button, Icon } from 'components/common';
import {
  Container,
  InputView,
  Footer,
  Value,
  DifferenceLabel,
  DifferenceValue,
  Cell,
  ActiveCell,
  MonthContainer,
  MonthLabel,
  CalendarContainer,
  LastDays,
  LastDaysContainer,
  LastDaysHeader,
  BetweenCell,
  DisabledCell,
} from './styles';

const RangeCalendar = ({
  defaultStartDate,
  defaultEndDate,
  style,
  onChange,
  lastDays = [7, 14, 30, 60],
  // object of countDays and name
  customLastDays,
  placement = 'bottomRight',
}) => {
  const [visible, setVisible] = useState(false);
  const [month, setMonth] = useState(moment().utc().format('MMMM'));
  const [value, setValue] = useState(defaultStartDate ? [moment(defaultStartDate).utc(), moment(defaultEndDate).utc()] : []);

  const maxLastDays = Math.max(...lastDays);

  let difference;
  let placeholder = 'Select dates';
  if (value.length === 2) {
    const [startDate, endDate] = value;
    difference = moment(endDate).utc().diff(startDate, 'days') + 1;
    placeholder = `${startDate.format('D MMM')} - ${endDate.format('D MMM')}`;
  }

  const handleChange = (date) => {
    const [startDate, endDate] = value;
    if (!startDate || (startDate && endDate)) {
      setValue([date.utc().startOf('day')]);
    } else {
      const rationalDifference = moment(startDate).diff(date, 'days');
      if (rationalDifference > 0) {
        setValue([date.utc().startOf('day'), startDate.utc().endOf('day')]);
      } else {
        setValue([startDate.utc().startOf('day'), date.utc().endOf('day')]);
      }
    }
  };

  const handleApply = (countDays, isCustom) => {
    setVisible(false);
    const result = countDays ?? value;
    if (result.length === 2) {
      onChange(result, isCustom);
    } else {
      const [firstDay] = result;
      const startDate = firstDay.startOf('day');
      const endDate = firstDay.endOf('day');
      const newResult = [startDate, endDate];
      setValue(newResult);
      onChange(newResult, isCustom);
    }
  };

  return (
    <Popover
      visible={visible}
      onVisibleChange={setVisible}
      trigger="click"
      placement={placement}
      overlayClassName="hide-popover-arrow"
      content={(
        <Container>
          <CalendarContainer>
            <Calendar
              headerRender={({ value: headerValue, onChange: onChangeCalendar }) => {
                const changeMonth = (isPrev) => {
                  const newValue = headerValue.clone();
                  if (isPrev) {
                    newValue.subtract(1, 'month');
                  } else {
                    newValue.add(1, 'month');
                  }
                  onChangeCalendar(newValue);
                  setMonth(newValue.format('MMMM'));
                };
                return (
                  <MonthContainer>
                    <Icon onClick={() => changeMonth(true)} size="small" type="ic-keyboard-arrow-left" />
                    <MonthLabel>
                      {headerValue.format('MMMM YYYY')}
                    </MonthLabel>
                    <Icon onClick={() => changeMonth(false)} size="small" type="ic-keyboard-arrow-right" />
                  </MonthContainer>
                );
              }}
              dateFullCellRender={(date) => {
                const dateMonth = moment(date).format('MMMM');
                if (dateMonth !== month) return null;
                if (date.format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
                  const startDateAfter = date.clone().subtract(1, 'days');
                  return <DisabledCell first={startDateAfter.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')}>{date.format('D')}</DisabledCell>;
                }
                if (date.format('YYYY-MM-DD') < moment().subtract(maxLastDays, 'days').format('YYYY-MM-DD')) {
                  return <DisabledCell>{date.format('D')}</DisabledCell>;
                }
                const [startDate, endDate] = value;
                if (startDate && !endDate && startDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
                  return <ActiveCell onClick={() => handleChange(date)}>{date.format('D')}</ActiveCell>;
                }
                if (startDate && endDate) {
                  if (startDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD') && startDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
                    return <ActiveCell onClick={() => handleChange(date)}>{date.format('D')}</ActiveCell>;
                  }
                  if (difference === 1) {
                    if (startDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
                      return <ActiveCell first firstLong onClick={() => handleChange(date)}>{date.format('D')}</ActiveCell>;
                    }
                    if (endDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
                      return <ActiveCell last lastLong onClick={() => handleChange(date)}>{date.format('D')}</ActiveCell>;
                    }
                  }
                } if (startDate && startDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
                  return <ActiveCell first onClick={() => handleChange(date)}>{date.format('D')}</ActiveCell>;
                } if (endDate && endDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
                  return <ActiveCell last onClick={() => handleChange(date)}>{date.format('D')}</ActiveCell>;
                } if (value.length === 2 && date.isBetween(startDate, endDate)) {
                  const startDateAfter = date.clone().subtract(1, 'days');
                  const endDateBefore = date.clone().add(1, 'days');
                  return (
                    <BetweenCell
                      first={startDateAfter.format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')}
                      last={endDateBefore.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')}
                      onClick={() => handleChange(date)}
                    >
                      {date.format('D')}
                    </BetweenCell>
                  );
                }
                return <Cell onClick={() => handleChange(date)}>{date.format('D')}</Cell>;
              }}
              fullscreen={false}
            />
            <LastDaysContainer>
              <LastDaysHeader>Preferred days</LastDaysHeader>
              {
                customLastDays && (
                  <LastDays
                    onClick={() => {
                      const formattedCountDays = [moment(customLastDays.date).utc(), moment().utc().endOf('days')];
                      setValue(formattedCountDays);
                      handleApply(formattedCountDays, true);
                    }}
                    key={customLastDays.date}
                  >
                    {customLastDays.name}
                  </LastDays>
                )
              }
              {
                lastDays.map((countDays) => (
                  <LastDays
                    onClick={() => {
                      const formattedCountDays = [moment().utc().subtract(countDays - 1, 'days').startOf('days'), moment().utc().endOf('days')];
                      setValue(formattedCountDays);
                      handleApply(formattedCountDays);
                    }}
                    key={countDays}
                  >
                    {`Last ${countDays} days`}
                  </LastDays>
                ))
              }
            </LastDaysContainer>
          </CalendarContainer>
          <Footer>
            <Button
              onClick={() => {
                setVisible(false);
                setValue(defaultStartDate ? [moment(defaultStartDate), moment(defaultEndDate)] : []);
              }}
              size="default"
              type="normal"
            >
              Cancel
            </Button>
            <div>
              {
                difference ? (
                  <>
                    <DifferenceLabel>Selected:</DifferenceLabel>
                    &nbsp;
                    <DifferenceValue>{`${difference} days`}</DifferenceValue>
                  </>
                ) : null
              }
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => handleApply()}
                size="default"
                type="primary"
              >
                Apply
              </Button>
            </div>
          </Footer>
        </Container>
      )}
    >
      <InputView style={style}>
        <Value>
          {placeholder}
        </Value>
        <Icon size="small" type="ic-calendar-today" />
      </InputView>
    </Popover>
  );
};

export default RangeCalendar;
