import moment from 'moment';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { PHONE_PLAN_IDS, PLAN_IDS } from 'data/types/subscription.types';

const baseSubscriptionsSelector = (state) => get(state, 'subscriptions.subscription', {});
export const subscriptionsSelector = createSelector(
  baseSubscriptionsSelector,
  (subscription) => subscription,
);

export const isOnlyVerificationsSelector = createSelector(
  baseSubscriptionsSelector,
  (subscription) => get(subscription, 'type') === 'verifications',
);

const baseSubscriptionSelector = (state) => get(state, 'subscriptions', {});
export const redirectAfterFirstPaySelector = createSelector(
  baseSubscriptionSelector,
  (subscription) => get(subscription, 'redirectAfterFirstPay', false),
);

export const fastSpringDataSelector = createSelector(
  baseSubscriptionSelector,
  (subscription) => get(subscription, 'fastSpringData', {}),
);

export const currentPlanSelector = createSelector(
  subscriptionsSelector,
  (subscription) => subscription.plan,
);

export const currentPhonePlanSelector = createSelector(
  subscriptionsSelector,
  (subscription) => subscription.phoneSearchPlan,
);

export const isPaidSubscriptionSelector = createSelector(
  currentPlanSelector,
  (plan) => ![PLAN_IDS.FREE, PLAN_IDS.LIMITED].includes(get(plan, 'planId')),
);

export const isPaidPhoneSubscriptionSelector = createSelector(
  currentPhonePlanSelector,
  (plan) => ![PHONE_PLAN_IDS.FREE].includes(get(plan, 'planId')),
);

export const currentBillingCycleSelector = createSelector(
  baseSubscriptionsSelector,
  (subscription) => get(subscription, 'billingCycle'),
);

export const cancelSubscriptionSelector = createSelector(
  baseSubscriptionSelector,
  (subscription) => get(subscription, 'cancelSubscription', {}),
);

export const isDisabledPauseSelector = createSelector(
  baseSubscriptionsSelector,
  (subscription) => subscription?.pauseData?.pauseSetAt && moment().diff(subscription?.pauseData?.pauseSetAt, 'months') < 3,
);

export const hasDiscountSelector = createSelector(
  currentPlanSelector,
  (plan) => get(plan, 'discount'),
);

export const nextPlanSelector = createSelector(
  baseSubscriptionsSelector,
  (subscription) => get(subscription, 'nextPlan'),
);

export const subscriptionQuantitySelector = createSelector(
  baseSubscriptionsSelector,
  (subscription) => get(subscription, 'subscriptionQuantity', 1),
);

export const isDiscountAvailableSelector = createSelector(
  hasDiscountSelector,
  nextPlanSelector,
  subscriptionQuantitySelector,
  (hasDiscount, hasNextPlan, subscriptionQuantity) => !hasNextPlan && !hasDiscount && (!subscriptionQuantity || subscriptionQuantity === 1),
);

export const selectSubscriptionQuantitySelector = createSelector(
  baseSubscriptionSelector,
  (subscription) => subscription?.selectSubscriptionQuantity || 1,
);
