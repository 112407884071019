import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  CheckboxGroup,
} from 'components/common';

import useResetParams from 'hooks/useResetParams';
import { replaceSearchFilter } from 'data/actions/search';
import { getFilterOptionsSelector, searchFiltersSelector } from 'data/selectors/search';
import { CheckboxGroupWrapper } from '../../styles';

const CheckboxFilter = ({ filter }) => {
  const dispatch = useDispatch();

  const changeFilter = useResetParams((newFilter) => dispatch(replaceSearchFilter(newFilter)));

  const optionsSelector = getFilterOptionsSelector(filter.property);
  const options = useSelector(optionsSelector);
  const filters = useSelector(searchFiltersSelector);

  const emailFilter = filters.find((item) => item.property === filter.property);
  const includedValues = get(emailFilter, 'included.value');

  const setValue = (items) => {
    const newFilter = { operator: filter.includedOp, property: filter.property };

    if (items.length) newFilter.value = items;
    else {
      const defaultOption = options.find((option) => option.default);

      if (defaultOption) {
        newFilter.value = [defaultOption.value];
      }
    }
    changeFilter(newFilter);
  };

  return (
    <CheckboxGroupWrapper>
      <CheckboxGroup
        value={includedValues}
        onChange={setValue}
      >
        {
          options && ('map' in options) ? options.filter((option) => !option?.default).map((option) => (
            <Checkbox key={`search_checkbox_filter_${option.value}`} value={option.value}>
              {option.label === 'With emails' ? `${option.label} only` : option.label}
            </Checkbox>
          )) : <></>
        }
      </CheckboxGroup>
    </CheckboxGroupWrapper>
  );
};

export default CheckboxFilter;
