import get from 'lodash/get';
import { createSelector } from 'reselect';

import { SOURCE_TYPE } from 'data/types/source.types';
import { ASYNC_BOOLEAN } from 'data/types/progress.types';
import { pluginInstalledSelector } from './user';

const baseLoadingSelector = (state) => get(state, 'loading', {});
export const contactsTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactsTable,
);

export const loginPageLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.loginPage,
);

export const mapContactPropertiesLoading = createSelector(
  baseLoadingSelector,
  (loading) => loading.mapContactProperties,
);

export const contactsListTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactsListTable,
);

export const listContactsTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.listContactsTable,
);

export const companiesListTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.companiesListTable,
);

export const listsTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.listsTable,
);

export const listSelectLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.listSelect,
);

export const recentListsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.recentLists,
);

export const recentListsSizesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.recentListsSizesLoading,
);

export const updateSubscriptionLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateSubscription,
);

export const updateCardLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.cardsLoading,
);

export const subscriptionsTabLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.accountLoader || loading.billingCycleLoader || loading.subscriptionsLoader || loading.plansLoader,
);

export const subscriptionsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.subscriptionsLoader,
);

export const cardsTabLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.accountLoader || loading.cardsLoading,
);

export const contactPreviewBarButton = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactPreviewBarButton,
);

export const locationFilterLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.locationsLoader,
);

export const positionFilterLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.positionsLoader,
);

export const industryFilterLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.industriesLoader,
);

export const contactPreviewBarLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.previewContact_contact_data,
);

export const getContactSidebarLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getContactSidebarLoader,
);

export const companyPreviewBarDataLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.previewCompany_company_data,
);

export const contactPreviewBarCompanyRelationsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.previewContact_company_relations,
);

export const addToContactCompaniesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.AddToContactCompanies,
);

export const integrationsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.integrations,
);

export const companyPreviewBarButton = createSelector(
  baseLoadingSelector,
  (loading) => loading.companyPreviewBarButton,
);

export const referredUsersSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.referredUsers,
);

export const sessionSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sessions,
);

export const getLoaderSelector = (component) => createSelector(
  baseLoadingSelector,
  (loading) => loading[component],
);

export const sequencesTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sequencesTable,
);

export const listsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.listsLoading,
);

export const premadeSequenceListLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.premadeSequencesList,
);

export const stepListLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.stepList,
);

export const resetPasswordPageLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.resetPasswordPage,
);

export const signUpLoadingSelector = createSelector(
  baseLoadingSelector,
  pluginInstalledSelector,
  (loading) => loading.signUp,
);

export const signInLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.signIn,
);

export const emailCheckLoadingSelector = createSelector(
  baseLoadingSelector,
  pluginInstalledSelector,
  (loading) => loading.emailCheck,
);

export const verifyTokenLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.verifyToken,
);

export const unconfirmedPhonePageLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.unconfirmedPhonePage,
);

export const verifyPhoneLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.verifyPhone,
);

export const notificationListLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.notificationList,
);

export const foldersTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.foldersTable,
);

export const contactsFiltersLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactsFilters,
);

export const contactsListsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactsLists,
);

export const usageLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.usage,
);

export const userCreditsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.userCredits,
);

export const userCreditsAndSubscriptionLoadingSelector = createSelector(
  subscriptionsLoadingSelector,
  userCreditsLoadingSelector,
  (subscriptionLoader, creditsLoader) => subscriptionLoader || creditsLoader,
);

export const relationLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.relation,
);

export const usagePageAnalyticsSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.usagePageAnalytics,
);

export const companiesFiltersLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.companiesFilters,
);

export const searchContactInsightsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchContactInsights,
);

export const searchCompanyInsightsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchCompanyInsights,
);

export const searchContactSavedInsightsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchContactSavedInsights,
);

export const searchCompanySelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchCompanies,
);

export const searchDeletedCompaniesSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchDeletedCompanies,
);

export const searchContactsSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchContacts,
);

export const deleteContactsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.deleteContacts,
);

export const searchDeletedListsSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchDeletedLists,
);

export const insightsLoadingSelector = (sourceType) => {
  if (sourceType === SOURCE_TYPE.CONTACT) {
    return searchContactInsightsLoadingSelector;
  } if (sourceType === SOURCE_TYPE.COMPANY) {
    return searchCompanyInsightsLoadingSelector;
  }
  throw new Error('Insights loading: An unknown source type');
};

export const userSettingsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.userSettingsLoader,
);

export const addCompaniesToContactLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.addCompaniesToContact,
);

export const usersWorkspacesLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.usersWorkspaces,
);
export const workspaceTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.workspaceAnalytics,
);

export const searchLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchLoading,
);

export const searchHistoryLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchHistoryLoading,
);

export const propertiesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.propertiesLoading,
);

export const insightsSearchLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.insightsSearchLoading,
);

export const addContactsToSequenceLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.addContactsToSequenceLoader,
);

export const addToSequenceLoadingSelector = createSelector(
  insightsSearchLoadingSelector,
  addContactsToSequenceLoaderSelector,
  (loading, loading2) => loading || loading2,
);

export const bulkInsightsSearchLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.bulkInsightsSearchLoading,
);

export const googleOAuthVerificationSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.googleOAuthVerification,
);

export const loadersSelector = (loaders = []) => createSelector(
  baseLoadingSelector,
  (loading) => Object.entries(loading).filter(([load]) => loaders.includes(load)).map(([, value]) => value),
);

export const oauth2PageLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.oauth2pageLoading,
);

export const invoiceLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.invoiceLoader,
);

export const contactsCountLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactsCount,
);

export const companiesCountLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.companiesCount,
);

export const listsSizesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.listsSizesLoading,
);

export const bulkOperationsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.bulkOperationsLoader,
);

export const insightContactLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.insightContactLoader,
);

export const insightContactLinkedinLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.insightContactLinkedinLoader,
);

export const insightContactEmailLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.insightContactEmailLoader,
);

export const insightCompanyLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.insightCompanyLoader,
);

export const insightContactsCountLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.insightContactsCountLoader,
);

export const googleAuthLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.googleAuth,
);

export const addContactLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.addContactLoader,
);

export const addCompanyLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.addCompanyLoader,
);

export const deletedItemsTableLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.deletedItemsTable,
);

export const deleteBlockedContactLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.deleteBlockedContactLoader,
);

export const deleteBlockedCompanyLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.deleteBlockedCompanyLoader,
);

export const searchPreviewLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchPreviewLoading,
);

export const searchContactLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchContactLoader,
);

export const addContactConfirmationLoaderSelector = createSelector(
  addContactLoaderSelector,
  searchContactLoaderSelector,
  (addContactLoader, searchContactLoader) => addContactLoader || searchContactLoader,
);

export const savedSearchOptionsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.savedSearchOptionsLoader,
);

export const shortSearchesHistoryLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.shortSearchesHistoryLoader,
);

export const shortSearchesLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.shortSearchesLoader,
);

export const shortRecentActivitiesLoaderSelector = createSelector(
  shortSearchesHistoryLoaderSelector,
  (loading) => loading,
);

export const searchesPreviewLoadingSelector = createSelector(
  searchLoadingSelector,
  searchHistoryLoadingSelector,
  (loading1, loading2) => loading1 || loading2,
);

export const createUserSearchLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.createUserSearchLoading,
);

export const createApiKeyLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.createApiKeyLoader,
);

export const schedulesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.schedulesLoading,
);

export const sequenceLeadsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sequenceLeadsLoading,
);

export const sequenceEngagedDetailsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sequenceEngagedDetailsLoading,
);

export const sequenceAudienceDetailsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sequenceAudienceDetailsLoading,
);

export const sequenceStepStatsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sequenceStepStatsLoading,
);

export const connectedAccountsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.connectedAccountsLoading,
);

export const countActiveCampaignsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.countActiveCampaignsLoading,
);

export const optOutContactsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.optOutContactsLoading,
);

export const unsubscribeSequenceLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.unsubscribeSequenceLoading,
);

export const sendTestMessageLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sendTestMessageLoading,
);

export const accountLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.accountLoader,
);

export const companiesLimitLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.companiesLimitLoader,
);

export const userWorkspacesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.userWorkspacesLoading,
);

export const verifyAccountByCodeLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.verifyAccountByCodeLoader,
);

export const currentCompanyTechnologiesLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.currentCompanyTechnologiesLoader,
);

export const searchTechnologiesLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchTechnologiesLoader,
);

export const createQuitReportLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.createQuitReportLoader,
);

export const deleteUserLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.deleteUserLoader,
);

export const analyticsAndDeleteUserLoaderSelector = createSelector(
  createQuitReportLoaderSelector,
  deleteUserLoaderSelector,
  (loading, loading2) => loading || loading2,
);

export const userExtensionLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.userExtensionLoader,
);

export const deleteFilesFromStepLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.deleteFilesFromStepLoader,
);

export const updateSequenceDraftStatusLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateSequenceDraftStatusLoader,
);

export const createSequenceLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.createSequenceLoader,
);

export const updateStepRemotelyLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateStepRemotelyLoader,
);

export const updateSequenceSettingsRemotelyLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateSequenceSettingsRemotelyLoader,
);

export const sequenceRemotelyLoaderSelector = createSelector(
  updateStepRemotelyLoaderSelector,
  updateSequenceSettingsRemotelyLoaderSelector,
  (updateStepRemotelyLoader, updateSequenceSettingsRemotelyLoader) => updateStepRemotelyLoader || updateSequenceSettingsRemotelyLoader,
);

export const sendTestCampaignLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sendTestCampaignLoader,
);

export const sumSendingLimitsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sumSendingLimitsLoading,
);

export const getSequenceSentMailsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getSequenceSentMailsLoader,
);

export const uploadImagesToCDNLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.uploadImagesToCDNLoader,
);

export const enrollmentContactsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.enrollmentContactsLoader,
);

export const previewContactsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.previewContactsLoader,
);

export const getTeammatesLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getTeammatesLoader,
);

export const checkGoogleSheetsPluginLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.checkGoogleSheetsPluginLoader,
);

export const suggestionsLoaderSelector = createSelector(
  getTeammatesLoaderSelector,
  sequencesTableLoadingSelector,
  checkGoogleSheetsPluginLoaderSelector,
  pluginInstalledSelector,
  (loading1, loading2, loading3, loading4) => loading1 || loading2 || loading3 || loading4 === ASYNC_BOOLEAN.LOADING,
);

export const updateSubscriptionStatusLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateSubscriptionStatusLoader,
);

export const contactsAnalyticsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactsAnalyticsLoader,
);

export const createStepTemplateLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.createStepTemplateLoader,
);

export const fastSearchInputLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.fastSearchInput,
);

export const getSequencesByNameLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getSequencesByNameLoader,
);

export const inaccurateFieldLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.inaccurateFieldLoading,
);

export const getContactListsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getContactListsLoading,
);

export const getContactSequencesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getContactSequencesLoading,
);

export const getHistoryLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getHistoryLoading,
);

export const getCompanyCompetitorsSidebarLoading = createSelector(
  baseLoadingSelector,
  (loading) => loading.getCompanyCompetitorsSidebarLoading,
);

export const getCompanyCompetitorsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getCompanyCompetitorsLoading,
);

export const getLastThreeInsightContactsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getLastThreeInsightContactsLoading,
);

export const contactPageWithoutHistoryLoadingSelector = createSelector(
  contactPreviewBarLoadingSelector,
  getContactListsLoadingSelector,
  getContactSequencesLoadingSelector,
  (...loaders) => loaders.some((loader) => loader),
);

export const contactPageLoadingSelector = createSelector(
  contactPreviewBarLoadingSelector,
  getContactListsLoadingSelector,
  getContactSequencesLoadingSelector,
  getHistoryLoadingSelector,
  (...loaders) => loaders.some((loader) => loader),
);

export const companySidebarLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.companySidebarLoading,
);

export const companyPageLoadingSelector = createSelector(
  companyPreviewBarDataLoadingSelector,
  getHistoryLoadingSelector,
  getCompanyCompetitorsLoadingSelector,
  (...loaders) => loaders.some((loader) => loader),
);

export const createNoteLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.createNoteLoader,
);

export const contactActivityLoaderSelector = createSelector(
  createNoteLoaderSelector,
  (...loaders) => loaders.some((loader) => loader),
);

export const searchPreviewBarLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.fastSearchCompaniesTab,
);

export const fastSearchCompaniesTabLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.fastSearchCompaniesTab,
);

export const fastSearchContactsTabLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.fastSearchContactsTab,
);

export const contactsForceNoResultsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactsForceNoResults,
);

export const companiesForceNoResultsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.companiesForceNoResults,
);

export const hubspotConnectionLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.hubspotConnectionLoading,
);

export const updateHubspotConnectionLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateHubspotConnectionLoading,
);

export const syncHubspotUserLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.syncHubspotUserLoading,
);

export const searchContactListsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchContactListsLoading,
);

export const searchContactSequencesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchContactSequencesLoading,
);

export const searchCompanyContactInsightsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchCompanyContactInsights,
);

export const searchCompanyContactSavedInsightsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchCompanySavedContactInsights,
);

export const getCompanyKeywordsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getCompanyKeywordsLoading,
);

export const updatePreviewBarButtonSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updatePreviewBarButton,
);

export const salesforceConnectionLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.salesforceConnectionLoading,
);

export const syncSalesforceUserLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.syncSalesforceUserLoading,
);

export const updateSalesforceConnectionLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateSalesforceConnectionLoading,
);

export const integrationTaskLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.integrationTaskLoading,
);

export const zohoConnectionLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.zohoConnectionLoading,
);

export const pipedriveConnectionLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.pipedriveConnectionLoading,
);

export const updateZohoConnectionLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateZohoConnectionLoading,
);

export const updatePipedriveConnectionLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updatePipedriveConnectionLoading,
);

export const syncZohoUserLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.syncZohoUserLoading,
);

export const syncPipedriveUserLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.syncPipedriveUserLoading,
);

export const lastNspScoreFeedbackSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.lastNspScoreFeedback,
);

export const contactCellSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.contactCell,
);

export const googleSheetsConnectionLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.sheetsConnectionLoading,
);

export const syncGoogleSheetsDataLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.syncSheetsDataLoading,
);

export const updateGoogleSheetsConnectionLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateSheetsConnectionLoading,
);

export const searchSpreadsheetsLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchSpreadsheetsLoading,
);

export const searchSavedContactColleaguesSidebarLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchSavedContactColleaguesSidebarLoading,
);

export const searchSavedContactColleaguesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchSavedContactColleaguesLoading,
);

export const searchContactColleaguesSidebarLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchContactColleaguesSidebarLoading,
);

export const searchContactColleaguesLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.searchContactColleaguesLoading,
);

export const getContactColleaguesPositionsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getContactColleaguesPositionsLoading,
);

export const updateConnectedAccountLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.updateConnectedAccountLoading,
);

export const customAccountVerificationLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.customAccountVerification,
);

export const fastSpringUpdateLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.fastSpringUpdateLoading,
);

export const saveFromSearchSelectorLoading = createSelector(
  insightsSearchLoadingSelector,
  bulkInsightsSearchLoadingSelector,
  companiesLimitLoaderSelector,
  (insightsLoading, bulkInsightsLoading, companiesLimitLoading) => insightsLoading || bulkInsightsLoading || companiesLimitLoading,
);

export const exportDataLoaderSelector = createSelector(
  baseLoadingSelector,
  saveFromSearchSelectorLoading,
  (loading, saveFromSearchLoading) => loading.exportDataLoader || saveFromSearchLoading,
);

export const savedInsightContactsLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.savedInsightContactsLoading,
);

export const createImportLoaderSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.createImportLoaderSelector,
);

export const getCheapestPlansLoadingSelector = createSelector(
  baseLoadingSelector,
  (loading) => loading.getCheapestPlansLoading,
);
