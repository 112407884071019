import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useFetch from 'hooks/useFetch';
import { getCards } from 'data/actions/cards';
import { deleteUser } from 'data/actions/user';
import { toggleModal } from 'data/actions/modals';
import { createQuitReport } from 'data/actions/common';
import { cheapestPlansSelector } from 'data/selectors/plans';
import { modalOptionsSelector } from 'data/selectors/modals';
import { existFastSpringCardSelector } from 'data/selectors/cards';
import { cancelSubscriptionSelector } from 'data/selectors/subscriptions';
import {
  REASON_TYPE,
  SUBSCRIPTION_STATUS,
} from 'data/types/subscription.types';
import {
  resetCancelSubscription,
  setCancelSubscription,
  updateSubscriptionStatus,
} from 'data/actions/subscriptions';
import { getCheapestPlans } from 'data/actions/plans';
import { toggleMessage } from 'data/actions/message';
import useDeleteAccountMessage from 'hooks/messageTemplates/useDeleteAccountMessage';
import Other from '../common/modals/other';
import FinishedWork from './finishedWork';
import StepManager from '..';
import CommonCase from '../common/modals/commonCase';

const ManagerCancelSubscriptionModal = () => {
  const dispatch = useDispatch();

  const { reason, labelReason, deleteAccount } = useSelector(modalOptionsSelector);
  const existFastSpringCard = useSelector(existFastSpringCardSelector);
  const details = useSelector(cancelSubscriptionSelector);

  const { data: cheapestPlans } = useFetch({
    selector: cheapestPlansSelector,
    action: getCheapestPlans,
    cached: true,
  });

  const deleteAccountMessage = useDeleteAccountMessage();

  const reasonMap = {
    [REASON_TYPE.FINISHED_WORK]: FinishedWork,
    [REASON_TYPE.ANOTHER_TOOL]: CommonCase,
    [REASON_TYPE.POOR_DATA_QUANTITY]: CommonCase,
    [REASON_TYPE.POOR_DATA_QUALITY]: CommonCase,
    [REASON_TYPE.EXPENSIVE_SUBSCRIPTION]: CommonCase,
    [REASON_TYPE.OTHER]: Other,
  };

  const ReasonComponent = reasonMap[reason] || Fragment;

  const handleClose = () => {
    dispatch(toggleModal('manager_cancel_subscription', false));
    dispatch(resetCancelSubscription());
  };

  const handleCancelSubscription = (params) => {
    if (reason) {
      const {
        needToAct,
      } = params;
      if (deleteAccount) {
        details.deleteAccount = deleteAccount;
      }
      const status = details.pausingPeriod ? SUBSCRIPTION_STATUS.PAUSE : SUBSCRIPTION_STATUS.DISABLED;
      const cheapPlan = cheapestPlans.find((item) => item._id === details.cheapPlanId);
      dispatch(createQuitReport({
        type: reason,
        action: 'cancelSubscription',
        details,
        nextPlan: cheapPlan,
        onSuccess: () => {
          if (needToAct) {
            if (deleteAccount && status !== SUBSCRIPTION_STATUS.PAUSE) {
              dispatch(deleteUser(() => {
                handleClose();
                deleteAccountMessage();
              }));
            } else {
              dispatch(updateSubscriptionStatus({
                status,
                reason: labelReason,
                period: details.pausingPeriod,
              }, () => {
                if (existFastSpringCard) {
                  dispatch(getCards());
                }
                handleClose();
              }));
            }
          } else if (details.cheapPlanId) {
            dispatch(toggleMessage('success', {
              header: 'Success',
              text: (
                <div>
                  Congratulations! The charge for your next payment will be
                  {' '}
                  <b>
                    {`${cheapPlan.price}$`}
                  </b>
                  <br />
                  No matter how your next subscription is paid, whether you pay manually or charge automatically, the discount will be applied.
                </div>
              ),
            }));
          }
        },
      }));
    }
  };

  return (
    <StepManager
      stepsComponent={ReasonComponent}
      details={details}
      onClose={handleClose}
      onFinish={handleCancelSubscription}
      onStep={(params) => dispatch(setCancelSubscription(params))}
      deleteAccount={deleteAccount}
    />
  );
};

export default ManagerCancelSubscriptionModal;
