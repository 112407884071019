import get from 'lodash/get';
import { Drawer } from 'antd';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContactSidebar } from '@newlab-io/getprospect-react-components/lib/pages';
import { getRelevantCompanyRelation } from '@newlab-io/getprospect-react-components/lib/shared/helpers/contact';

import {
  contactPreviewSidebarColleaguesSelector,
  contactPreviewSidebarHistorySelector,
  contactPreviewSidebarSavedColleaguesSelector,
  contactSidebarSelector,
  contactSidebarPopulatedListsSelector,
} from 'data/selectors/contacts';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import {
  getContact,
  updateContact,
  getInaccurateFields,
  getContactLists,
  getContactSequences,
  getSavedContactByInsightId,
  saveContactFormInsights,
  bulkEmailsValidation,
  searchContactColleagues,
  updateContactCompaniesRelation,
  searchPhoneNumber,
} from 'data/actions/contacts';
import { STATUSES } from 'constants/emailStatus';
import { toggleModal } from 'data/actions/modals';
import { toggleMessage } from 'data/actions/message';
import { CREDITS_TYPE } from 'data/types/user.types';
import { SOURCE_TYPE } from 'data/types/source.types';
import usePaidOperation from 'hooks/usePaidOperation';
import { setOperation } from 'data/actions/storeTriggers';
import { getEntityHistory } from 'data/actions/common';
import {
  contactPageWithoutHistoryLoadingSelector,
} from 'data/selectors/loading';
import * as CONSTANTS from 'constants/styles';
import Loader from 'components/loading';
import { createNote } from 'data/actions/notes';
import {
  createList, deleteContactsFromStaticList, listAddContacts, searchContactLists,
} from 'data/actions/lists';
import { getInsightById } from 'data/actions/search';
import { contactListsSelector } from 'data/selectors/lists';
import { contactSequencesSelector } from 'data/selectors/sequences';
import {
  addContactsToSequence, createSequence, removeSequenceLeads, searchContactSequences,
} from 'data/actions/sequences';
import useSubscribeActions from 'hooks/useSubscribeActions';
import { getFullName } from 'helpers/getFullName';
import { generateContactFilters } from 'helpers/filterFactory';
import { generateContactFilters as generateInsightFilters } from 'helpers/insightHelpers';

import {
  PreviewLoader,
} from '../styles';

const NewContactPreviewBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const contactData = useSelector(contactSidebarSelector);
  const savedColleagues = useSelector(contactPreviewSidebarSavedColleaguesSelector);
  const colleagues = useSelector(contactPreviewSidebarColleaguesSelector);
  const activities = useSelector(contactPreviewSidebarHistorySelector);
  const populatedLists = useSelector(contactSidebarPopulatedListsSelector);
  const linkedinUrl = get(contactData, 'properties.linkedinUrl.value', '');
  const { visible } = useSelector(modalsSelector);
  const {
    isInsight: defaultIsInsight,
    contactId: defaultContactId,
    insightId,
    defaultNavigation = 'prospect',
    defaultContactsTab = 'allContacts',
    backHistory = [],
  } = useSelector(modalOptionsSelector);
  const { data: contactLists = [] } = useSelector(contactListsSelector);
  const { data: contactSequences = [] } = useSelector(contactSequencesSelector);

  const loading = useSelector(contactPageWithoutHistoryLoadingSelector);

  const [isInsight, setIsInsight] = useState(defaultIsInsight);
  const [contactId, setContactId] = useState(defaultContactId);
  const [localLoading, setLocalLoading] = useState(true);

  const [suggestedCompanyId, setSuggestedCompanyId] = useState();
  const [savedSuggestedCompanyId, setSavedSuggestedCompanyId] = useState();

  const [keywordAllCompanies, setKeywordAllCompanies] = useState();
  const [keywordSavedCompanies, setKeywordSavedCompanies] = useState();

  const [navigation, setNavigation] = useState(defaultNavigation);
  const [contactsTab, setContactsTab] = useState(defaultContactsTab);

  const savedSuggestedCompanies = (contactData.companyRelations || []).filter((relation) => get(relation, 'company.properties.name')).map((relation) => ({
    _id: relation.company._id,
    name: get(relation, 'company.properties.name.value', ''),
    employees: savedColleagues.totalItems,
  }));

  const suggestedCompanies = (contactData.companyRelations || []).filter((relation) => get(relation, 'company.properties.name')).map((relation) => ({
    _id: relation.company._id,
    name: get(relation, 'company.properties.name.value', ''),
    employees: colleagues.totalItems,
  }));

  const contactForSidebar = useMemo(() => {
    if (!contactData) return contactData;
    const firstName = get(contactData, 'properties.firstName.value', '');
    const lastName = get(contactData, 'properties.lastName.value', '');
    const location = get(contactData, 'properties.location.value', '');
    const phone = get(contactData, 'properties.phone.value[0]', '');

    return ({
      _id: contactId,
      firstName,
      lastName,
      saved: !isInsight,
      saving: contactData.isSaving,
      location,
      linkedin: linkedinUrl,
      activities,
      lists: populatedLists,
      phone,
      phoneStatus: get(contactData, 'properties.phone.status', ''),
      companyRelations: (contactData.companyRelations || []).map((relation) => {
        const industry = get(relation, 'company.properties.industry.value', '');
        const relationId = relation.company?._id || relation.company?.id;
        return {
          ...relation,
          company: {
            _id: relationId,
            industry: typeof industry === 'object' ? get(industry, 'en_US', '') : industry,
            location: get(relation, 'company.properties.location.value', ''),
            name: get(relation, 'company.properties.name.value', ''),
            domain: get(relation, 'company.properties.domain.value', ''),
            description: get(relation, 'company.properties.description.value', ''),
            phone: get(relation, 'company.properties.phone.value', ''),
            type: get(relation, 'company.properties.type.value', ''),
            employees: get(relation, 'company.properties.size.value', ''),
            foundedAt: get(relation, 'company.properties.foundedAt.value', ''),
            postalCode: get(relation, 'company.properties.postalCode.value', ''),
          },
        };
      }),
      allColleagues: (colleagues?.data || []).map((colleague) => ({
        _id: colleague._id || colleague.id,
        firstName: get(colleague, 'properties.firstName.value', ''),
        lastName: get(colleague, 'properties.lastName.value', ''),
        location: get(colleague, 'properties.location.value', ''),
        companyRelations: colleague.companyRelations,
        saved: colleague.saved,
        savedWorkspaceId: colleague.savedWorkspaceId,
      })),
      savedColleagues: (savedColleagues?.data || []).map((colleague) => ({
        _id: colleague._id,
        firstName: get(colleague, 'properties.firstName.value', ''),
        lastName: get(colleague, 'properties.lastName.value', ''),
        location: get(colleague, 'properties.location.value', ''),
        companyRelations: colleague.companyRelations,
      })).filter((colleague) => colleague._id !== contactData._id),
    });
  }, [contactId, populatedLists, isInsight, contactData, activities, colleagues, savedColleagues, linkedinUrl]);

  const getDomainByCompanyId = useCallback((companyId) => {
    const foundCompany = contactData.companyRelations.find((relation) => [relation?.company?._id, relation?.company?.id].includes(companyId));
    const primaryDomain = foundCompany?.company?.properties?.domain?.value;
    return primaryDomain;
  }, [contactData.companyRelations]);

  const handleClose = useCallback(() => dispatch(toggleModal('contact_preview', false)), [dispatch]);
  const handleShowContact = () => {
    history.push(`/contacts/${contactId}`);
  };
  const handleSearchPhoneNumber = usePaidOperation(CREDITS_TYPE.PHONE_SEARCH, (onSuccess = () => {}) => {
    dispatch(saveContactFormInsights({
      insightId,
      companies: contactForSidebar.companyRelations.map((company) => {
        const email = get(company, 'email');
        const item = { company: get(company, 'company.id', get(company, 'company._id')) };
        if (email && ![STATUSES.PROCESSING].includes(get(email, 'status'))) item.email = email;
        return item;
      }).filter(({ company }) => company),
      onSuccess: (data) => {
        if (data?._id) dispatch(searchPhoneNumber({ linkedinUrl, insightId, contactId: data?._id }));
        onSuccess();
      },
    }));
    dispatch(setOperation({
      type: 'insight',
      source: SOURCE_TYPE.CONTACT,
      from: 'search',
    }));
  });

  const handleSaveContact = usePaidOperation(CREDITS_TYPE.EMAIL_SEARCH, (onSuccess = () => {}) => {
    dispatch(saveContactFormInsights({
      insightId,
      companies: contactForSidebar.companyRelations.map((company) => {
        const email = get(company, 'email');
        const item = { company: get(company, 'company.id', get(company, 'company._id')) };
        if (email && ![STATUSES.PROCESSING].includes(get(email, 'status'))) item.email = email;
        return item;
      }).filter(({ company }) => company),
      onSuccess,
    }));
    dispatch(setOperation({
      type: 'insight',
      source: SOURCE_TYPE.CONTACT,
      from: 'search',
    }));
  });

  const handleBack = () => {
    const lastPage = backHistory.pop();
    if (lastPage.source === SOURCE_TYPE.CONTACT) {
      if (lastPage.options.navigation) {
        setNavigation(lastPage.options.navigation);
      } if (lastPage.options.contactsTab) {
        setContactsTab(lastPage.options.contactsTab);
      }
    }
    dispatch(toggleModal(`${lastPage.source}_preview`, true, {
      ...lastPage.options,
      defaultNavigation: lastPage.options.navigation,
      defaultContactsTab: lastPage.options.contactsTab,
      defaultInsightsTab: lastPage.options.insightsTab,
      backHistory,
    }));
  };

  const setActionProxy = (callback) => (item) => {
    if (isInsight) {
      handleSaveContact((createdContact) => callback(item, createdContact._id));
    } else {
      callback(item, contactId);
    }
  };

  const handleAddList = setActionProxy((list, newContactId) => {
    if (!list?._id) {
      dispatch(createList({ name: list.name, dynamic: false }, (createdList) => {
        setImmediate(() => {
          dispatch(listAddContacts({
            listIds: [createdList._id],
            contactsIds: [newContactId],
            fromPreviewBar: true,
            filters: [],
          }));
          dispatch(searchContactLists());
        });
      }));
    } else {
      dispatch(listAddContacts({
        listIds: [list._id],
        contactsIds: [newContactId],
        fromPreviewBar: true,
        filters: [],
      }));
    }
  });

  const handleSearchList = useCallback(debounce((searchValue) => {
    dispatch(searchContactLists(searchValue));
  }, 1000), [dispatch]);

  const handleRemoveList = setActionProxy((listId, newContactId) => {
    dispatch(deleteContactsFromStaticList({
      listIds: [listId],
      objectIdList: [newContactId],
      fromPreviewBar: true,
    }));
  });

  const handleRemoveSequence = setActionProxy((sequence, newContactId) => {
    dispatch(removeSequenceLeads(sequence._id, [newContactId]));
  });

  const handleAddSequence = setActionProxy((sequence, newContactId) => {
    if (!sequence?._id) {
      dispatch(createSequence({
        name: sequence.name,
      }, (createdSequence) => {
        dispatch(addContactsToSequence({
          sequenceId: createdSequence._id,
          contactsIds: [newContactId],
          sequence: createdSequence,
        }));
      }));
    } else {
      dispatch(addContactsToSequence({
        sequenceId: sequence._id,
        contactsIds: [newContactId],
        sequence,
      }));
    }
  });

  const handleSearchCompany = useCallback(debounce((searchValue, saved) => {
    if (saved) {
      setKeywordSavedCompanies(searchValue);
      dispatch(searchContactColleagues({
        filters: generateContactFilters({ companyId: savedSuggestedCompanyId }),
        search: { properties: ['firstName', 'lastName', 'email'], value: searchValue },
        saved: true,
        isSidebar: true,
      }));
    } else {
      setKeywordAllCompanies(searchValue);
      const domain = getDomainByCompanyId(suggestedCompanyId);
      if (domain) {
        dispatch(searchContactColleagues({ filters: generateInsightFilters({ domain, search: searchValue }), isSidebar: true }));
      }
    }
  }, 1000), [dispatch, savedSuggestedCompanyId, suggestedCompanyId, getDomainByCompanyId]);

  const verifyContact = usePaidOperation(CREDITS_TYPE.EMAIL_VERIFICATION, () => {
    const tasks = (contactData?.companyRelations || []).filter((relation) => relation?.email?.value).map((relation) => ({
      contactId,
      relationId: relation._id,
      email: relation.email.value,
    }));
    dispatch(bulkEmailsValidation({ tasks }));
  });

  useEffect(() => {
    setLocalLoading(true);
    const onSuccess = (foundContact) => {
      setContactId(foundContact._id);
      dispatch(getInaccurateFields(SOURCE_TYPE.CONTACT, [foundContact._id]));
      dispatch(getContactLists({
        contactId: foundContact._id,
        isSidebar: true,
      }));
      dispatch(getContactSequences(foundContact._id));
      dispatch(getEntityHistory({
        source: SOURCE_TYPE.CONTACT,
        entityId: foundContact._id,
        isSidebar: true,
        onSuccess: () => setLocalLoading(false),
        onFailed: () => setLocalLoading(false),
      }));
      const primaryRelation = getRelevantCompanyRelation(foundContact.companyRelations);
      const primaryDomain = primaryRelation?.company?.properties?.domain?.value;
      const primaryCompanyId = primaryRelation?.company?._id;
      if (primaryDomain) {
        dispatch(searchContactColleagues({ filters: generateInsightFilters({ domain: primaryDomain }), isSidebar: true }));
      }
      if (primaryCompanyId) {
        setSuggestedCompanyId(primaryCompanyId);
        setSavedSuggestedCompanyId(primaryCompanyId);
        dispatch(searchContactColleagues({ filters: generateContactFilters({ companyId: primaryCompanyId }), saved: true, isSidebar: true }));
      }
      setImmediate(() => {
        dispatch(searchContactLists());
        dispatch(searchContactSequences());
      });
      setLocalLoading(false);
    };
    const onFailed = () => {
      dispatch(toggleMessage('error', { text: 'The contact is not found' }));
      handleClose();
      setLocalLoading(false);
    };
    if (defaultContactId) {
      dispatch(getContact({
        contactId: defaultContactId,
        isSidebar: true,
        onSuccess,
        onFailed,
      }));
    } else if (insightId) {
      if (defaultIsInsight) {
        dispatch(getInsightById({
          insightId,
          sourceType: SOURCE_TYPE.CONTACT,
          components: ['getContactSidebarLoader'],
          onSuccess: (foundContact) => {
            if (foundContact) {
              const primaryDomain = foundContact.companies[0]?.company?.domain;
              if (primaryDomain) {
                dispatch(searchContactColleagues({ filters: generateInsightFilters({ domain: primaryDomain }), isSidebar: true }));
              }
            } else {
              dispatch(toggleMessage('error', { header: 'Contact is obsolete' }));
              handleClose();
            }
            setLocalLoading(false);
          },
          onFailed: () => setLocalLoading(false),
        }));
      } else {
        dispatch(getSavedContactByInsightId({
          insightId,
          onSuccess,
          onFailed,
        }));
      }
    }
  }, [dispatch, history, defaultContactId, insightId, defaultIsInsight, handleClose]);

  useEffect(() => {
    setContactId(defaultContactId);
  }, [defaultContactId]);

  useEffect(() => {
    setIsInsight(defaultIsInsight);
    if (insightId && defaultIsInsight) {
      setContactId();
    }
  }, [insightId, defaultIsInsight]);

  useSubscribeActions({
    actions: ['SAVE_CONTACT_FROM_INSIGHTS'],
    callback: ({ insightId: savedInsightId, payload: { _id: newContactId } }) => {
      if (savedInsightId === insightId) {
        setIsInsight(false);
        setContactId(newContactId);
      }
    },
  }, [insightId]);

  return (
    <Drawer
      className="ant-drawer-default"
      placement="right"
      mask={false}
      closable={false}
      visible={visible}
      bodyStyle={{ padding: 0, overflow: 'hidden' }}
      width={CONSTANTS.DRAWER_WIDTH}
    >
      {
        (loading || localLoading) ? <PreviewLoader><Loader size="large" loading /></PreviewLoader>
          : (
            <>
              <ContactSidebar
                navigation={navigation}
                onSavePhone={handleSearchPhoneNumber}
                setNavigation={setNavigation}
                contactsTab={contactsTab}
                setContactsTab={setContactsTab}
                contact={contactForSidebar}
                onClose={handleClose}
                suggestedLists={contactLists}
                suggestedSequences={contactSequences}
                suggestedCompanies={suggestedCompanies}
                savedSuggestedCompanies={savedSuggestedCompanies}
                onAddSequence={handleAddSequence}
                onRemoveSequence={handleRemoveSequence}
                onCreateNote={(text) => dispatch(createNote({
                  text,
                  source: SOURCE_TYPE.CONTACT,
                  entityId: contactId,
                  isSidebar: true,
                }))}
                onBack={backHistory.length ? handleBack : undefined}
                onSearchCompany={handleSearchCompany}
                onChangeRelation={(relation) => {
                  dispatch(updateContactCompaniesRelation({
                    contactId,
                    isSidebar: true,
                    data: [relation],
                  }));
                }}
                onSelectCompany={(companyId, saved) => {
                  if (saved) {
                    setSavedSuggestedCompanyId(companyId);
                    dispatch(searchContactColleagues({
                      filters: generateContactFilters({ companyId }),
                      saved: true,
                      isSidebar: true,
                      search: { properties: ['firstName', 'lastName', 'email'], value: keywordSavedCompanies },
                    }));
                  } else {
                    setSuggestedCompanyId(companyId);
                    const domain = getDomainByCompanyId(companyId);
                    if (domain) {
                      dispatch(searchContactColleagues({ filters: generateInsightFilters({ domain, search: keywordAllCompanies }), isSidebar: true }));
                    }
                  }
                }}
                onClickCompany={(companyId) => {
                  const foundCompany = contactData.companyRelations.find((relation) => relation.company?._id === companyId);
                  if (foundCompany) {
                    backHistory.push({
                      options: {
                        isInsight,
                        contactId,
                        insightId,
                        navigation,
                        contactsTab,
                      },
                      source: SOURCE_TYPE.CONTACT,
                    });
                    const isCompanySaved = contactForSidebar.saved || foundCompany.company.savedWorkspaceId;
                    dispatch(toggleModal('company_preview', true, {
                      companyId: isCompanySaved ? (foundCompany.company.savedWorkspaceId || companyId) : undefined,
                      insightId: !isCompanySaved ? companyId : undefined,
                      isInsight: !isCompanySaved,
                      backHistory,
                    }));
                  }
                }}
                onClickContact={(_contactId, saved) => {
                  backHistory.push({
                    options: {
                      isInsight,
                      contactId,
                      insightId,
                      navigation,
                      contactsTab,
                    },
                    source: SOURCE_TYPE.CONTACT,
                  });
                  setNavigation('prospect');
                  setContactsTab('allContacts');
                  let updatedContactId = _contactId;
                  let updatedSaved = saved;
                  if (!saved) {
                    const foundContact = contactForSidebar.allColleagues.find((colleague) => colleague._id === _contactId);
                    if (foundContact?.savedWorkspaceId) {
                      updatedContactId = foundContact.savedWorkspaceId;
                      updatedSaved = true;
                    }
                  }
                  dispatch(toggleModal('contact_preview', true, {
                    contactId: updatedSaved ? updatedContactId : undefined,
                    insightId: !updatedSaved ? updatedContactId : undefined,
                    isInsight: !updatedSaved,
                    backHistory,
                  }));
                }}
                onUpdate={(formContact) => {
                  dispatch(updateContact({
                    contactId,
                    status: 'updated',
                    isSidebar: true,
                    properties: [
                      {
                        property: 'firstName',
                        value: formContact.firstName,
                      },
                      {
                        property: 'lastName',
                        value: formContact.lastName,
                      },
                      {
                        property: 'linkedinUrl',
                        value: formContact.linkedin,
                      },
                      {
                        property: 'location',
                        value: formContact.location,
                      },
                    ],
                  }));
                }}
                onVerifyContact={verifyContact}
                onView={handleShowContact}
                onSave={handleSaveContact}
                onRemoveList={handleRemoveList}
                onSearchList={handleSearchList}
                onAddList={handleAddList}
                onAddProperty={() => {
                  dispatch(toggleModal('create_property_preview', true, {
                    onCreateProperty: () => {
                      dispatch(toggleModal('contact_preview', true, { contactId }));
                    },
                    sourceType: SOURCE_TYPE.CONTACT,
                  }));
                }}
                onAddCompany={() => {
                  dispatch(toggleModal('add_company_to_contact_preview', true, { contactId: contactData._id }));
                }}
                onDelete={() => {
                  dispatch(toggleModal('delete_contacts', true, {
                    contactId,
                    openSidebarOnClose: true,
                    name: getFullName(contactData),
                    onClickYes: () => history.push('/contacts'),
                  }));
                }}
                onChangePhone={(value) => {
                  const phones = get(contactData, 'properties.phone.value', []).slice(1);
                  dispatch(updateContact({
                    contactId,
                    status: 'updated',
                    isSidebar: true,
                    properties: [
                      {
                        property: 'phone',
                        value: [value, ...phones],
                      },
                    ],
                  }));
                }}
              />
            </>
          )
      }
    </Drawer>
  );
};

export default NewContactPreviewBar;
