import React, { useState } from 'react';
import { Flex } from '@newlab-io/getprospect-react-components/lib/ui';

import ListSelect from 'components/listSelect';
import Text from '../text';

const AddToList = ({
  selectedList,
  onSelect,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Flex gap={12} direction="column">
      <Text />
      <ListSelect
        size="default"
        maxTagCount={2}
        isOpened={isOpened}
        placeholder="Enter or create lists..."
        setIsOpened={setIsOpened}
        value={selectedList}
        onSelectedListsChange={onSelect}
      />
    </Flex>
  );
};

export default AddToList;
