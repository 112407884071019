import words from 'lodash/words';
import capitalize from 'lodash/capitalize';

const labelDictionary = [
  { from: 'size', to: 'Employees' },
  { from: 'leads', to: 'Leads' },
  { from: 'position', to: 'Job title' },
  { from: 'linkedIn', to: 'LinkedIn' },
];

export const renameLabel = (item, isDefault = true) => {
  const foundedItem = labelDictionary.find((label) => label.from === item?.toLowerCase());

  if (foundedItem) {
    return foundedItem.to;
  }

  if (isDefault) {
    const parts = words(item).map((word, index) => (index === 0 ? capitalize(word) : word.toLowerCase()));
    const formattedString = parts.join(' ');
    return formattedString;
  }

  return item;
};
