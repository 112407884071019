import get from 'lodash/get';
import { createSelector } from 'reselect';

const baseSelector = (state) => get(state, 'teammates');

const baseTeammatesSelector = (state) => get(state, 'teammates.teammates', {});
export const teammatesSelector = createSelector(
  baseTeammatesSelector,
  (teammates) => teammates,
);

const baseRelationSelector = (state) => get(state, 'teammates.relation', {});
export const relationSelector = createSelector(
  baseRelationSelector,
  (relation) => relation,
);

export const allTeammatesSelector = createSelector(
  baseSelector,
  (teammates) => teammates.allTeammates,
);
