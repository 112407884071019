import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { createQuitReport } from 'data/actions/common';
import { deleteAccountSelector } from 'data/selectors/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import useDeleteAccountMessage from 'hooks/messageTemplates/useDeleteAccountMessage';
import {
  deleteUser,
  resetDeleteAccount,
  setDeleteAccount,
} from 'data/actions/user';
import { REASON_TYPE } from 'data/types/user.types';
import Other from '../common/modals/other';
import StepManager from '..';

const ManagerDeleteAccountModal = () => {
  const dispatch = useDispatch();

  const { reason, deleteAccount } = useSelector(modalOptionsSelector);
  const details = useSelector(deleteAccountSelector);

  const deleteAccountMessage = useDeleteAccountMessage();

  const reasonMap = {
    [REASON_TYPE.HARD_APP]: Other,
    [REASON_TYPE.NOT_FOUND_CONTACTS]: Other,
    [REASON_TYPE.ANOTHER_TOOL]: Other,
    [REASON_TYPE.BAD_KEEPING_DATA]: Other,
    [REASON_TYPE.OTHER]: Other,
  };

  const ReasonComponent = reasonMap[reason] || Fragment;

  const handleClose = () => {
    dispatch(toggleModal('manager_delete_account', false));
    dispatch(resetDeleteAccount());
  };

  const handleDeleteAccount = (params) => {
    if (reason) {
      const {
        needToAct,
      } = params;

      if (deleteAccount) {
        details.deleteAccount = deleteAccount;
      }
      dispatch(createQuitReport({
        type: reason,
        action: 'deleteAccount',
        details,
        onSuccess: () => {
          if (needToAct) {
            dispatch(deleteUser(() => {
              deleteAccountMessage();
              handleClose();
            }));
          }
        },
      }));
    }
  };

  return (
    <StepManager
      stepsComponent={ReasonComponent}
      details={details}
      onClose={handleClose}
      onFinish={handleDeleteAccount}
      onStep={(params) => dispatch(dispatch(setDeleteAccount(params)))}
    />
  );
};

export default ManagerDeleteAccountModal;
