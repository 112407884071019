export const USER_STATUS = Object.freeze({
  ALLOWED_PAYMENT: 'allowed_payment',
  NOT_ALLOWED_PAYMENT: 'not_allowed_payment',
  BLOCKED: 'blocked',
  PENDING_REVIEW: 'pending_review',
  EMPTY: undefined,
});

export const ACCOUNT_STATUS = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISCONNECT: 'disconnect',
});

export const CREDITS_TYPE = Object.freeze({
  EMAIL_SEARCH: 'EMAIL_SEARCH',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  PHONE_SEARCH: 'PHONE_SEARCH',
  SAVE_COMPANIES: 'SAVE_COMPANIES',
});

export const GP_GROUP = Object.freeze({
  DEFAULT: '0',
  PHONE_REQUIRED: '3',
  PHONE_NOT_REQUIRED: '4',
  NEW_PRICING: '5',
  NEW_PRICING_2: '6',
  NEW_PRICING_3: '7',
});

export const REASON_TYPE = Object.freeze({
  HARD_APP: 'hardApp',
  BAD_KEEPING_DATA: 'badKeepingData',
  NOT_FOUND_CONTACTS: 'notFoundContacts',
  CHANGE_EMAIL: 'changeEmail',
  CREATE_ANOTHER_ACCOUNT: 'createAnotherAccount',
  FINISHED_WORK: 'finishedWork',
  OTHER: 'other',
});
