import get from 'lodash/get';

const initialState = {
  plans: [],
  cheapestPlans: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CHEAPEST_PLANS': {
      return {
        ...state,
        cheapestPlans: action.payload,
      };
    }
    case 'GET_ALL_PLANS': {
      return {
        ...state,
        plans: (get(action, 'payload', []) || [])?.sort((prev, next) => (prev?.price < next?.price ? -1 : 1)),
      };
    }
    case 'UPDATE_SUBSCRIPTION_STATUS': {
      const currentPlan = get(action, 'payload.plan', {});
      const originalPlanId = get(currentPlan, 'originalPlanWithoutDiscount._id');
      if (originalPlanId) {
        return {
          ...state,
          plans: state.plans.map((plan) => {
            if (plan._id === originalPlanId) {
              return currentPlan;
            }
            return plan;
          }),
        };
      }
      return state;
    }
    default:
      return state;
  }
};
