import React from 'react';
import { useSelector } from 'react-redux';

import { getNameFilters } from 'helpers/getNameFilters';
import { searchesSelector } from 'data/selectors/search';
import SavedSearchOptions from 'components/listsOptions/savedSearchOptions';

import {
  Container,
  LabelContainer,
  SavedFilterContainer,
  NameLabel,
  FiltersLabel,
} from './styles';

const SavedFilters = ({
  onClick,
}) => {
  const {
    data: searches = [],
  } = useSelector(searchesSelector);

  return (
    <Container>
      {
        searches.map((search) => {
          const filtersName = getNameFilters({
            filters: search.filters,
            sourceType: search.sourceType,
          });
          return (
            <SavedFilterContainer
              onClick={() => {
                if (onClick) onClick(search);
              }}
              key={`left_saved_search_${search._id}`}
            >
              <LabelContainer>
                <NameLabel title={search.name} placement="bottomLeft">
                  {search.name}
                </NameLabel>
                <FiltersLabel title={filtersName} placement="bottomLeft">
                  {filtersName}
                </FiltersLabel>
              </LabelContainer>
              <SavedSearchOptions
                name={search.name}
                sourceType={search.sourceType}
                savedSearchId={search._id}
                titleExists={false}
              />
            </SavedFilterContainer>
          );
        })
      }
    </Container>
  );
};

export default SavedFilters;
