import get from 'lodash/get';
import jwt from 'jsonwebtoken';
import isEqual from 'react-fast-compare';

import StorageArray from 'helpers/storageArray';
import { SOURCE_TYPE } from 'data/types/source.types';
import { STATUSES } from 'constants/emailStatus';
import { INSIGHTS_TYPE } from 'data/types/insights.types';
import { transformFilters } from 'helpers/transformFilters';
import { ENRICHMENT_TYPE } from 'data/types/enrichment.types';
import { contactsAnalyticsSelector } from 'data/selectors/contacts';
import * as SearchFiltersTypes from 'data/types/search.filters.types';
import { INSIGHTS_REQUEST_TYPE } from 'data/types/insights.request.type';
import { createImportFilter, createListFilter } from 'helpers/filterFactory';
import getRelevantCompanyRelation from 'helpers/table/getRelevantCompanyRelation';
import { updateForceLoading } from 'data/actions/loading';
import { createBulkOperation } from './bulkOperations';
import { createUserSearch } from './common';
import { updateLoading } from './loading';

export const getContactsAnalytics = () => (dispatch, getState) => {
  const state = getState();
  const {
    filterBy,
    startDate,
    endDate,
    noCache,
  } = contactsAnalyticsSelector(state);

  dispatch({
    type: 'GET_CONTACTS_ANALYTICS',
    payload: {
      endpoint: 'api/v1/contacts/contact/analytics',
      method: 'POST',
      body: {
        type: filterBy,
        startDate,
        endDate,
        noCache,
      },
      delayLoad: 0,
    },
    components: ['contactsAnalyticsLoader'],
  });
};

export const changeContactsAnalyticsParams = (params) => (dispatch) => {
  dispatch({
    type: 'CHANGE_CONTACTS_ANALYTICS_PARAMS',
    data: params,
  });
  dispatch(getContactsAnalytics());
};

export const getContactsFilters = () => (dispatch, getState) => {
  const state = getState();
  const savedFilters = get(state, 'contacts.savedFilters', []);
  if (!savedFilters.length) {
    dispatch({
      type: 'GET_CONTACTS_FILTERS',
      payload: {
        endpoint: 'api/v1/filters',
        method: 'GET',
        params: {
          sourceType: 'contact',
        },
        retries: 2,
      },
      components: ['contactsFilters'],
    });
  }
};

export const setSelectedContacts = (selectedContacts) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_CONTACTS',
    data: selectedContacts,
  });
};

export const excludeContacts = (excludedContacts) => (dispatch) => {
  dispatch({
    type: 'EXCLUDE_CONTACTS',
    data: excludedContacts,
  });
};

export const setOnCheckAllChange = (onCheckAllChange) => (dispatch) => {
  dispatch({
    type: 'SET_ON_CHECK_ALL_CHANGE',
    data: {
      onCheckAllChange,
    },
  });
};

export const setContactsInitialRequest = (initialRequest) => (dispatch) => {
  dispatch({
    type: 'SET_CONTACTS_INITIAL_REQUEST',
    data: { initialRequest },
  });
};

export const setInsightsContactsInitialRequest = (initialRequest) => (dispatch) => {
  dispatch({
    type: 'SET_INSIGHTS_CONTACTS_INITIAL_REQUEST',
    data: {
      initialRequest,
    },
  });
};

export const getContactsCount = (body, params, deleted, onSuccessCount = () => { }) => (dispatch) => {
  dispatch({
    type: deleted ? 'GET_DELETED_CONTACTS_COUNT' : 'GET_CONTACTS_COUNT',
    payload: {
      endpoint: 'api/v1/contacts/contact/search/count',
      method: 'POST',
      body,
      params,
      delayLoad: 700,
      cancelPrevRequests: true,
    },
    onSuccess: (res) => onSuccessCount(res),
    components: ['contactsCount'],
  });
};

export const fastSearchDatabaseContacts = ({
  onSuccess = () => { },
  onFailed = () => { },
  filters = [],
  search,
}) => (dispatch) => {
  const includes = ['properties.firstName', 'properties.lastName', 'companyRelations.company', 'companyRelations.position', 'createdAt', 'updatedAt', 'companyRelations._id'];
  const params = {
    pageSize: 25,
    pageNumber: 1,
    sort: '',
    order: '',
  };

  const body = {
    filters, search, includeRelations: true, includes, deleted: false,
  };

  const onFailedDefault = (error) => {
    onFailed(error);
  };

  dispatch({
    type: 'FAST_SEARCH_DATABASE_CONTACTS',
    payload: {
      endpoint: 'api/v1/contacts/contact/search',
      method: 'POST',
      body,
      params,
      delayLoad: 0,
      cancelPrevRequests: true,
    },
    components: ['fastSearchContactsTab'],
    onSuccess,
    onFailed: onFailedDefault,
  });
};

export const addDemoContacts = () => (dispatch) => {
  dispatch({
    type: 'ADD_DEMO_CONTACTS',
    data: {},
  });
};

export const searchContacts = (data, findCount = true, onSuccess = () => { }, onFailed = () => { }, deleted = false, onSuccessCount = () => { }) => (dispatch, getState) => {
  const state = getState();
  if (state?.user?.isDemoUser) {
    addDemoContacts();
    dispatch(updateForceLoading(SOURCE_TYPE.CONTACT, false));
    return;
  }
  const {
    pageSize, page, filters, sort, search,
  } = deleted ? get(
    state, 'contacts.deletedContacts',
  ) : get(
    state, 'contacts.contacts',
  );
  const userSettings = get(state, 'user.settings.columnsConfiguration', {});

  if (get(data, 'columnsConfiguration.contacts.length', 0)) {
    userSettings.contacts = get(data, 'columnsConfiguration.contacts', []);
  }

  const excludeProperties = ['source'];

  const includes = ['properties.firstName', 'properties.lastName', 'companyRelations.company', 'companyRelations.position', 'companyRelations.email', 'createdAt', 'updatedAt', 'companyRelations._id', 'properties.linkedinUrl', ...get(userSettings, 'contacts', [])
    .map((column) => {
      if (excludeProperties.includes(column.propertyName)) {
        return column.propertyName;
      }
      return `properties.${column.propertyName}`;
    }),
  ];

  if (deleted) includes.push('owner');

  const params = {
    pageSize,
    pageNumber: page,
    sort: sort?.column,
    order: sort?.order,
  };

  const body = {
    filters, search, includeRelations: true, includes, deleted,
  };

  const onFailedDefault = (error) => {
    dispatch(setContactsInitialRequest(false));
    onFailed(error);
  };

  dispatch({
    type: deleted ? 'SEARCH_DELETED_CONTACTS' : 'SEARCH_CONTACTS',
    payload: {
      endpoint: 'api/v1/contacts/contact/search',
      method: 'POST',
      body,
      params,
      delayLoad: 700,
      cancelPrevRequests: true,
    },
    components: deleted ? ['deletedItemsTable'] : ['listContactsTable', 'contactsListTable', 'searchContacts'],
    onSuccess: (responseData) => {
      if (!deleted) {
        dispatch(updateForceLoading(SOURCE_TYPE.CONTACT, false));
      }
      onSuccess(responseData);
    },
    onFailed: onFailedDefault,
  });
  if (findCount) {
    dispatch(getContactsCount(body, params, deleted, onSuccessCount));
  }
};

export const searchContact = ({
  filters,
  onSuccess = () => { },
  onFailed = () => { },
} = {}) => (dispatch) => {
  dispatch({
    type: 'SEARCH_CONTACT',
    payload: {
      endpoint: 'api/v1/contacts/contact/search',
      method: 'POST',
      body: { filters },
      delayLoad: 0,
      cancelPrevRequests: true,
    },
    components: ['searchContactLoader'],
    onSuccess,
    onFailed,
  });
};

export const setContactsParams = ({
  filters,
  pageSize,
  page,
  sort,
  search,
  disableSearch = false,
  listId,
  importId,
  findCount,
  usePrevFilters = false,
  onSuccess = () => { },
  onFailed = () => { },
  onSuccessCount = () => { },
} = {}) => (dispatch, getState) => {
  const state = getState();
  let currentPageSize = get(state, 'contacts.contacts.pageSize');
  let currentPage = get(state, 'contacts.contacts.page');
  const currentSearch = get(state, 'contacts.contacts.search');
  let currentSort = get(state, 'contacts.contacts.sort');
  const additionalSelectedFilters = get(state, 'contacts.additionalSelectedFilters', []);
  const listFilter = [createListFilter(listId)];
  const importFilter = [createImportFilter(importId)];

  const tableMeta = localStorage.getItem('tableMeta');

  if (tableMeta) {
    const parsedTableMeta = JSON.parse(tableMeta);
    currentPageSize = get(parsedTableMeta, 'contact.pageSize', currentPageSize);
    currentPage = get(parsedTableMeta, 'contact.page', currentPage);
    if (sort === false) {
      currentSort = {};
    } else {
      currentSort = get(parsedTableMeta, 'contact.sort', currentSort);
    }
  }

  let currentFilters = get(state, 'contacts.contacts.filters', []);

  if (!usePrevFilters) {
    if (importId) {
      currentFilters = importFilter;
    } else if (listId) {
      currentFilters = listFilter;
    } else if (filters) {
      currentFilters = filters;
    }
  }

  additionalSelectedFilters.forEach((additionalFilter) => {
    const filterType = additionalFilter.additionalFilterType;

    if (filterType === 'list') {
      const nedFilterData = createListFilter(additionalFilter._id);
      currentFilters = [...currentFilters.filter((item) => !isEqual(item, nedFilterData) && !isEqual({ property: item.property, included: item.included }, nedFilterData)), { ...nedFilterData }];
    } else if (filterType === 'filter') {
      currentFilters = [...currentFilters.filter((item) => !additionalFilter.filters.some((filterItem) => {
        let checkingItem = filterItem;

        if (checkingItem.property === 'listRelations') {
          checkingItem = { property: filterItem.property, included: filterItem.included };
        }

        return isEqual(checkingItem, item);
      })), ...additionalFilter.filters];
    }
  });

  const data = {
    pageSize: pageSize || currentPageSize,
    page: pageSize > currentPageSize ? 1 : page || currentPage,
    sort: {
      column: sort?.column || currentSort.column || '',
      order: sort?.order || currentSort.order || '',
    },
    filters: currentFilters,
    search: search || currentSearch,
  };

  dispatch({
    type: 'SET_CONTACTS_PARAMS',
    data,
  });

  if (!disableSearch) {
    dispatch(searchContacts({}, findCount, onSuccess, onFailed, false, onSuccessCount));
  }
};

export const updateContactsFilter = (filterId, filters, onSuccess = () => { }) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CONTACTS_FILTER',
    payload: {
      endpoint: `api/v1/filters/${filterId}`,
      method: 'PATCH',
      body: {
        filters,
      },
    },
    onSuccess,
  });
};

export const addContact = ({
  properties,
  source = 'crm',
  onSuccess = () => { },
  onFailed = () => { },
}) => (dispatch) => {
  dispatch({
    type: 'ADD_CONTACT',
    payload: {
      endpoint: 'api/v1/contacts/contact',
      method: 'POST',
      body: {
        properties,
        source,
      },
      showResponseError: true,
      delayLoad: 0,
    },
    components: ['addContactLoader'],
    onSuccess,
    onFailed,
  });
};

export const addProperty = (property) => (dispatch) => {
  dispatch({
    type: 'ADD_PROPERTY',
    payload: {
      endpoint: 'api/v1/contacts/properties',
      method: 'POST',
      body: property,
    },
  });
};

export const editContactsProperty = ({
  properties,
  selectedItems,
  excludedIds,
  selectedAll,
  filters,
  search,
  onSuccess,
} = {}) => (
  (dispatch) => {
    const params = {
      selectAll: selectedAll || false,
    };

    const onSuccessProxy = (data) => {
      if (data._id) {
        dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'update' }));
      }
      onSuccess(data);
    };

    const itemIds = params.selectAll ? [] : selectedItems;

    dispatch({
      type: 'EDIT_CONTACTS_PROPERTY',
      payload: {
        endpoint: 'api/v1/bulk-operations',
        method: 'POST',
        body: {
          type: 'update',
          sourceType: 'contacts',
          properties,
          filters,
          search,
          itemIds,
          excludedIds,
        },
        showResponseError: true,
      },
      properties,
      itemIds,
      onSuccess: onSuccessProxy,
    });
  }
);

export const restoreContacts = (contactIds, onSuccess) => (
  (dispatch, getState) => {
    const state = getState();
    const filters = get(state, 'contacts.deletedContacts.filters', []);
    const search = get(state, 'contacts.deletedContacts.search');

    const params = {
      selectAll: get(state, 'contacts.deletedContacts.selectAll', false),
    };

    const onSuccessProxy = (data) => {
      if (data._id) {
        dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: 'restore' }));
      }
      onSuccess(data);
    };

    const itemIds = params.selectAll ? [] : contactIds;

    dispatch({
      type: 'RESTORE_CONTACTS',
      payload: {
        endpoint: 'api/v1/bulk-operations',
        method: 'POST',
        body: {
          type: 'restore',
          sourceType: 'contacts',
          filters,
          search,
          itemIds,
        },
        showResponseError: true,
      },
      itemIds,
      onSuccess: onSuccessProxy,
    });
  }
);

export const getContact = ({
  contactId,
  onSuccess = () => {},
  onFailed = () => {},
  isSidebar,
}) => (dispatch) => {
  if (contactId) {
    dispatch({
      type: 'GET_CONTACT',
      payload: {
        endpoint: `api/v1/contacts/contact/${contactId}`,
        method: 'GET',
        delayLoad: 0,
      },
      isSidebar,
      components: [isSidebar ? 'getContactSidebarLoader' : 'previewContact_contact_data'],
      onSuccess,
      onFailed,
    });
  }
};

export const updateContact = ({
  contactId,
  properties,
  listRelations,
  primaryPosition,
  onSuccess = () => { },
  onFailed = () => { },
  status = 'updated',
  isSidebar,
}) => (
  (dispatch) => {
    dispatch({
      type: 'UPDATE_CONTACT',
      payload: {
        endpoint: `api/v1/contacts/contact/${contactId}`,
        method: 'PATCH',
        body: {
          properties,
          listRelations,
          primaryPosition,
        },
        showResponseError: true,
      },
      onSuccess,
      onFailed,
      status,
      isSidebar,
      components: ['contactPreviewBarButton'],
    });
  }
);

export const clearAdditionalFilters = (data = {}) => (dispatch) => {
  dispatch({
    type: 'CLEAR_ADDITIONAL_FILTER',
    data,
  });
};

export const createContactsFilter = (name, filters, onSuccess) => (
  (dispatch) => {
    const successProxy = (data) => {
      dispatch(clearAdditionalFilters({ saveContactParams: true }));
      onSuccess(data);
    };

    dispatch({
      type: 'CREATE_CONTACTS_FILTER',
      payload: {
        endpoint: 'api/v1/filters',
        method: 'POST',
        body: {
          name,
          filters,
          sourceType: 'contact',
        },
        delayLoad: 0,
        showResponseError: true,
      },
      onSuccess: successProxy,
      components: ['contactsFilters'],
    });
  }
);

export const deleteContactsFilter = (filterId, onSuccess) => (
  (dispatch) => {
    dispatch({
      type: 'DELETE_CONTACTS_FILTER',
      payload: {
        endpoint: `api/v1/filters/${filterId}`,
        method: 'DELETE',
      },
      filterId,
      onSuccess,
    });
  }
);

export const cloneContactsFilter = (name, filters, onSuccess) => (
  (dispatch) => {
    dispatch({
      type: 'CLONE_CONTACTS_FILTER',
      payload: {
        endpoint: 'api/v1/filters',
        method: 'POST',
        body: {
          name,
          filters,
          sourceType: 'contact',
        },
        delayLoad: 0,
      },
      onSuccess,
      components: ['contactsFilters'],
    });
  }
);

export const renameContactsFilter = (name, filterId) => (dispatch) => {
  dispatch({
    type: 'RENAME_CONTACTS_FILTER',
    payload: {
      endpoint: `api/v1/filters/${filterId}`,
      method: 'PATCH',
      body: {
        name,
      },
    },
  });
};

export const updateContactsConditions = ({
  objectIds,
  excludedIds = [],
  deleted = false,
  operationType = 'delete',
  onSuccess,
  search,
  filters,
  selectAll,
}) => (
  (dispatch) => {
    const params = {
      selectAll,
    };

    const onSuccessProxy = (data) => {
      if (data._id) {
        dispatch(createBulkOperation({ _id: data._id, sourceType: 'contacts', type: operationType }));
      }
      onSuccess(data);
    };

    dispatch({
      type: 'DELETE_CONTACTS',
      payload: {
        endpoint: 'api/v1/bulk-operations',
        method: 'POST',
        body: {
          type: operationType,
          itemIds: params.selectAll ? [] : objectIds,
          excludedIds,
          sourceType: 'contacts',
          delayLoad: 0,
          filters: filters || [],
          search,
        },
      },
      operationType,
      components: !params.selectAll ? [deleted ? 'deletedItemsTable' : 'deleteContacts'] : [],
      contactsIds: objectIds,
      onSuccess: onSuccessProxy,
    });
    dispatch({
      type: 'CHECK_ENRICH_CONTACT_WHEN_DELETED',
      data: {
        contactsIds: objectIds,
      },
    });
  }
);

export const toggleShowAllSavedFilters = (value) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_SHOW_ALL_SAVED_FILTERS',
    data: { value },
  });
};

export const getInsightContactsCount = ({
  filters, requestType, selectedTab, totalItems, onSuccess = () => { },
}) => (dispatch, getState) => {
  const state = getState();
  const currentFilters = get(state, 'contacts.insights.filters');

  const filtersTypes = SearchFiltersTypes.CONTACT_SEARCH_FILTERS;
  const filtersItem = JSON.parse(JSON.stringify(filters ? [...filters] : [...currentFilters]));
  let updatedFilters = filtersItem.filter((filter) => filtersTypes.includes(filter.property));
  const companyDomainPropertyIndex = updatedFilters.findIndex((item) => item.property === 'company.domain');

  if (companyDomainPropertyIndex > -1 && 'map' in updatedFilters[companyDomainPropertyIndex].included && 'map' in updatedFilters[companyDomainPropertyIndex].excluded) {
    updatedFilters[companyDomainPropertyIndex].included.value = updatedFilters[companyDomainPropertyIndex].included.value.map((item) => updatedFilters[companyDomainPropertyIndex].included.map.find((innerItem) => innerItem?.printedData === item)?.domain ?? item);
    updatedFilters[companyDomainPropertyIndex].excluded.value = updatedFilters[companyDomainPropertyIndex].excluded.value.map((item) => updatedFilters[companyDomainPropertyIndex].excluded.map.find((innerItem) => innerItem?.printedData === item)?.domain ?? item);
    delete updatedFilters[companyDomainPropertyIndex].excluded.map;
    delete updatedFilters[companyDomainPropertyIndex].included.map;
  }

  updatedFilters = updatedFilters.map((updatedFilter) => {
    const transformData = (dataItem) => {
      let returningData = dataItem;

      if (typeof dataItem === 'string') returningData = dataItem;
      if (typeof dataItem === 'object') {
        if ('file' in dataItem) {
          returningData = {
            file: dataItem.file,
            matching: dataItem.matching,
          };
        }
      }
      return returningData;
    };
    const updatedIncludes = updatedFilter?.included?.value.map(transformData);
    const updatedExcludes = updatedFilter?.excluded?.value.map(transformData);

    if (updatedIncludes) updatedFilter.included.value = updatedIncludes;
    if (updatedExcludes) updatedFilter.excluded.value = updatedExcludes;
    return updatedFilter;
  });

  dispatch({
    type: 'GET_INSIGHT_CONTACTS_COUNT',
    payload: {
      endpoint: 'api/v1/insights/search/contacts/count',
      method: 'POST',
      body: {
        filters: updatedFilters.filter((filter) => !filter.included.value.includes(SearchFiltersTypes.EMAIL_FILTER_STATUS.ALL_CONTACTS)),
        requestType,
      },
      delayLoad: 0,
      cancelPrevRequests: true,
    },
    selectedTab,
    totalItems,
    components: ['insightContactsCountLoader'],
    onSuccess,
  });
};

export const getSavedInsightContacts = ({
  data = [],
  onlyEnrich = false,
  onSuccess = () => { },
  components,
}) => (dispatch) => {
  const insightIds = data.map((el) => el.id);
  const linkedinUrls = data.map((el) => el.linkedinUrl).filter((el) => !!el);
  const domains = data.map((el) => el.domain).filter((el) => !!el);

  dispatch({
    type: 'GET_SAVED_INSIGHT_CONTACTS',
    payload: {
      endpoint: 'api/v1/insights/search/contacts/saved',
      method: 'POST',
      body: {
        insightIds,
        linkedinUrls,
        domains,
      },
      delayLoad: 0,
    },
    components,
    insightIds,
    onlyEnrich,
    onSuccess,
  });
};

export const searchInsightContacts = ({
  filters, pageSize, page, sort, selectedTab, onSuccess = () => { }, onFailed = () => { },
}) => (dispatch, getState) => {
  const state = getState();
  if (state?.user?.isDemoUser && !state?.contacts?.insights?.initialRequest) return;
  let currentPageSize = get(state, 'contacts.insights.pageSize');
  const currentPage = get(state, 'contacts.insights.page');
  const currentFilters = get(state, 'contacts.insights.filters');
  const tableMeta = localStorage.getItem('tableMeta');

  if (tableMeta) {
    const parsedTableMeta = JSON.parse(tableMeta);
    currentPageSize = get(parsedTableMeta, 'contactInsight.pageSize', currentPageSize);
  }

  const params = {
    pageSize: pageSize || currentPageSize,
    pageNumber: page || currentPage,
  };

  if (sort) {
    params.sort = sort.column;
    params.order = sort.order;
  }

  const filtersTypes = SearchFiltersTypes.CONTACT_SEARCH_FILTERS;
  const filtersItem = JSON.parse(JSON.stringify(filters ? [...filters] : [...currentFilters]));

  const updatedFilters = transformFilters(filtersItem.filter((filter) => filtersTypes.includes(filter.property)));

  let requestType;

  switch (selectedTab) {
    case INSIGHTS_TYPE.INSIGHTS:
      requestType = INSIGHTS_REQUEST_TYPE.ALL;
      break;
    case INSIGHTS_TYPE.NEW_INSIGHTS:
      requestType = INSIGHTS_REQUEST_TYPE.EXCLUDED;
      break;
    case INSIGHTS_TYPE.SAVED_INSIGHTS:
      requestType = INSIGHTS_REQUEST_TYPE.INCLUDED;
      break;
    default:
      break;
  }

  const handleSuccess = (result) => {
    if (onSuccess) onSuccess(result);
    if (result?.data?.length) {
      dispatch(getSavedInsightContacts({
        data: result.data,
        components: ['savedInsightContactsLoading'],
      }));
    }
  };

  dispatch({
    type: 'SEARCH_INSIGHT_CONTACTS',
    payload: {
      endpoint: 'api/v1/insights/search/contacts',
      method: 'POST',
      body: {
        filters: updatedFilters.filter((filter) => !filter.included.value.includes(SearchFiltersTypes.EMAIL_FILTER_STATUS.ALL_CONTACTS)),
        requestType,
      },
      params,
      delayLoad: 700,
      cancelPrevRequests: true,
      showResponseError: true,
    },
    selectedTab,
    components: ['searchContactInsights'],
    onSuccess: handleSuccess,
    onFailed,
  });
};

export const toggleSelectAllContacts = (value, deleted = false) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_SELECT_ALL_CONTACTS',
    data: {
      value,
      deleted,
    },
  });
};

export const addCompaniesToContact = (contactId, companies, onSuccess, onFailed = () => { }) => (
  (dispatch) => {
    dispatch({
      type: 'ADD_COMPANIES_TO_CONTACT',
      payload: {
        endpoint: `api/v1/contacts/contact/${contactId}/companies`,
        method: 'POST',
        body: {
          companies,
        },
        delayLoad: 0,
        showResponseError: true,
      },
      onSuccess,
      onFailed,
      components: ['addCompaniesToContact'],
    });
  }
);

export const getBlockedContacts = () => (dispatch, getState) => {
  const state = getState();
  const pageSize = get(state, 'contacts.blockedContacts.pageSize');
  const page = get(state, 'contacts.blockedContacts.page');
  const search = get(state, 'contacts.blockedContacts.search');

  dispatch({
    type: 'GET_BLOCKED_CONTACTS',
    payload: {
      endpoint: 'api/v1/insights/search/blocked/contacts/search',
      method: 'POST',
      params: { pageNumber: page, pageSize },
      body: {
        search,
      },
      cancelPrevRequests: true,
    },
  });
};

export const setBlockedContactsParams = (props) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'contacts.blockedContacts.pageSize');
  const currentPage = get(state, 'contacts.blockedContacts.page');
  const currentSearch = get(state, 'contacts.blockedContacts.search');

  const data = {
    pageSize: props?.pageSize || currentPageSize,
    page: props?.page || currentPage,
    search: props?.search || currentSearch,
  };

  dispatch({
    type: 'SET_BLOCKED_CONTACTS_PARAMS',
    data,
  });

  dispatch(getBlockedContacts());
};

export const deleteBlockedContact = (contactId) => (dispatch, getState) => {
  const state = getState();
  const { page: currentPage, pageSize: currentPageSize } = state.contacts.blockedContacts;
  const onSuccessDelete = () => {
    const page = state.contacts.blockedContacts.data.length - 1 ? currentPage : currentPage - 1;
    if (state.contacts.blockedContacts.data.length - 1 <= 1 && state.contacts.blockedContacts.totalItems >= currentPageSize) {
      dispatch(setBlockedContactsParams({ page: page < 1 ? 1 : page }));
    }
  };
  dispatch({
    type: 'DELETE_BLOCKED_CONTACT',
    payload: {
      endpoint: `api/v1/insights/search/blocked/contacts/${contactId}`,
      method: 'DELETE',
      delayLoad: 0,
    },
    onSuccess: onSuccessDelete,
    contactId,
    components: ['deleteBlockedContactLoader'],
  });
};

export const addBlockedContacts = (contacts) => (dispatch) => {
  dispatch({
    type: 'ADD_BLOCKED_CONTACTS',
    payload: {
      endpoint: 'api/v1/insights/search/blocked/contacts',
      method: 'POST',
      body: {
        contacts,
      },
      showResponseError: true,
    },
  });
};

export const updateContactCompaniesRelation = ({
  contactId,
  data,
  onSuccess,
  isSidebar,
}) => (
  (dispatch) => {
    dispatch({
      type: 'UPDATE_CONTACT_COMPANIES_RELATION',
      payload: {
        endpoint: `api/v1/contacts/contact/${contactId}/companies`,
        method: 'PATCH',
        body: {
          data,
        },
        delayLoad: 0,
      },
      components: ['contactPreviewBarButton'],
      onSuccess,
      updatedData: data,
      contactId,
      isSidebar,
    });
  }
);

export const searchPhoneNumber = ({
  contactId,
  insightId,
  onSuccess = () => { },
  onFailed = () => { },
  components = [],
}) => (dispatch) => {
  dispatch({
    type: 'SEARCH_PHONE_NUMBER',
    payload: {
      endpoint: 'api/v1/phone-number',
      method: 'POST',
      body: {
        contactId,
        insightId,
      },
      showResponseError: true,
      delayLoad: 0,
    },
    contactId,
    insightId,
    onSuccess,
    onFailed,
    components,
  });
};

export const saveContactFormInsights = ({
  insightId,
  companies,
  onSuccess = () => {},
  customListIds,
}) => (dispatch, getState) => {
  const state = getState();
  const token = get(state, 'user.auth.token');
  const userId = get(jwt.decode(token), '_id');
  const workspaceId = get(jwt.decode(token), 'workspaceId');
  const lastUserFilters = get(state, 'search.searchesHistory.data[0]');
  const lastUserSearchId = get(state, 'search.lastUserSearchId');
  const storage = new StorageArray('userSearchContact', {
    kind: sessionStorage,
  });
  const foundFilters = storage.findItem([
    { field: '_id', value: userId },
    { field: 'workspaceId', value: workspaceId },
  ]);

  const load = (loading) => {
    if (lastUserFilters?._id !== lastUserSearchId && foundFilters) {
      dispatch(updateLoading(['createUserSearchLoading'], loading));
    }
  };

  load(true);
  dispatch({
    type: 'UPDATE_INSIGHTS_CONTACT_STATUS',
    data: {
      isSaving: true,
      insightIds: [insightId],
    },
  });

  const onSuccessProxy = (result) => {
    load(false);
    if (onSuccess) {
      onSuccess(result);
    }
  };

  const onFailedProxy = () => {
    load(false);
    dispatch({
      type: 'UPDATE_INSIGHTS_CONTACT_STATUS',
      data: {
        status: null,
        isSaving: false,
        insightIds: [insightId],
      },
    });
  };

  const listIds = customListIds || get(state, 'lists.selectedLists');
  const contact = get(state, 'companies.insightCompanyPreview.contacts', get(state, 'contacts.insights.data', [])).find((item) => get(item, 'id') === insightId);

  const relations = companies ?? get(contact || {}, 'companies', []).map((company) => {
    const email = get(company, 'email');
    const item = { company: get(company, 'company.id', get(company, 'company._id')) };
    if (email && ![STATUSES.PROCESSING].includes(get(email, 'status'))) item.email = email;
    return item;
  }).filter(({ company }) => company);

  const saveContact = (userSearchId) => {
    dispatch({
      type: 'SAVE_CONTACT_FROM_INSIGHTS',
      payload: {
        endpoint: `api/v1/contacts/contact/save/insight/${insightId}`,
        method: 'POST',
        body: {
          listIds,
          relations,
          userSearchId,
        },
        showResponseError: true,
      },
      userSearchId,
      insightId,
      onSuccess: onSuccessProxy,
      onFailed: onFailedProxy,
    });
  };

  if (lastUserFilters && lastUserFilters._id === lastUserSearchId) {
    saveContact(lastUserSearchId);
  } else if (foundFilters) {
    try {
      if (foundFilters.filters?.length) {
        dispatch(createUserSearch({
          ...foundFilters,
          onSuccess: ({ _id: userSearchId }) => {
            saveContact(userSearchId);
          },
          onFailed: onFailedProxy,
        }));
      }
    } finally {
      storage.removeItem([
        { field: '_id', value: userId },
        { field: 'workspaceId', value: workspaceId },
      ]);
    }
  } else {
    saveContact();
  }
};

export const bulkSaveContactsFromInsights = ({
  insightIds,
  bulk,
}) => (dispatch) => {
  dispatch({
    type: 'BULK_SAVE_CONTACTS_FROM_INSIGHTS',
    data: {
      insightIds,
      bulk,
    },
  });
};

export const addNewContactsToList = (currentListId) => (dispatch) => {
  dispatch({
    type: 'ADD_NEW_CONTACTS_TO_LIST',
    data: { currentListId },
  });
};

export const setSelectedInsightContacts = (selectedInsightContacts) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_INSIGHT_CONTACTS',
    data: selectedInsightContacts,
  });
};

export const createEnrichment = ({
  itemsIds,
  filters,
  selected,
  type,
  source,
  excludedIds,
  onSuccess = () => { },
}) => (dispatch, getState) => {
  const onSuccessProxy = (data) => {
    const state = getState();
    const contacts = get(state, 'contacts.contacts.data', []);
    const selectedContacts = get(state, 'contacts.selectedContacts', []);
    const filteredContacts = selectedContacts.length > 0 ? contacts.filter((contact) => selectedContacts.includes(contact._id)) : contacts.filter((contact) => !excludedIds?.includes(contact._id));

    if (type !== ENRICHMENT_TYPE.PHONE_SEARCH) {
      dispatch({
        type: 'BULK_EMAILS_VALIDATION',
        data: {},
        tasks: filteredContacts.map((contact) => {
          const company = getRelevantCompanyRelation(contact.companyRelations);
          const email = get(company, 'email.value');
          return {
            contactId: contact._id,
            relationId: (email || type !== ENRICHMENT_TYPE.EMAIL_VERIFICATION) ? get(company, '_id') : null,
            email,
          };
        }),
      });
    }
    onSuccess(data);
  };

  dispatch({
    type: 'CREATE_CONTACTS_ENRICHMENT',
    payload: {
      endpoint: 'api/v1/enrichment',
      method: 'POST',
      body: {
        excludedIds,
        itemsIds,
        filters,
        selected,
        type,
        source,
      },
      showResponseError: true,
    },
    onSuccess: onSuccessProxy,
  });
};

export const bulkEmailsValidation = ({ tasks }) => (dispatch) => {
  dispatch({
    type: 'BULK_EMAILS_VALIDATION',
    payload: {
      endpoint: 'api/v1/contacts/contact/email/validation',
      method: 'POST',
      body: {
        tasks,
      },
    },
    tasks,
  });
};

export const deleteCompanyRelationEmail = ({
  contactId,
  relationId,
  onSuccess,
  isSidebar,
}) => (dispatch) => {
  dispatch({
    type: 'DELETE_COMPANY_RELATION_EMAIL',
    payload: {
      endpoint: 'api/v1/contacts/contact/email/status',
      method: 'DELETE',
      body: {
        contactId,
        relationId,
      },
      delayLoad: 0,
    },
    isSidebar,
    onSuccess,
    components: ['contactPreviewBarButton'],
    contactId,
    relationId,
  });
};

export const selectFilter = (filter) => (dispatch) => {
  dispatch({
    type: 'SELECT_FILTER',
    data: filter,
  });
};

export const addAdditionalFilter = (filter) => (dispatch) => {
  dispatch({
    type: 'ADD_ADDITIONAL_FILTER',
    data: filter,
  });
};

export const emailFinder = ({
  task,
  contactId,
  companyIndex,
}) => (dispatch) => {
  dispatch({
    type: 'SET_EMAIL_STATUS',
    data: {
      contactId,
      companyIndex,
      status: STATUSES.PROCESSING,
    },
  });
  dispatch({
    type: 'FIND_EMAIL',
    payload: {
      endpoint: 'api/v1/contacts/contact/email/find',
      method: 'POST',
      body: task,
    },
  });
};

export const getSavedContactByInsightId = ({
  insightId,
  onFailed = () => { },
  onSuccess = () => { },
}) => (dispatch) => {
  dispatch({
    type: 'GET_SAVED_CONTACT_BY_INSIGHT_ID',
    payload: {
      endpoint: `api/v1/insights/search/contact/${insightId}`,
      method: 'GET',
    },
    insightId,
    components: ['getContactSidebarLoader'],
    onFailed,
    onSuccess,
  });
};

export const getContactSearchInfo = ({ onSuccess = () => { }, onFailed = () => { } }) => (dispatch, getState) => {
  const state = getState();
  const { filters, search } = get(state, 'contacts.contacts');

  const body = {
    filters,
    search,
  };

  dispatch({
    type: 'GET_CONTACT_SEARCH_INFO',
    payload: {
      endpoint: 'api/v1/contacts/contact/search/loading',
      method: 'POST',
      cancelPrevRequests: true,
      body,
    },
    onSuccess,
    onFailed,
  });
};

export const setDeletedContactsParams = (props) => (dispatch, getState) => {
  const state = getState();
  const currentPageSize = get(state, 'contacts.deletedContacts.pageSize');
  const currentPage = get(state, 'contacts.deletedContacts.page');
  const currentSearch = get(state, 'contacts.deletedContacts.search');
  const currentSort = get(state, 'contacts.deletedContacts.sort');
  const currentFilters = get(state, 'contacts.deletedContacts.filters');
  const currentIsChanged = get(state, 'contacts.deletedContacts.isChanged');
  const onSuccess = get(props, 'onSuccess', () => { });
  const onFailed = get(props, 'onFailed', () => { });

  const data = {
    pageSize: props?.pageSize || currentPageSize,
    page: props?.page || currentPage,
    search: props?.search || currentSearch,
    sort: props?.sort || currentSort,
    filters: props?.filters || currentFilters,
    isChanged: props?.isChanged || currentIsChanged,
  };

  dispatch({
    type: 'SET_DELETED_CONTACTS_PARAMS',
    data,
  });

  if (!get(props, 'disableSearch', false)) dispatch(searchContacts({}, true, onSuccess, onFailed, true));
};

export const permanentDeleteContact = (contactId) => (dispatch, getState) => {
  const state = getState();
  const { page: currentPage } = state.contacts.deletedContacts;
  const onSuccessDelete = () => {
    const page = state.contacts.deletedContacts.data.length - 1 ? currentPage : currentPage - 1;
    if (page !== currentPage && page > 0) dispatch(setDeletedContactsParams({ page }));
  };
  dispatch({
    type: 'PERMANENT_DELETE_CONTACT',
    payload: {
      endpoint: `api/v1/contacts/contact/permanent/${contactId}`,
      method: 'DELETE',
    },
    onSuccess: onSuccessDelete(),
    contactId,
  });
};

export const updatePreviewContactListsRelations = ({
  listRelations,
  populatedLists,
  toDelete,
  isSidebar,
}) => (dispatch) => {
  dispatch({
    type: 'SET_PREVIEW_CONTACT_RELATIONS',
    data: {
      listRelations,
      populatedLists,
      toDelete,
      isSidebar,
    },
  });
};

export const clearContactSearch = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_CONTACT_SEARCH',
    data: {},
  });
};

export const getContactLists = ({
  contactId,
  isSidebar,
  onSuccess,
}) => (dispatch) => {
  dispatch({
    type: 'GET_CONTACT_LISTS',
    payload: {
      endpoint: `api/v1/contacts/contact/${contactId}/lists`,
      method: 'GET',
      showResponseError: true,
      delayLoad: 0,
    },
    isSidebar,
    onSuccess,
    components: ['getContactListsLoading'],
  });
};

export const getContactSequences = (contactId, onSuccess) => (dispatch) => {
  dispatch({
    type: 'GET_CONTACT_SEQUENCES',
    payload: {
      endpoint: `api/v1/contacts/contact/${contactId}/sequences`,
      method: 'GET',
      showResponseError: true,
      delayLoad: 0,
    },
    onSuccess,
    components: ['getContactSequencesLoading'],
  });
};

export const getInaccurateFields = (source, entityIds) => (dispatch) => {
  dispatch({
    type: `GET_INACCURATE_CONTACT_${source === SOURCE_TYPE.CONTACT ? 'EMAILS' : 'DOMAINS'}`,
    payload: {
      endpoint: `api/v1/inaccurate-field/${source}`,
      method: 'GET',
      params: {
        entityIds,
      },
      delayLoad: 0,
      showResponseError: true,
    },
    entityIds,
    source,
    components: ['inaccurateFieldLoading'],
  });
};

export const addPrimaryPosition = (position) => (dispatch) => {
  dispatch({
    type: 'ADD_PRIMARY_POSITION',
    data: { position },
  });
};

export const searchContactColleagues = ({
  filters,
  saved,
  page = 1,
  pageSize = 100,
  isSidebar,
  search,
}) => (dispatch) => {
  const params = {
    pageSize,
    pageNumber: page,
  };

  if (saved) {
    dispatch({
      type: 'SEARCH_CONTACT_COLLEAGUES',
      payload: {
        endpoint: 'api/v1/contacts/contact/search',
        method: 'POST',
        body: {
          search,
          filters,
          deleted: false,
          includeRelations: true,
          includes: [
            'properties.firstName',
            'properties.lastName',
            'companyRelations.company',
            'companyRelations.position',
            'companyRelations.email',
            'createdAt',
            'updatedAt',
            'companyRelations._id',
            'properties.linkedinUrl',
            'properties.name',
            'properties.email',
            'properties.createdAt',
          ],
        },
        params: {
          ...params,
          includeCount: true,
        },
        delayLoad: 0,
      },
      saved,
      isSidebar,
      components: [isSidebar ? 'searchSavedContactColleaguesSidebarLoading' : 'searchSavedContactColleaguesLoading'],
    });
  } else {
    const requestType = INSIGHTS_REQUEST_TYPE.ALL;

    dispatch({
      type: 'SEARCH_CONTACT_COLLEAGUES',
      payload: {
        endpoint: 'api/v1/insights/search/contacts',
        method: 'POST',
        body: {
          filters,
          requestType,
        },
        params,
        delayLoad: 0,
      },
      isSidebar,
      components: [isSidebar ? 'searchContactColleaguesSidebarLoading' : 'searchContactColleaguesLoading'],
      onSuccess: ({ data }) => {
        dispatch(getSavedInsightContacts({ data }));
      },
    });
  }
};

export const getContactColleaguesPositions = ({
  domain,
  position,
  onSuccess,
  onFailed,
}) => (dispatch) => {
  dispatch({
    type: 'GET_CONTACT_COLLEAGUES_POSITIONS',
    payload: {
      endpoint: 'api/v1/insights/analytics/company/contact-positions',
      method: 'GET',
      params: {
        domain,
        limit: 100,
        position,
      },
      delayLoad: 0,
    },
    components: ['getContactColleaguesPositionsLoading'],
    onSuccess,
    onFailed,
  });
};

export const getContactColleaguesCountries = (domain) => (dispatch) => {
  dispatch({
    type: 'GET_CONTACT_COLLEAGUES_COUNTRIES',
    payload: {
      endpoint: 'api/v1/insights/analytics/company/contact-countries',
      method: 'GET',
      params: {
        domain,
      },
      delayLoad: 0,
    },
    components: ['getContactColleaguesCountriesLoading'],
  });
};
