import { Modal } from 'antd';
import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRandomNumber } from '@newlab-io/getprospect-react-components/lib/shared/helpers/number';

import { toggleModal } from 'data/actions/modals';
import { REASON_TYPE } from 'data/types/user.types';
import { deleteUser, resetDeleteAccount, setDeleteAccount } from 'data/actions/user';
import {
  Button, TextArea,
} from 'components/common';
import { deleteAccountSelector } from 'data/selectors/user';
import { createQuitReport } from 'data/actions/common';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { analyticsAndDeleteUserLoaderSelector } from 'data/selectors/loading';
import useDeleteAccountMessage from 'hooks/messageTemplates/useDeleteAccountMessage';

import {
  AskText,
  SorryText,
  BlueLabel,
  ReasonRadio,
  ReasonsRadioWrapper,
} from '../cancelSubscriptionModal/styles';
import {
  CloseIcon,
  ModalTitle,
  Footer,
} from '../baseModal/styles';
import { WarningText } from './styles';

const NewDeleteAccountModal = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  const { deleteAccount } = useSelector(modalOptionsSelector);
  const loading = useSelector(analyticsAndDeleteUserLoaderSelector);
  const details = useSelector(deleteAccountSelector);
  const deleteAccountMessage = useDeleteAccountMessage();

  const [reason, setReason] = useState();

  const reasonOptions = useMemo(() => {
    const reasons = [
      { label: 'I finished the work I had to do', value: REASON_TYPE.FINISHED_WORK },
      { label: "I didn't understand how the app works", value: REASON_TYPE.HARD_APP },
      { label: "I don't want you to keep my data", value: REASON_TYPE.BAD_KEEPING_DATA },
      { label: "I didn't find the right contacts", value: REASON_TYPE.NOT_FOUND_CONTACTS },
      { label: 'I want to change my email', value: REASON_TYPE.CHANGE_EMAIL },
      { label: 'I want to create another account', value: REASON_TYPE.CREATE_ANOTHER_ACCOUNT },
    ];
    const randomReasons = [];
    while (reasons.length) {
      const [randomReason] = reasons.splice(getRandomNumber(0, reasons.length - 1), 1);
      randomReasons.push(randomReason);
    }
    randomReasons.push({ label: 'Other', value: REASON_TYPE.OTHER });
    return randomReasons;
  }, []);

  const disabled = useMemo(() => {
    if (!reason) return true;
    if (reason === REASON_TYPE.OTHER && !details.descriptionReason) return true;
    if (reason === REASON_TYPE.ANOTHER_TOOL && !details.tool) return true;
    return false;
  }, [reason, details.descriptionReason, details.tool]);

  const handleClose = () => {
    dispatch(toggleModal('new_delete_account', false));
    dispatch(resetDeleteAccount());
  };

  const openIntercom = () => {
    if (window.Intercom) {
      handleClose();
      window.Intercom('showNewMessage');
    }
  };

  const handleDeleteAccount = () => {
    if (reason) {
      dispatch(createQuitReport({
        type: reason,
        action: 'deleteAccount',
        details,
        onSuccess: () => {
          dispatch(deleteUser(() => {
            deleteAccountMessage();
            handleClose();
          }));
        },
      }));
    }
  };

  return (
    <Modal
      visible={visible}
      title={(
        <ModalTitle>
          Is this goodbye?
          <CloseIcon
            onClick={handleClose}
            className="ic_close"
          />
        </ModalTitle>
      )}
      closable={false}
      width={500}
      footer={false}
      onCancel={handleClose}
    >
      <div>
        <SorryText>
          If there&lsquo;s anything we can do to help, please
          {' '}
          <BlueLabel onClick={openIntercom}>contact us.</BlueLabel>
        </SorryText>
        <ReasonsRadioWrapper value={reason} onChange={(e) => setReason(e.target?.value)}>
          {
            reasonOptions.map((reasonItem) => (
              <ReasonRadio key={reasonItem.value} value={reasonItem.value}>
                <span>
                  {reasonItem.label}
                </span>
                {
                  reasonItem.value === REASON_TYPE.CHANGE_EMAIL && reason === REASON_TYPE.CHANGE_EMAIL && (
                    <WarningText>You can change your email in your account settings. No need to delete your account!</WarningText>
                  )
                }
                {
                  reasonItem.value === REASON_TYPE.CREATE_ANOTHER_ACCOUNT && reason === REASON_TYPE.CREATE_ANOTHER_ACCOUNT && (
                    <WarningText>
                      We only allow&nbsp;
                      <b>one free account</b>
                      &nbsp;per user. If you were planning to create a second account, please note that it will be&nbsp;
                      <b>banned</b>
                      &nbsp;, as this is against our policy. Your free credits reset every month.
                    </WarningText>
                  )
                }
              </ReasonRadio>
            ))
          }
        </ReasonsRadioWrapper>
        <AskText>{`Your feedback matters. Tell us more about why you're ${deleteAccount ? 'deleting' : 'cancelling'}:`}</AskText>
        <TextArea
          value={details.descriptionReason}
          onChange={(e) => dispatch(setDeleteAccount({ descriptionReason: e.target.value }))}
        />
        <Footer>
          <Button
            data-form="true"
            type="danger"
            loading={loading}
            disabled={disabled}
            onClick={handleDeleteAccount}
          >
            Delete my account
          </Button>
          <Button type="normal" onClick={handleClose}>Back</Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default NewDeleteAccountModal;
