import styled from 'styled-components';

import { COLORS } from 'constants/styles';
import EllipsisText from 'components/ellipsisText';

export const Container = styled.div`

`;

export const LabelContainer = styled.div`
  width: 210px;
  max-height: 40px;
  height: 40px;
`;

export const NameLabel = styled(EllipsisText)`
  color: ${COLORS.BLACK_G1};
  font-size: 14px;
  font-weight: 500;
  display: block;
`;

export const FiltersLabel = styled(EllipsisText)`
  color: ${COLORS.GREY_G2};
  font-size: 14px;
  font-weight: 500;
  display: block;
`;

export const SavedFilterContainer = styled.div`
  padding: 12px 20px;
  border-bottom: solid 1px ${COLORS.GREY_G4};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.PRIMARY_P4};

    ${NameLabel}, ${FiltersLabel} {
      color: ${COLORS.PRIMARY};
    }
  }
`;
