import { EMAIL_FILTER_STATUS } from 'data/types/search.filters.types';
import get from 'lodash/get';

export const isSimilar = (savedInsight, item) => get(savedInsight, 'properties.linkedinUrl.value') === get(item, 'linkedinUrl', false)
  || get(savedInsight, 'properties.domain.value') === get(item, 'domain', false)
  || get(savedInsight, 'insightId') === get(item, 'id', get(item, '_id', false));

const saveEmailAndIdCompany = (item, foundSimilarItem) => {
  get(foundSimilarItem, 'companyRelations', []).forEach((foundCompany) => {
    get(item, 'companies', []).forEach((company) => {
      // numbers are needed not to apply undefined variables
      const companyName = get(company, 'company.name', 1);
      const companyDomain = get(company, 'company.domain', 2);

      const foundCompanyName = get(foundCompany, 'company.properties.name.value', 3);
      const foundCompanyDomain = get(foundCompany, 'company.properties.domain.value', 4);

      if (companyName === foundCompanyName || companyDomain === foundCompanyDomain) {
        company.email = foundCompany.email;
        company._id = get(foundCompany, 'company._id');
      }
    });
  });
  item.isSaving = false;
  return item;
};

export const resetInsight = (item) => {
  item.saved = false;
  item.isSaving = false;
  delete item.savedWorkspaceId;
  get(item, 'companies').forEach((company) => {
    delete company.email;
  });
  return item;
};

export const saveInsightsEmail = (insights, savedInsights) => insights.data.map((item) => {
  const foundSimilarItem = savedInsights.find((savedInsight) => isSimilar(savedInsight, item));
  if (foundSimilarItem?.isRemovedContact) {
    return resetInsight(item);
  }
  return saveEmailAndIdCompany(item, foundSimilarItem);
});

export const transformSimilarInsights = (insights, savedInsights) => insights.data.map((item) => {
  const foundSimilarItem = savedInsights.find((savedInsight) => isSimilar(savedInsight, item));

  if (foundSimilarItem) {
    item.saved = true;
    item.savedWorkspaceId = get(foundSimilarItem, '_id');
    item.companyRelations = get(foundSimilarItem, 'companyRelations', []);
    item.listRelations = get(foundSimilarItem, 'listRelations', []);
    item.sequences = get(foundSimilarItem, 'sequences', []);
    item.phone = get(foundSimilarItem, 'properties.phone');
    item.properties = get(foundSimilarItem, 'properties', {});
    item.source = get(foundSimilarItem, 'source');
    item.owner = get(foundSimilarItem, 'owner');
  }

  if (!item.saved) {
    delete item.email;
    delete item.statusId;
  } else {
    item.companies = saveEmailAndIdCompany(item, foundSimilarItem).companies;
  }

  return item;
});

export const generateContactFilters = ({
  domain,
  search,
  position,
  location,
  department,
  departments,
  allContacts = true,
}) => {
  const filters = [];
  if (allContacts) {
    filters.push({
      included: {
        operator: 'EMAIL_STATUS',
        value: [EMAIL_FILTER_STATUS.ALL_CONTACTS],
      },
      property: 'email',
    });
  }
  if (domain) {
    filters.push({
      excluded: { value: [] },
      included: { operator: 'EQ', value: [domain] },
      property: 'company.domain',
    });
  }
  if (position) {
    const positions = [position];
    filters.push({
      excluded: { value: [] },
      included: { operator: 'CONTAINS_POSITION', value: positions },
      property: 'company.position',
    });
  }
  if (department) {
    const foundDepartment = departments.find(({ name }) => name === department);
    if (foundDepartment) {
      filters.push({
        excluded: { value: [] },
        included: { operator: 'CONTAINS_POSITION', value: [{ matching: foundDepartment.children, printedData: department }] },
        property: 'company.departments',
      });
    }
  }
  if (location) {
    const locations = [location];
    filters.push({
      excluded: { value: [] },
      included: { operator: 'LOCATION', value: locations },
      property: 'contact.location',
    });
  }
  if (search) {
    filters.push({
      checkboxes: ['search_desc'],
      excluded: { value: [] },
      included: { operator: 'KEYWORDS_SEARCH', value: [search] },
      property: 'keywords',
    });
  }
  return filters;
};
